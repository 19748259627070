import React, { useEffect } from 'react';
import './App.css';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

function App() {
  if (
    process.env.REACT_APP_NODE_ENV === 'production'
    // || process.env.REACT_APP_NODE_ENV === 'staging'
  ) {
    console.log = function () {};
  }

  useEffect(() => {
    console.log('lang inapp', localStorage.getItem('i18nextLng'));
  }, [localStorage.getItem('i18nextLng')]);

  function FallbackComponent() {
    return <div>An error has occured</div>;
  }

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
