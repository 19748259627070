import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Col, Container, Label } from 'reactstrap';

import { Constant } from '../../../../Helpers/constant';
import Table from './Components/Tables/Table';
import { VENDOR_DOWNLOAD_LIST, VENDOR_DOWNLOAD_LIST_EMPTY } from '../../../../../store/application/actionType';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const initialValues = {
  client: null,
};

const themeStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '8px 15px',
    ':hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid #80808026',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
  }),

  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),

  control: (styles, state) => ({
    ...styles,
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '2px 2px 2px 40px',
  }),

  menu: styles => ({
    ...styles,
    padding: '5px 0',
    margin: '5px 0',
    borderRadius: '20px',
    width: '100%',
    overflow: 'hidden',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, borderBottom: '1px solid black', display: 'none' }),
};

const Downloads = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Downloads
  const { downloads, downloadsLoading } = useSelector((state: any) => ({
    downloads: state?.VendorDownloadsList?.data,
    downloadsLoading: state?.VendorDownloadsList?.loading,
  }));

  useEffect(() => {
    const { clients } = JSON.parse(localStorage.getItem('authUser'));
    if (clients?.length > 0) {
      setClientList(
        clients?.map((client: string) => {
          return { value: client, label: `${client} Bank`, completeData: { code: client, name: `${client} Bank` } };
        }),
      );
    }
  }, []);

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (downloads === null) {
      if (JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB`) {
        if (state?.client?.value) {
          dispatch({
            type: VENDOR_DOWNLOAD_LIST,
            payload: {
              urlParam: {
                pageIndex: currentPageIndex,
              },
              headers: {
                'Project-Code': state?.client?.value,
              },
            },
          });
        }
      } else {
        dispatch({
          type: VENDOR_DOWNLOAD_LIST,
          payload: {
            urlParam: {
              'user.id': JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
              pageIndex: currentPageIndex,
            },
          },
        });
      }
    }
  }, [dispatch, state?.client?.value]);

  useEffect(() => {
    return () => {
      dispatch({
        type: VENDOR_DOWNLOAD_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `DOWNLOAD`) {
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: VENDOR_DOWNLOAD_LIST,
      payload: {
        urlParam: {
          'user.id': JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: VENDOR_DOWNLOAD_LIST,
      payload: {
        urlParam: {
          'user.id': JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          pageIndex: currentPageIndex,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (downloadsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/vendor/dashboard`);
    } else if (title === `Downloads`) {
      // do nothing
    }
  };

  const inputSelectHandler = (label: string, data) => {
    let updatedState = null;
    if (label === `clients`) {
      updatedState = {
        client: data,
      };
      setState(updatedState);
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Downloads`]} />
          {JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB` && (
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="client" className="form-label fs-5">
                  {t('Select Client')}
                </Label>
                <div className="area_expandable">
                  <Select
                    id="clients"
                    name="currency"
                    value={state?.client}
                    options={clientList}
                    onChange={data => inputSelectHandler(`clients`, data)}
                    styles={themeStyles}
                  />
                  <div className="select-icon-start">
                    <i className="ri-bank-line text-dark fs-20 align-middle me-1"></i>
                  </div>
                </div>
              </div>
            </Col>
          )}
          <Table
            data={downloads?.results}
            totalData={downloads?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default Downloads;
