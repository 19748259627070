import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import AddCategoryForm from './Components/Forms/Form';
import { BreadCrumb, Loader } from '../../../../Common';
import BulkUploadModal from './Components/Modals/BulkUpload';
import { EMPTY_CREATE_CATEGORY_RESPONSE, GET_EMPTY_POST_COUPON } from '../../../../../store/application/actionType';

const AddCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isBulkUploadShow, setIsBulkUploadShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Parent Category
  const { parentCategoryLoading } = useSelector(state => ({
    parentCategoryLoading: state?.GetParentCategory?.loading,
  }));

  // Redemption Type
  const { redemptionTypeLoading } = useSelector(state => ({
    redemptionTypeLoading: state?.GetRedemptionType?.loading,
  }));

  // Create Category
  const { createCategory, createCategoryLoading } = useSelector(state => ({
    createCategory: state?.CreateCategory?.data,
    createCategoryLoading: state?.CreateCategory?.loading,
  }));

  // Booking Types
  const { bookingTypesLoading } = useSelector(state => ({
    bookingTypesLoading: state?.BookingTypes?.loading,
  }));

  // Bulk Upload
  const { bulkUpload, bulkUploadLoading } = useSelector(state => ({
    bulkUpload: state?.PostCoupons?.data,
    bulkUploadLoading: state?.PostCoupons?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (createCategory !== null) {
      history.push('/category/manage-category');
      dispatch({
        type: EMPTY_CREATE_CATEGORY_RESPONSE,
      });
    }
  }, [createCategory]);

  useEffect(() => {
    if (bulkUpload !== null) {
      dispatch({
        type: GET_EMPTY_POST_COUPON,
      });
    }
  }, [bulkUpload]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (parentCategoryLoading || redemptionTypeLoading || createCategoryLoading || bookingTypesLoading || bulkUploadLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [parentCategoryLoading, redemptionTypeLoading, createCategoryLoading, bookingTypesLoading, bulkUploadLoading]);

  /* ---------------------------- BULK UPLOAD HANDLER ---------------------------- */
  const bulkUploadActionHandler = () => {
    setIsBulkUploadShow(true);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Add Category`) {
      // do nothing
    } else if (title === `Category`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ---------------------------- MODAL'S ----------------------------  */}
          {isBulkUploadShow && <BulkUploadModal onCloseHandler={() => setIsBulkUploadShow(false)} />}

          {/*  ---------------------------- PAGES ----------------------------  */}
          <BreadCrumb title="Category" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Category', 'Add Category']} />
          <AddCategoryForm bulkUploadAction={bulkUploadActionHandler} title="Add Category" />
        </Container>
      </div>
    </>
  );
};

export default AddCategory;
