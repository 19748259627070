import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label, CardFooter, Button, CardHeader } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

const BookingDetails = ({ details }) => {
  const [bookingDetails, setBookingDetails] = useState([]);

  let lastUpdatedBy = `NA`;
  let confirmedBy = `NA`;
  let processedBy = `NA`;
  let cancelledBy = `NA`;
  let cancelledReason = `NA`;

  let confirmedAt = `NA`;
  let processedAt = `NA`;
  let cancelledAt = `NA`;
  let latestBookingStatus = details?.bookingStatusCode;

  details?.bookingModifyPersons?.forEach(element => {
    if (element?.bookingStatus === latestBookingStatus) {
      lastUpdatedBy = element?.name;
    }
    if (
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE ||
      element?.bookingStatus === Constant.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE
    ) {
      cancelledBy = element?.name;
      cancelledReason = element?.remarks;
      cancelledAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
      confirmedBy = element?.name;
      confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.PROCESSED) {
      processedBy = element?.name;
      processedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
    }
  });

  useEffect(() => {
    setBookingDetails(details);
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  }, []);

  return (
    <Container fluid>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.bookingId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Contact Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Handicap'}</Label>
                    <Label className="text-muted">{details?.handicap || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Player Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Player name'}</Label>
                    {details?.bookingForSomeoneElse === 1 && <Label className="text-muted">{details?.playerDetails?.name || `NA`}</Label>}
                    {details?.bookingForSomeoneElse === 0 && <Label className="text-muted">{details?.user?.name || `NA`}</Label>}
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Player mobile'}</Label>
                    {details?.bookingForSomeoneElse === 1 && <Label className="text-muted">{details?.playerDetails?.mobileNumber || `NA`}</Label>}
                    {details?.bookingForSomeoneElse === 0 && <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {details?.guest?.length > 0 && (
        <Row>
          <Col xxl={12}>
            <Card>
              <CardBody className="p-4">
                <h6 className="fw-semibold text-uppercase mb-3">Guest Details</h6>
                <div className="border mt-2 mb-3 border"></div>

                {details?.guest?.map((guest, index) => (
                  <Row key={index}>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Name-${index + 1}`}</Label>
                        <Label className="text-muted">{guest?.name || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Email-${index + 1}`}</Label>
                        <Label className="text-muted">{guest?.email || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Handicap-${index + 1}`}</Label>
                        <Label className="text-muted">{guest?.handicap || `NA`}</Label>
                      </div>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Golf Course details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Golf Course Name'}</Label>
                    <Label className="text-muted">{details?.facility?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Address'}</Label>
                    <Label className="text-muted">{details?.address || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'City'}</Label>
                    <Label className="text-muted">{details?.subregion || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'State'}</Label>
                    <Label className="text-muted">{details?.region || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country'}</Label>
                    <Label className="text-muted">{details?.country || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pincode'}</Label>
                    <Label className="text-muted">{details?.pincode || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Confirmation Code'}</Label>
                    <Label className="text-muted">{details?.confirmationCode || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Creation and service</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date of Play'}</Label>
                    <Label className="text-muted">{details?.dateOfPlay ? moment(`${details?.dateOfPlay}`).format('DD-MM-YYYY') : 'NA'}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Time of Play'}</Label>
                    <Label className="text-muted">{`${
                      details?.timeOfPlay ? moment(`${details?.timeOfPlay}`, 'HH:mm').format('h:mm A') : `NA`
                    }`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date & Time of booking creation'}</Label>
                    <Label className="text-muted">{details?.timeOfPlay ? moment(`${details?.createdAt}`).format('DD-MM-YYYY, h:mm A') : 'NA'}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action Date and time</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Processed At'}</Label>
                    <Label className="text-muted">{processedAt}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Confirmed At'}</Label>
                    <Label className="text-muted">{confirmedAt}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Cancelled At'}</Label>
                    <Label className="text-muted">{cancelledAt}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action By</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last Updated By'}</Label>
                    <Label className="text-muted">{lastUpdatedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Processed By'}</Label>
                    <Label className="text-muted">{processedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Confirmed By'}</Label>
                    <Label className="text-muted">{confirmedBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Cancelled By'}</Label>
                    <Label className="text-muted">{cancelledBy}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Updated At'}</Label>
                    <Label className="text-muted">{moment(`${details?.updatedAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Remarks and Reason</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Remarks'}</Label>
                    <Label className="text-muted">{details?.remarks || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reason For Cancellation'}</Label>
                    <Label className="text-muted">{cancelledReason}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* ------------------------- PAYMENT SUMMARY ------------------------- */}
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">PAYMENT SUMMARY</h4>
            </CardHeader>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Total Payable</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.totalPayable?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.totalPayable?.value}` || `NA`}({details?.paymentSummary?.totalPayable?.currency || `NA`})
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Status'}</Label>
                    <Label className="text-muted">{details?.payment?.status || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Id'}</Label>
                    <Label className="text-muted">{details?.payment?.paymentId || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Tax</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.bookingTax?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.bookingTax?.value}` || `NA`}({details?.paymentSummary?.bookingTax?.currency || `NA`})
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Discount</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.bookingDiscount?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.bookingDiscount?.value}` || `NA`}({details?.paymentSummary?.bookingDiscount?.currency || `NA`})
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Booking Fee</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{details?.paymentSummary?.bookingFee?.name || ``}</Label>
                    <Label className="text-muted">
                      {`${details?.paymentSummary?.bookingFee?.value}` || `NA`}({details?.paymentSummary?.bookingFee?.currency || `NA`})
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3">Discounts</h6>
              <div className="border mt-2 mb-3 border"></div>

              {details?.paymentSummary?.discounts?.map((discount, index) => {
                return (
                  <Row key={index}>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{'Amount'}</Label>
                        <Label className="text-muted">
                          {`${discount?.amount}` || `NA`}({discount?.currency || `NA`})
                        </Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{discount?.name || ``}</Label>
                        <Label className="text-muted">{`${discount?.value}%` || `NA`}</Label>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </CardBody>

            <CardBody className="m-4 mb-0 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3"> Fees</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                {details?.paymentSummary?.fees?.map((fee, index) => {
                  return (
                    <Col key={index} md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{fee?.name || ``}</Label>
                        <Label className="text-muted">
                          {`${fee?.value}` || `NA`}({fee?.currency || `NA`})
                        </Label>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </CardBody>

            <CardBody className="m-4 bg-light">
              <h6 className="fw-semibold text-uppercase mb-3"> Taxes</h6>
              <div className="border mt-2 mb-3 border"></div>

              {details?.paymentSummary?.taxes?.map((tax, index) => {
                return (
                  <Row key={index}>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{tax?.name || ``}</Label>
                        <Label className="text-muted">{`${tax?.value}%` || `NA`}</Label>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label className="d-flex">{`Amount`}</Label>
                        <Label className="text-muted">
                          {`${tax?.amount}` || `NA`}({tax?.currency || `NA`})
                        </Label>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BookingDetails;
