import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { IoMdArrowRoundBack } from 'react-icons/io';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { BreadCrumb, Error, Loader } from '../../../../Common';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { useHistory, useLocation } from 'react-router-dom';
import { Constant } from '../../../../Helpers/constant';
import { useDispatch, useSelector } from 'react-redux';
import { VENDOR_BOOKINGS_DATA, VENDOR_BOOKINGS_DATA_EMPTY, VENDOR_DOWNLOAD_DUMP, VENDOR_DOWNLOAD_DUMP_EMPTY } from '../../../../../store/actions';

const initialState = {
  startDate: '',
  endDate: '',
};

const Report = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [state, setState] = useState(initialState);
  const [isMobile, setIsMobile] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState<any>();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.valetParking?.objectId);
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState({ code: '', name: '' });
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector((state: any) => ({
    bookings: state?.GetVendorBookings?.data,
    bookingsLoading: state?.GetVendorBookings?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector((state: any) => ({
    downloadDump: state?.VendorDownloadDump?.data,
    downloadDumpLoading: state?.VendorDownloadDump?.loading,
  }));

  // Function to check if the device is a mobile device based on the screen width
  const checkDeviceType = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    // Add event listener to window resize event
    window.addEventListener('resize', checkDeviceType);

    // Initial check of device type on component mount
    checkDeviceType();

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  useEffect(() => {
    return () => {
      dispatch({
        type: VENDOR_BOOKINGS_DATA_EMPTY,
      });
    };
  }, []);

  const buttonStyle = {
    background: 'linear-gradient(to right, #009BBD 0%, #3F9B76 100%)',
    border: 'none',
    color: 'white',
    padding: '9px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
    fontSize: '13px',
  };

  const datePickHandler = (flag: string, date) => {
    let updatedState = null;
    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      case 'endDate':
        updatedState = {
          ...state,
          endDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      default:
      // do nothing
    }
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/vendor/dashboard`);
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = (filters, code: string, name: string, filterStatus: boolean) => {
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);
    setCurrentlyAppliedFilters(filters);
    setBank({ code: code, name: name });
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: 0,
          sortOn: filters?.sortOn ?? 'updatedAt',
        },
        data: {
          ...filters,
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
        headers: {
          code: code,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    if (isMobile) {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: 'updatedAt',
          },
          data: {
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
            createdAtRange: {
              from: state.startDate,
              to: state.endDate,
            },
          },
          headers: {
            code: location?.state?.code,
          },
        },
      });
    } else {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: {
            ...currentlyAppliedFilters,
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
          headers: {
            code: bank?.code,
          },
        },
      });
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    if (isMobile) {
      if (validateErrors(state, `submissionCheck`)) {
        dispatch({
          type: VENDOR_DOWNLOAD_DUMP,
          payload: {
            urlParam: {
              bookingTypeId,
              downloadType: 1,
            },
            data: {
              createdAtRange: {
                from: state.startDate,
                to: state.endDate,
              },
              vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
            },
            headers: {
              code: location?.state?.code,
            },
          },
        });
      }
    } else {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP,
        payload: {
          urlParam: {
            bookingTypeId,
            downloadType: 1,
          },
          data: {
            createdAtRange: {
              from: currentlyAppliedFilters?.createdAtRange?.from,
              to: currentlyAppliedFilters?.createdAtRange?.to,
            },
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
          headers: {
            code: bank?.code,
          },
        },
      });
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.startDate !== `` && data?.endDate !== `` && new Date(data?.startDate) <= new Date(data?.endDate)) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  useEffect(() => {
    if (bookingsLoading || downloadDumpLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, downloadDumpLoading]);

  const handleSearch = () => {
    console.log('Ujjwal', state);
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: 0,
            sortOn: 'updatedAt',
          },
          data: {
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
            createdAtRange: {
              from: state.startDate,
              to: state.endDate,
            },
          },
          headers: {
            code: location?.state?.code,
          },
        },
      });
    }
  };

  return (
    <div className="page-content">
      {isMobile && (
        <>
          {loading && <Loader />}
          <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', position: 'relative' }}>
            <span
              style={{ cursor: 'pointer', position: 'absolute', left: '0' }}
              onClick={() => {
                history.push('/vendor/dashboard');
              }}
            >
              <IoMdArrowRoundBack size={25} />
            </span>
            <h3 className="text-center">Report Download</h3>
          </div>

          <Card>
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div className="mb-3">
                  <Label htmlFor="voucherDetailsExpiry" className="form-label">
                    Start Date
                  </Label>
                  <Flatpickr
                    className="form-control rounded-pill"
                    onChange={date => datePickHandler(`startDate`, date)}
                    value={state.startDate}
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: 'today',
                    }}
                  />
                  {isValidationShow && state?.startDate === `` && <Error text="Please select start date" />}
                  {isValidationShow && state?.startDate !== `` && new Date(state?.startDate)?.getTime() > new Date(state?.endDate)?.getTime() && (
                    <Error text="Start date is invalid" />
                  )}
                </div>

                <div className="mb-3">
                  <Label htmlFor="voucherDetailsExpiry" className="form-label">
                    End Date
                  </Label>
                  <Flatpickr
                    className="form-control rounded-pill"
                    onChange={date => datePickHandler(`endDate`, date)}
                    value={state.endDate}
                    options={{
                      dateFormat: 'Y-m-d',
                      maxDate: 'today',
                    }}
                  />
                  {isValidationShow && state?.endDate === `` && <Error text="Please select end date" />}
                  {isValidationShow && state?.endDate !== `` && new Date(state?.startDate)?.getTime() > new Date(state?.endDate)?.getTime() && (
                    <Error text="End date is invalid" />
                  )}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Button style={buttonStyle} className="rounded-pill" onClick={handleSearch}>
                  Search
                </Button>
                <Button
                  className="rounded-pill"
                  style={buttonStyle}
                  onClick={downloadDumpHandler}
                  disabled={state.startDate === '' || state.endDate === ''}
                >
                  Download
                </Button>
              </div>
            </CardBody>
          </Card>
          <Table
            data={bookings?.results}
            totalData={bookings?.count}
            pageNumber={currentPageNumber}
            pageHandler={paginationHandler}
            dumpDownload={downloadDumpHandler}
            appliedFilters={currentlyAppliedFilters}
            isMobile={isMobile}
            bankName={location?.state?.name}
          />
        </>
      )}
      {!isMobile && (
        <>
          <BreadCrumb title="Airport Transfer Booking" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `reports`]} />
          <Filter filterHandler={filterSubmitHandler} appliedFilters={currentlyAppliedFilters} />
          {loading && <Loader />}
          <Table
            data={bookings?.results}
            isMobile={isMobile}
            totalData={bookings?.count}
            pageNumber={currentPageNumber}
            pageHandler={paginationHandler}
            dumpDownload={downloadDumpHandler}
            appliedFilters={currentlyAppliedFilters}
            bankName={bank?.name}
          />
        </>
      )}
    </div>
  );
};

export default Report;
