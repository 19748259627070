import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, Input, Label, Col, ModalFooter, Row } from 'reactstrap';
import { Constant } from '../../../../../../../Helpers/constant';

import { VENDOR_UPDATE_BOOKING } from '../../../../../../../../store/application/actionType';
import { Error } from '../../../../../../../Common';
import { isANumber, isValidAplha } from '../../../../../../../Helpers/Helpers';

const RejectBookingModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    remarks: '',
    vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
  };
  const [state, setState] = useState(initialObj);
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'vendor-remarks':
        updatedState = {
          ...state,
          remarks: value,
        };
        break;

      default:
      // do nothing
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.remarks?.trim() !== ``) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      dispatch({
        type: VENDOR_UPDATE_BOOKING,
        payload: {
          data: {
            bookingTypeId: Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                process: `reject-booking`,
                vendor: {
                  ...state,
                  bookingId: data?.bookingId,
                },
              },
            ],
          },
        },
      });
      onCloseHandler(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        onCloseHandler();
      }}
      id="reject-bookings-modal"
    >
      <div className="modal-header p-3 bg-soft-danger">
        <h5 className="modal-title" id="createboardModalLabel">
          Are you sure you want to reject the booking ?
        </h5>
        <Button
          type="button"
          onClick={() => {
            onCloseHandler();
          }}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <div>
                <Label htmlFor="vendor-remarks" className="form-label">
                  Reject Remarks <span className="lbl_mandatory">*</span>
                </Label>
                <Input
                  id="vendor-remarks"
                  name="vendor-remarks"
                  type="textarea"
                  className="form-control"
                  value={state?.remarks}
                  onChange={inputChangeHandler}
                  rows={3}
                />
              </div>
              {isValidationShow && state?.remarks?.trim() === `` && <Error text="Please enter remarks" />}
            </div>
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="danger" onClick={() => onCloseHandler()}>
          Close
        </Button>
        <Button className="overlay-disable" color="primary" onClick={submitHandler}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default RejectBookingModal;
