import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';

import {
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  EMPTY_UPDATE_BOOKING,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_EMPTY,
  GET_CATEGORY,
  GET_COUNTRY,
  GET_LOCATION,
  GET_PARTNER,
  SERVICES_ON_FACILITY,
} from '../../../../../store/application/actionType';

const ValetParkingManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.valetParking.objectId);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.Bookings?.data,
    bookingsLoading: state?.Bookings?.loading,
  }));

  // Update Booking
  const { updateBookings, updateBookingsLoading } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
    updateBookingsLoading: state?.UpdateBooking?.loading,
  }));

  // Countries
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Regions
  const { regions, regionsLoading } = useSelector(state => ({
    regions: state?.Regions?.data,
    regionsLoading: state?.Regions?.loading,
  }));

  // Sub-Regions
  const { subRegions, subRegionsLoading } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
    subRegionsLoading: state?.SubRegions?.loading,
  }));

  // Category
  const { categories, categoryLoading } = useSelector(state => ({
    categories: state?.GetCategory?.data,
    categoryLoading: state?.GetCategory?.loading,
  }));

  // Partners
  const { partners, partnersLoading } = useSelector(state => ({
    partners: state?.GetPartners?.data,
    partnersLoading: state?.GetPartners?.loading,
  }));

  // Locations
  const { locations, locationsLoading } = useSelector(state => ({
    locations: state?.GetLocation?.data,
    locationsLoading: state?.GetLocation?.loading,
  }));

  // Service
  const { services, serviceLoading } = useSelector(state => ({
    services: state?.GetService?.data,
    serviceLoading: state?.GetService?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BOOKING_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });

    dispatch({
      type: SERVICES_ON_FACILITY,
      payload: {
        urlParam: {
          pagination: true,
          // facilityId: value,
        },
      },
    });

    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }

    if (categories === null) {
      dispatch({
        type: GET_CATEGORY,
      });
    }

    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }

    if (locations === null) {
      dispatch({
        type: GET_LOCATION,
        payload: {
          urlParam: {
            categoryId: Constant?.GOLF_COURSE_CATEGORY_ID,
          },
        },
      });
    }
    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: EMPTY_UPDATE_BOOKING,
      });

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  }, [updateBookings]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(0);
      setCurrentPageIndex(0);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: 0,
            sortOn: filters?.sortOn ?? 'updatedAt',
          },
          data: filters,
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);

    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    }
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Valet parking bookings`) {
      setIsDetailsPage(false);
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      bookingsLoading ||
      countriesLoading ||
      regionsLoading ||
      subRegionsLoading ||
      partnersLoading ||
      locationsLoading ||
      updateBookingsLoading ||
      downloadDumpLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    bookingsLoading,
    countriesLoading,
    regionsLoading,
    subRegionsLoading,
    partnersLoading,
    locationsLoading,
    updateBookingsLoading,
    downloadDumpLoading,
  ]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  UI'S ---------------------------- */}
          {!isDetailsPage && (
            <>
              <BreadCrumb
                title="Valet parking bookings"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Valet parking bookings`]}
              />
              <Filter
                data={{
                  countries,
                  regions,
                  subRegions,
                  categories: categories?.results,
                  partners: partners?.results,
                  locations: locations?.results,
                  services: services?.results,
                }}
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
              />
              <Table
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
              />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ValetParkingManagement;
