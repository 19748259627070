import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { GET_COUPON_PROJECTS } from '../../../store/application/actionType';
import { indianProjectsBaseURL, uaeProjectsBaseURL } from '../../Config';
import ProjectPlaceholder from './../../../assets/custom/project-placeholder-img.png';
import { AiFillProject } from 'react-icons/ai';

const activeProjectStyle = {
  backgroundColor: '#ced4da70',
  cursor: 'pointer',
};

const projectsStyle = {
  cursor: 'pointer',
};

const ProjectsDropdown = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isProjectDropdown, setIsProjectsDropdown] = useState(false);
  const toggleWebAppDropdown = () => {
    setIsProjectsDropdown(!isProjectDropdown);
  };

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects
  const { projects } = useSelector(state => ({
    projects: state?.GetCouponProject?.data,
  }));
  const [selectedProject, setSelectedProject] = useState('');

  useEffect(() => {
    if (projects !== null) {
      setSelectedProject(JSON.parse(localStorage.getItem('data'))?.program?.id || '');
    } else {
      dispatch({
        type: GET_COUPON_PROJECTS,
      });
    }
  }, [projects]);

  const logoutHandler = () => {
    localStorage.clear();
    setTimeout(() => {
      history.push(`/login`);
      window.location.reload();
    }, 500);
  };

  setInterval(() => {
    if (new Date().getTime() / 1000 >= parseInt(localStorage?.getItem('sessionPersistTill'))) {
      logoutHandler();
    }
  }, 1000);

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function getUserPermissions(userId, projectCode) {
    let projectBaseURL = ``;
    const contractingCountry = JSON.parse(localStorage?.getItem('data'))?.program?.contractingCountry?.name.toLowerCase();

    if (contractingCountry === `india`) {
      projectBaseURL = process.env.REACT_APP_INDIA_PROJECTS_URL;
    } else if (contractingCountry === `united arab emirates`) {
      projectBaseURL = process.env.REACT_APP_UAE_PROJECTS_URL;
    } else {
      projectBaseURL = process.env.REACT_APP_UAE_PROJECTS_URL;
    }

    axios({
      method: 'get',
      url: `${
        [`SAB_LANDMARK`, `SAB`, `THRIWE_VALET_PARKING`]?.includes(projectCode) ? process.env.REACT_APP_KSA_URL : projectBaseURL
      }/admin/permissions`,
      headers: {
        Authorization: JSON.parse(window.localStorage.getItem('authUser'))?.token,
        'Content-Type': 'application/json',
        'project-code': projectCode,
      },
      params: {
        userId,
      },
    }).then(function (response) {
      const tempPermissions = {
        ...JSON.parse(window.localStorage.getItem('authUser')),
        token: response?.headers?.authorization,
        permissions: jwt_decode(response?.headers?.authorization)?.permissions,
      };
      localStorage.setItem('authUser', JSON.stringify(tempPermissions));
      localStorage.setItem('sessionPersistTill', jwt_decode(response?.headers?.authorization)?.exp);

      history.push(`/dashboard`);
      window.location.reload();
    });
  }

  /* ---------------------------- PROGRAM CHANGE HANDLER ---------------------------- */
  const changeProgramHandler = async dataProjectId => {
    const projectId = dataProjectId;
    setSelectedProject(dataProjectId);

    if ([null, undefined, [], ''].includes(dataProjectId)) {
      localStorage.setItem(
        'data',
        JSON.stringify({
          program: '',
        }),
      );

      const tempPermissions = {
        ...JSON.parse(window.localStorage.getItem('authUser')),
        permissions: [],
      };
      localStorage.setItem('authUser', JSON.stringify(tempPermissions));

      history.push(`/dashboard`);
      window.location.reload();
    } else {
      const selectedProgram = projects?.filter(project => project?.id === projectId)?.[0];

      localStorage.setItem(
        'data',
        JSON.stringify({
          program: selectedProgram,
        }),
      );

      const projectCode = selectedProgram?.code;
      const userId = JSON.parse(window.localStorage?.getItem('authUser'))?.data?.userId;
      await getUserPermissions(userId, projectCode);
    }
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={isProjectDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
        <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
          <AiFillProject style={{ width: '60%', height: '60%' }} />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fw-semibold fs-15"> Programs </h6>
              </Col>
            </Row>
          </div>

          <div className="p-2" style={{ maxHeight: '80vh', overflow: 'auto' }}>
            <div className="row g-0">
              {projects?.map((project, index) => (
                <Col
                  md="4"
                  xs="12"
                  key={index}
                  style={selectedProject === project?.id ? activeProjectStyle : projectsStyle}
                  onClick={() => changeProgramHandler(project?.id)}
                >
                  <div className="dropdown-icon-item">
                    <img
                      className="flex-shink-0"
                      src={`${project?.logo === '' ? ProjectPlaceholder : project?.logo}`}
                      alt={project?.name}
                      style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                    />
                    <p
                      style={{
                        lineHeight: '16px',
                        marginTop: '5px',
                        textWrap: 'wrap',
                        fontSize: '11px',
                        padding: '0 3px',
                      }}
                    >
                      {project?.name}
                    </p>
                  </div>
                </Col>
              ))}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProjectsDropdown;
