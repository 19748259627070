import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';

import CancelModal from './Components/CancelModal';
import SuccessModal from './Components/SuccessModal';
import { uaeProjectsBaseURL } from '../../../../Config';
import { BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import BookingTableMobileScreen from './Components/BookingTableMobileScreen';
import BookingTableLaptopScreen from './Components/BookingTableLaptopScreen';
import { VENDOR_BOOKINGS_DATA, VENDOR_BOOKINGS_DATA_EMPTY, VENDOR_UPDATE_BOOKING, VENDOR_UPDATE_BOOKING_EMPTY } from '../../../../../store/actions';
import Select from 'react-select';

const initialValues = {
  client: null,
};

const themeStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '8px 15px',
    ':hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid #80808026',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
  }),

  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),

  control: (styles, state) => ({
    ...styles,
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '2px 2px 2px 40px',
  }),

  menu: styles => ({
    ...styles,
    padding: '5px 0',
    margin: '5px 0',
    borderRadius: '20px',
    width: '100%',
    overflow: 'hidden',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, borderBottom: '1px solid black', display: 'none' }),
};

const VendorBookingValetParking = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(false);
  const [currentBookingDetails, setCurrentBookingDetails] = useState(null);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.valetParking?.objectId);
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [state, setState] = useState(initialValues);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [bookingsData, setBookingsData] = useState([]);
  const [hasMoreBookingsData, setHasMoreBookingsData] = useState(true);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    const { clients } = JSON.parse(localStorage.getItem('authUser'));
    if (clients?.length > 0) {
      setClientList(
        clients?.map((client: string) => {
          return { value: client, label: `${client} Bank`, completeData: { code: client, name: `${client} Bank` } };
        }),
      );
    }
  }, []);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector((state: any) => ({
    bookings: state?.GetVendorBookings?.data,
    bookingsLoading: state?.GetVendorBookings?.loading,
  }));

  // Update Booking
  const { updateBookings, updateBookingsLoading } = useSelector((state: any) => ({
    updateBookings: state?.VendorUpdateBooking?.data,
    updateBookingsLoading: state?.VendorUpdateBooking?.loading,
  }));

  useEffect(() => {
    if (bookings !== null && bookings?.results?.length > 0) {
      setHasMoreBookingsData(true);
      if (updateBookings !== null) {
        setBookingsData(bookings.results);
      } else {
        setBookingsData(prevData => [...prevData, ...bookings.results]);
      }
      dispatch({
        type: VENDOR_UPDATE_BOOKING_EMPTY,
      });
    } else {
      setHasMoreBookingsData(false);
    }
  }, [bookings]);

  const loadOptionForClient = async () => {
    let urlParam = null;
    return await axios({
      method: `GET`,
      url: `${uaeProjectsBaseURL}/vendor/clients`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        // ...customHeaders,
      },
      params: {
        ...urlParam,
      },
    })
      .then(function (response) {
        return {
          options: response?.data?.data?.map(data => {
            return {
              value: data?.name,
              label: `${data?.name}`,
              completeData: data,
            };
          }),
          hasMore: false,
        };
      })
      .catch(err => {
        return err;
      });
  };

  const onSelectClient = selectedOption => {
    setState({ ...state, client: selectedOption });
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: 0,
          sortOn: 'updatedAt',
        },
        headers: {
          code: selectedOption?.completeData?.code,
        },
        data: {
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
      },
    });
  };

  // Function to check if the device is a mobile device based on the screen width
  const checkDeviceType = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    // Add event listener to window resize event
    window.addEventListener('resize', checkDeviceType);
    // Initial check of device type on component mount
    checkDeviceType();
    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: 0,
            sortOn: 'updatedAt',
          },
          headers: {
            code: location?.state?.code ? location?.state?.code : state?.client?.value,
          },
          data: {
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
        },
      });

      if (showSuccessModal) {
        setIsSuccessModal(true);
        setShowSuccessModal(false);
      } else {
        setIsCancelModal(false);
      }
    }
  }, [updateBookings]);

  useEffect(() => {
    if (location?.state && location?.state?.code && isMobile) {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: 0,
            sortOn: 'updatedAt',
          },
          headers: {
            code: location?.state?.code,
          },
          data: {
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
        },
      });
    } else if (isMobile) {
      history.push('/vendor/dashboard');
    }
    return () => {
      dispatch({
        type: VENDOR_BOOKINGS_DATA_EMPTY,
      });
    };
  }, [location.state, dispatch, isMobile]);

  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: 'updatedAt',
        },
        headers: {
          code: isMobile ? location?.state?.code : state?.client?.completeData?.code,
        },
        data: {
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
      },
    });
  };

  const tableActionHandler = (flag: string, bookingDetails) => {
    if (flag === 'Reject') {
      setIsCancelModal(true);
      setCurrentBookingDetails(bookingDetails);
    } else if (flag === 'Accept') {
      setShowSuccessModal(true);
      dispatch({
        type: VENDOR_UPDATE_BOOKING,
        payload: {
          data: {
            bookingTypeId,
            bookingsData: [
              {
                objectId: bookingDetails?.bookingId,
                process: 'accept-booking',
                vendor: {
                  ...bookingDetails?.vendor,
                  bookingId: bookingDetails?.bookingId,
                  vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
                },
              },
            ],
          },
          headers: {
            code: isMobile ? location?.state?.code : bookingDetails?.bankCode,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (bookingsLoading || updateBookingsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, updateBookingsLoading]);

  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/vendor/dashboard`);
    }
  };

  const handleBookingStatus = (flag, data) => {
    if (flag === 'Cancel') {
      dispatch({
        type: VENDOR_UPDATE_BOOKING,
        payload: {
          data: {
            bookingTypeId,
            bookingsData: [
              {
                objectId: data?.bookingId,
                process: 'reject-booking',
                vendor: {
                  bookingId: data?.bookingId,
                  vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
                },
              },
            ],
          },
          headers: {
            code: isMobile ? location?.state?.code : currentBookingDetails?.bankCode,
          },
        },
      });
    }
  };

  const inputSelectHandler = (label: string, data) => {
    let updatedState = null;

    if (label === `clients`) {
      updatedState = {
        client: data,
      };
      setState(updatedState);
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: 0,
            sortOn: 'updatedAt',
          },
          headers: {
            code: data?.value,
          },
          data: {
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
        },
      });
    }
  };

  return (
    <div className="page-content">
      {loading && <Loader />}
      {isCancelModal && (
        <CancelModal
          isCancelModal={isCancelModal}
          setIsCancelModal={setIsCancelModal}
          currentBookingDetails={currentBookingDetails}
          handleBookingStatus={handleBookingStatus}
        />
      )}

      {isSuccessModal && <SuccessModal isSuccessModal={isSuccessModal} setIsSuccessModal={setIsSuccessModal} />}

      {isMobile ? (
        <Card>
          <CardHeader>
            <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', position: 'relative' }}>
              <span
                style={{ cursor: 'pointer', position: 'absolute', left: '0' }}
                onClick={() => {
                  history.push('/vendor/dashboard');
                }}
              >
                <IoMdArrowRoundBack size={25} />
              </span>
              <h3 className="text-center">{t('Booking Details')}</h3>
            </div>
            <BookingTableMobileScreen
              actionHandler={tableActionHandler}
              data={bookingsData}
              totalData={bookingsData?.length}
              pageNumber={currentPageNumber}
              pageHandler={paginationHandler}
              hasMoreBookingsData={hasMoreBookingsData}
              bankName={location?.state?.name}
            />
          </CardHeader>
        </Card>
      ) : (
        <>
          <BreadCrumb
            title="Airport Transfer Booking"
            navigationby={breadcrumNavigationHandler}
            navigation={[`Dashboard`, `Valet Parking Booking`]}
          />
          <Card>
            <CardBody>
              <Row>
                <Col md={4}>
                  <Label htmlFor="client" className="form-label fs-5">
                    {t('Select Client')}
                  </Label>
                  <div className="mb-2 position-relative">
                    <Select
                      id="clients"
                      name="currency"
                      value={state?.client}
                      options={clientList}
                      onChange={data => inputSelectHandler(`clients`, data)}
                      styles={themeStyles}
                    />
                    <div className="select-icon-start">
                      <i className="ri-bank-line text-dark fs-20 align-middle me-1"></i>
                    </div>
                  </div>
                </Col>
              </Row>
              <BookingTableLaptopScreen
                actionHandler={tableActionHandler}
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                bankName={state.client}
              />
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default VendorBookingValetParking;
