import { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';

const BenefitPackInfoTable = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: 'S.No.',
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: 'Benefit Group Name',
          selector: row => row?.name,
          wrap: true,
          width: '220px',
        },
        {
          name: 'Applicable Date',
          selector: row => row?.applicableDate,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Expiry Date',
          selector: row => row?.expiryDate,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Count',
          selector: row => row?.count,
          wrap: true,
          width: '100px',
        },
        {
          name: 'Generated By',
          selector: row => row?.createdBy,
          wrap: true,
          width: '180px',
        },
        {
          name: 'Creation Date',
          selector: row => row?.createdAt,
          width: '200px',
          wrap: true,
        },
        {
          name: 'Actions',
          width: '180px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column w-100 p-2">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_PACK_READ) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`VIEW`, row?.data);
                  }}
                >
                  View
                </span>
              )}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_PACK_UPDATE) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`UPDATE`, row?.data);
                  }}
                >
                  Update
                </span>
              )}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_PACK_CHANGE_EXPIRY) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`CHANGE_EXPIRY`, row?.data);
                  }}
                >
                  Change Expiry
                </span>
              )}

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.DOWNLOAD_ACCESS) && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`DOWNLOAD`, row?.data);
                  }}
                >
                  Download
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColums);

      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            name: data?.benefitGroup?.name || `NA`,
            count: data?.count || `NA`,
            createdBy: data?.createdBy?.name || `NA`,
            applicableDate: data?.applicableDate ? moment(new Date(`${data?.applicableDate}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            createdAt: data?.createdAt ? moment(new Date(`${data?.createdAt}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            expiryDate: data?.expiryDate ? moment(new Date(`${data?.expiryDate}`)).format('DD-MM-YYYY, h:mm A') : 'NA',
            data,
          };
        }),
      );
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `EDIT`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    } else if (flag === `VIEW`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    } else if (flag === `DOWNLOAD`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    } else if (flag === `CHANGE_EXPIRY`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        data: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{`Benefit Packs`}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.BENEFIT_PACK_CREATE) && (
                <span className="btn btn-success mb-1" onClick={props?.onCreateBenefitPack}>
                  <div className="me-2">
                    <i className="ri-add-line me-1 text-white text-opacity-75 fs-14 align-middle"></i>Create Benefit Pack
                  </div>
                </span>
              )}
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BenefitPackInfoTable;
