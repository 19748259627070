import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { UPDATE_BINS } from '../../../../../../../store/application/actionType';

const UpdateBinModal = ({ data, selectedProject, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    number: '',
    name: '',
    description: '',
    gateways: [],
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    if (data !== null) {
      setState({ ...data });
      validationCheck({ ...data });
    }
  }, []);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'number':
        updatedState = {
          ...state,
          number: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `gateways`) {
      updatedState = {
        ...state,
        gateways: data,
      };
      setState(updatedState);
      validationCheck(updatedState);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.name !== `` && data?.description !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const payload = {
      ...state,
      projectCode: selectedProject?.code,
    };

    dispatch({
      type: UPDATE_BINS,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
      //   centered
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Update Bin
        </h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(data)}>Get state</button> */}
        <form>
          <Row>
            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Name <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="name" name="name" value={state?.name} className="form-control" onChange={inputChangeHandler} />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Number
                  </Label>
                  <Input
                    id="number"
                    name="number"
                    value={state?.number}
                    className="form-control"
                    disabled={true}
                    // onChange={inputChangeHandler}
                  />
                </div>
              </Col>
            </div>

            <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Description <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="description" name="description" value={state?.description} className="form-control" onChange={inputChangeHandler} />
                </div>
              </Col>
            </div>

            {/* <div className="mb-3">
              <Col md={12}>
                <div>
                  <Label htmlFor="name" className="form-label">
                    Payment Gateway <span className="lbl_mandatory">*</span>
                  </Label>
                  <Select
                    id="gateways"
                    name="gateways"
                    isMulti={true}
                    value={state?.gateways}
                    options={[...data.allpaymentGateways]}
                    onChange={(data) => inputSelectHandler(`gateways`, data)}
                  />
                </div>
              </Col>
            </div> */}
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateBinModal;
