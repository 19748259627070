import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import classnames from 'classnames';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Constant } from '../../../../../../Helpers/constant';
import { Error, PreviewCardHeader } from './../../../../../../Common';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter, Accordion, AccordionItem, Collapse } from 'reactstrap';
import { hasPermission, isValidAlphaWithSpec, isValidAplha, isValidUrl, validateUrl } from '../../../../../../Helpers/Helpers';
import { GET_PARENT_CATEGORY, CREATE_CATEGORY, GET_BOOKING_TYPES_LIST, UPDATE_CATEGORY } from '../../../../../../../store/application/actionType';

const AddCategoryForm = props => {
  const dispatch = useDispatch();

  const [accordions, setAccordions] = useState({
    general: {
      status: true,
      error: false,
    },
    displayProperties: {
      status: false,
      error: false,
    },
    amenities: {
      status: false,
      error: false,
    },
  });

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: '',
    displayName: '',
    parentCategory: null,
    images: {
      logo: '',
      coverImageMobile: '',
      coverImageWeb: '',
      displayImageMobile: '',
      displayImageWeb: '',
    },
    longDescription: '',
    shortDescription: '',
    defaultRedemptionType: -1,
    bookingType: null,
    workflowType: null,
  };
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [hasParentCategory, setHasParentCategory] = useState(false);

  const [amenities, setAmenities] = useState([{ name: '', logo: '' }]);

  const [parentCategories, setParentCategories] = useState([]);
  const [bookingTypesData, setBookingTypesData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Parent Category
  const { parentCategory } = useSelector(state => ({
    parentCategory: state?.GetParentCategory?.data,
  }));

  // Redemption Type
  const { redemptionType } = useSelector(state => ({
    redemptionType: state?.GetRedemptionType?.data,
  }));

  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  useEffect(() => {
    if (parentCategory === null) {
      dispatch({
        type: GET_PARENT_CATEGORY,
      });
    }

    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  useEffect(() => {
    if (parentCategory !== null) {
      setParentCategories(
        parentCategory?.map(category => {
          return {
            value: category?.objectId,
            label: category?.name,
          };
        }),
      );
    }
  }, [parentCategory]);

  useEffect(() => {
    if (props?.data !== undefined) {
      const tempStateData = {
        ...props?.data,
        parentCategory: {
          value: props?.data?.parentCategory?.id,
          label: props?.data?.parentCategory?.name,
        },
        bookingType: {
          value: props?.data?.bookingType?.objectId,
          label: props?.data?.bookingType?.name,
          data: props?.data?.bookingType,
        },
        workflowType: props?.data?.workflowType ?? null,
      };
      setState({ ...tempStateData });

      if (props?.data?.amenities?.length > 0) {
        setAmenities(
          props?.data?.amenities?.map(amenity => ({
            name: amenity?.name,
            logo: amenity?.logo,
          })),
        );
      }

      if (props?.data?.parentCategory?.id !== ``) {
        setHasParentCategory(true);
      }
    }
  }, [props]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'has-parent-category':
        setHasParentCategory(!hasParentCategory);
        updatedState = {
          ...state,
          parentCategory: null,
        };
        break;

      case 'name':
        updatedState = {
          ...state,
          name: isValidAlphaWithSpec(value) ? value : state?.name,
        };
        break;

      case 'display-name':
        updatedState = {
          ...state,
          displayName: isValidAlphaWithSpec(value) ? value : state?.displayName,
        };
        break;

      case 'logo':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            logo: value,
          },
        };
        break;

      case 'cover-img-mobile-url':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageMobile: value,
          },
        };
        break;

      case 'cover-img-web-url':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            coverImageWeb: value,
          },
        };
        break;

      case 'display-img-mobile-url':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageMobile: value,
          },
        };
        break;

      case 'display-img-web-url':
        updatedState = {
          ...state,
          images: {
            ...state.images,
            displayImageWeb: value,
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `bookingType`) {
      updatedState = {
        ...state,
        bookingType: data,
      };
    } else if (label === `workflowType`) {
      updatedState = {
        ...state,
        workflowType: data,
      };
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const ckEditorDataInput = (name, data) => {
    let updatedState = { ...state };

    switch (name) {
      case 'shortDescription':
        updatedState = {
          ...state,
          shortDescription: data,
        };
        break;

      case 'longDescription':
        updatedState = {
          ...state,
          longDescription: data,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  const amenitiyChangeHandler = (e, index) => {
    const { name, value } = e.target;

    const tempAmenities = [...amenities];
    if (name === 'amenityName') {
      tempAmenities[index] = {
        ...tempAmenities[index],
        name: isValidAplha(value) ? value : tempAmenities[index]['name'],
      };
    } else if (name === 'amenityLogo') {
      tempAmenities[index] = {
        ...tempAmenities[index],
        logo: value,
      };
    }
    setAmenities(tempAmenities);
  };

  const parentCategoryInputHandler = data => {
    let updatedState = null;

    updatedState = {
      ...state,
      parentCategory: data,
    };
    setState(updatedState);
    validateErrors(`validationCheck`, updatedState);
  };

  /* ---------------------------- ADD AMENITY ---------------------------- */
  const addNewAmenityHandler = index => {
    const tempAmenities = [...amenities];
    tempAmenities.push({
      name: ``,
      logo: ``,
    });
    setAmenities(tempAmenities);
    validateErrors(`validationCheck`, state, tempAmenities);
  };

  /* ---------------------------- REMOVE AMENITY ---------------------------- */
  const removeNewAmenityHandler = index => {
    const tempAmenities = [...amenities];
    tempAmenities.splice(index, 1);
    setAmenities(tempAmenities);

    validateErrors(`validationCheck`, state, tempAmenities);
  };

  /* ---------------------------- VALIDATE ERROR ---------------------------- */
  const validateErrors = (flag, data = state, amenitiesData = amenities) => {
    let isError = false;
    let accordionsTemp = { ...accordions };

    if (
      data?.name?.trim() === `` ||
      data?.images?.logo === `` ||
      !isValidUrl(data?.images?.logo) ||
      data?.bookingType === null ||
      data?.workflowType === null
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          ...accordionsTemp?.general,
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        general: {
          ...accordionsTemp?.general,
          status: flag === `validationCheck` ? accordionsTemp?.general?.status : false,
          error: false,
        },
      };
    }

    if (
      data?.displayName?.trim() === `` ||
      !isValidUrl(data?.images?.coverImageMobile) ||
      !isValidUrl(data?.images?.coverImageWeb) ||
      !isValidUrl(data?.images?.displayImageMobile) ||
      !isValidUrl(data?.images?.displayImageWeb)
    ) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          ...accordionsTemp?.displayProperties,
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        displayProperties: {
          ...accordionsTemp?.displayProperties,
          status: flag === `validationCheck` ? accordionsTemp?.displayProperties?.status : false,
          error: false,
        },
      };
    }

    let amenitieStatus = false;
    for (let i = 0; i < amenitiesData?.length; i++) {
      if (amenitiesData[i]['name']?.trim() === `` || amenitiesData[i]['logo'] === `` || !isValidUrl(amenitiesData[i]['logo'])) {
        amenitieStatus = true;
        break;
      }
    }
    if (amenitieStatus) {
      isError = true;
      accordionsTemp = {
        ...accordionsTemp,
        amenities: {
          ...accordionsTemp?.amenities,
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: flag === `submissionCheck` ? isError : isValidationShow ? true : false,
        },
      };
    } else {
      accordionsTemp = {
        ...accordionsTemp,
        amenities: {
          ...accordionsTemp?.amenities,
          status: flag === `validationCheck` ? accordionsTemp?.amenities?.status : false,
          error: false,
        },
      };
    }

    // setIsValidationShow(isError)
    setIsValidationShow(flag === `submissionCheck` ? isError : isValidationShow ? true : false);
    setAccordions({ ...accordionsTemp });
    return isError;
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (!validateErrors(`submissionCheck`)) {
      const payload = {
        ...state,
        parentId: hasParentCategory ? 1 : 0,
        parentCategory: {
          id: hasParentCategory ? state?.parentCategory?.value : `-`,
          name: hasParentCategory ? state?.parentCategory?.label : `-`,
        },
        amenities: amenities,
        bookingType: state?.bookingType?.data,
      };

      if (props?.title === `Update Category`) {
        dispatch({
          type: UPDATE_CATEGORY,
          payload: {
            data: {
              requests: [{ ...payload }],
            },
          },
        });
      } else if (props?.title === `Add Category`) {
        dispatch({
          type: CREATE_CATEGORY,
          payload: {
            data: payload,
          },
        });
      }
    }
  };

  /* ---------------------------- ACCORDION HANDLER ---------------------------- */
  const accordionToggleHandler = flag => {
    setAccordions({
      ...accordions,
      [flag]: {
        ...accordions[flag],
        status: !accordions[flag]['status'],
      },
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Row>
        <Col xxl={12}>
          {/* <button onClick={() => console.log(state)}>Get state</button> */}
          <Card>
            <PreviewCardHeader
              title={props?.title}
              primaryButton={hasPermission(PermissionConstants?.ALL_PERMISSIONS?.UPLOAD_FILE) && props?.title === `Add Category`}
              primaryButtonText={`Upload Category`}
              primaryClickHandler={() => props?.bulkUploadAction()}
            />
            <CardBody>
              <Form>
                <Row>
                  <Col xxl={12}>
                    <CardBody>
                      <Accordion
                        className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-secondary"
                        id="accordionBordered"
                      >
                        {/* ---------------------------- ACCORDION - General ---------------------------- */}
                        <AccordionItem>
                          <h2 className="accordion-header" id="accordionborderedExample1">
                            <button
                              className={classnames('accordion-button', {
                                collapsed: !accordions?.general?.status,
                              })}
                              type="button"
                              style={{ cursor: 'pointer' }}
                              onClick={() => accordionToggleHandler(`general`)}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div>General</div>
                                {accordions?.general?.error && (
                                  <div
                                    style={{
                                      marginRight: '23px',
                                      color: 'red',
                                    }}
                                  >
                                    <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                  </div>
                                )}
                              </div>
                            </button>
                          </h2>

                          <Collapse isOpen={accordions?.general?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                            <div className="accordion-body color-black">
                              <Row>
                                <Col md={12}>
                                  <div className="mb-3">
                                    <div className="form-check form-switch">
                                      <Input
                                        role="switch"
                                        type="checkbox"
                                        id="has-parent-category"
                                        name="has-parent-category"
                                        className="form-check-input"
                                        // defaultChecked={hasParentCategory}
                                        checked={hasParentCategory}
                                        onChange={inputChangeHandler}
                                      />
                                      <Label className="form-check-label" htmlFor="has-parent-category">
                                        Has it Parent Category
                                      </Label>
                                    </div>
                                  </div>
                                </Col>

                                <Row>
                                  <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                    <div className="mb-3">
                                      <Select
                                        id="parentCategory"
                                        name="parentCategory"
                                        options={parentCategories ?? []}
                                        value={state.parentCategory}
                                        onChange={parentCategoryInputHandler}
                                        isDisabled={!hasParentCategory}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <hr style={{ backgroundColor: '#9ea0a1' }} />

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="name" className="form-label">
                                      System Name
                                      <span className="lbl_mandatory">*</span>
                                    </Label>
                                    <Input
                                      id="name"
                                      name="name"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.name}
                                      autoComplete="off"
                                      disabled={props?.title !== `Add Category`}
                                    />
                                    {isValidationShow && state?.name?.trim() === `` && <Error text="Please enter system name" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="logo" className="form-label">
                                      Logo <span className="lbl_mandatory">*</span>
                                    </Label>
                                    <Input
                                      id="logo"
                                      name="logo"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.images?.logo}
                                      autoComplete="off"
                                    />
                                    {isValidationShow && !validateUrl(state?.images?.logo) && <Error text="Please enter valid URL" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="bookingType" className="form-label">
                                      Booking Type
                                      <span className="lbl_mandatory"> *</span>
                                    </Label>
                                    <Select
                                      id="bookingType"
                                      name="bookingType"
                                      options={bookingTypesData ?? []}
                                      value={state?.bookingType}
                                      onChange={data => inputSelectHandler(`bookingType`, data)}
                                    />
                                    {isValidationShow && state?.bookingType === null && <Error text="Please select booking type" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="workflowType" className="form-label">
                                      Workflow Type
                                      <span className="lbl_mandatory"> *</span>
                                    </Label>
                                    <Select
                                      id="workflowType"
                                      name="workflowType"
                                      options={bookingTypesData ?? []}
                                      value={state?.workflowType}
                                      onChange={data => inputSelectHandler(`workflowType`, data)}
                                    />
                                    {isValidationShow && state?.workflowType === null && <Error text="Please select workflow type" />}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Collapse>
                        </AccordionItem>

                        {/* ---------------------------- ACCORDION - DISPLAY PROPERTIES ---------------------------- */}
                        <AccordionItem>
                          <h2 className="accordion-header" id="accordionborderedExample1">
                            <button
                              className={classnames('accordion-button', {
                                collapsed: !accordions?.displayProperties?.status,
                              })}
                              type="button"
                              onClick={() => accordionToggleHandler(`displayProperties`)}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div> Display Properties</div>
                                {accordions?.displayProperties?.error && (
                                  <div
                                    style={{
                                      marginRight: '23px',
                                      color: 'red',
                                    }}
                                  >
                                    <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                  </div>
                                )}
                              </div>
                            </button>
                          </h2>

                          <Collapse isOpen={accordions?.displayProperties?.status} className="accordion-collapse" id="accor_borderedExamplecollapse1">
                            <div className="accordion-body color-black">
                              <Row>
                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="display-name" className="form-label">
                                      Display Name <span className="lbl_mandatory">*</span>
                                    </Label>
                                    <Input
                                      id="display-name"
                                      name="display-name"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.displayName}
                                      autoComplete="off"
                                    />
                                    {isValidationShow && state?.displayName?.trim() === `` && <Error text="Please enter display name" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="cover-img-mobile-url" className="form-label">
                                      Cover Image Mobile URL
                                    </Label>
                                    <Input
                                      id="cover-img-mobile-url"
                                      name="cover-img-mobile-url"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.images?.coverImageMobile}
                                      autoComplete="off"
                                    />
                                    {isValidationShow && !isValidUrl(state?.images?.coverImageMobile) && <Error text="Please enter valid URL" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="cover-img-web-url" className="form-label">
                                      Cover Image Web URL
                                    </Label>
                                    <Input
                                      id="cover-img-web-url"
                                      name="cover-img-web-url"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.images?.coverImageWeb}
                                      autoComplete="off"
                                    />
                                    {isValidationShow && !isValidUrl(state?.images?.coverImageWeb) && <Error text="Please enter valid URL" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="display-img-mobile-url" className="form-label">
                                      Display Image Mobile URL
                                    </Label>
                                    <Input
                                      id="display-img-mobile-url"
                                      name="display-img-mobile-url"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.images?.displayImageMobile}
                                      autoComplete="off"
                                    />
                                    {isValidationShow && !isValidUrl(state?.images?.displayImageMobile) && <Error text="Please enter valid URL" />}
                                  </div>
                                </Col>

                                <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="display-img-web-url" className="form-label">
                                      Display Image Web URL
                                    </Label>
                                    <Input
                                      id="display-img-web-url"
                                      name="display-img-web-url"
                                      type="text"
                                      className="form-control"
                                      onChange={inputChangeHandler}
                                      value={state?.images?.displayImageWeb}
                                      autoComplete="off"
                                    />
                                    {isValidationShow && !isValidUrl(state?.images?.displayImageWeb) && <Error text="Please enter valid URL" />}
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="defaultRedemptionProcess" className="form-label">
                                      Short Description
                                    </Label>
                                    <div className="snow-editor" style={{ minHeight: 300 }}>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={state?.shortDescription || ''}
                                        config={{
                                          toolbar: {
                                            items: Constant.CkEditorToolbar,
                                          },
                                        }}
                                        onBlur={(event, editor) => {
                                          const data = editor.getData();
                                          ckEditorDataInput(`shortDescription`, data);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col md={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="defaultRedemptionProcess" className="form-label">
                                      Long Description
                                    </Label>
                                    <div className="snow-editor" style={{ minHeight: 300 }}>
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={state?.longDescription || ''}
                                        config={{
                                          toolbar: {
                                            items: Constant.CkEditorToolbar,
                                          },
                                        }}
                                        onBlur={(event, editor) => {
                                          const data = editor.getData();
                                          ckEditorDataInput(`longDescription`, data);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Collapse>
                        </AccordionItem>

                        {/* ---------------------------- ACCORDION - AMENTIES ---------------------------- */}
                        <AccordionItem>
                          <h2 className="accordion-header" id="accordionborderedExample2">
                            <button
                              className={classnames('accordion-button', {
                                collapsed: !accordions?.amenities?.status,
                              })}
                              type="button"
                              onClick={() => accordionToggleHandler(`amenities`)}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div> Amenities</div>
                                {accordions?.amenities?.error && (
                                  <div
                                    style={{
                                      marginRight: '23px',
                                      color: 'red',
                                    }}
                                  >
                                    <i className="ri-error-warning-line align-middle fs-16 text-danger "></i> Error in submission
                                  </div>
                                )}
                              </div>
                            </button>
                          </h2>

                          <Collapse isOpen={accordions?.amenities?.status} className="accordion-collapse" id="accor_borderedExamplecollapse2">
                            <div className="accordion-body color-black">
                              {amenities?.map((amenity, index) => {
                                return (
                                  <Row key={index}>
                                    <Col md={5}>
                                      <div className="mb-3">
                                        <Label htmlFor="amenityName" className="form-label">
                                          Name
                                          <span className="lbl_mandatory">*</span>
                                        </Label>
                                        <Input
                                          id="amenityName"
                                          name="amenityName"
                                          type="text"
                                          className="form-control"
                                          onChange={e => amenitiyChangeHandler(e, index)}
                                          value={amenity?.name}
                                          autoComplete="off"
                                        />
                                        {isValidationShow && amenity?.name?.trim() === `` && <Error text="Please enter name" />}
                                      </div>
                                    </Col>

                                    <Col md={5}>
                                      <div className="mb-3">
                                        <Label htmlFor="amenityLogo" className="form-label">
                                          Logo
                                          <span className="lbl_mandatory"> *</span>
                                        </Label>
                                        <Input
                                          id="amenityLogo"
                                          name="amenityLogo"
                                          type="text"
                                          className="form-control"
                                          onChange={e => amenitiyChangeHandler(e, index)}
                                          value={amenity?.logo}
                                          autoComplete="off"
                                        />
                                        {isValidationShow && !validateUrl(amenity?.logo) && <Error text="Please enter valid logo" />}
                                      </div>
                                    </Col>

                                    <Col md={2}>
                                      <div className="mb-3">
                                        <Label htmlFor="display-img-web-url" className="form-label">
                                          &nbsp;
                                        </Label>
                                        <div>
                                          <button
                                            type="submit"
                                            className="btn btn-primary mx-3 "
                                            onClick={e => {
                                              e.preventDefault();
                                              addNewAmenityHandler(index);
                                            }}
                                          >
                                            +
                                          </button>

                                          <button
                                            type="submit"
                                            className="btn btn-danger mx-3 "
                                            onClick={e => {
                                              e.preventDefault();
                                              removeNewAmenityHandler(index);
                                            }}
                                            disabled={amenities.length === 1}
                                          >
                                            -
                                          </button>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </div>
                          </Collapse>
                        </AccordionItem>
                      </Accordion>
                    </CardBody>
                  </Col>
                </Row>
              </Form>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-3 overlay-disable"
                      onClick={submitHandler}
                      // disabled={isSubmitBtnDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AddCategoryForm;
