import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Card, CardBody, Col, Row, Container, Label } from 'reactstrap';
import { Constant } from '../../../../../../Helpers/constant';

const BookingDetails = ({ details }) => {
  const [bookingDetails, setBookingDetails] = useState([]);

  let lastUpdatedBy = `NA`;
  let confirmedBy = `NA`;
  let processedBy = `NA`;
  let cancelledBy = `NA`;
  let cancelledReason = `NA`;

  let confirmedAt = `NA`;
  let processedAt = `NA`;
  let cancelledAt = `NA`;
  let latestBookingStatus = details?.bookingStatusCode;

  details?.bookingModifyPersons?.forEach(element => {
    if (element?.bookingStatus === latestBookingStatus) {
      lastUpdatedBy = element?.name;
    }
    if (
      element?.bookingStatus === Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE ||
      element?.bookingStatus === Constant.BOOKING_STATUS?.SHORT_NOTICE_CANCELLED_BY_THRIWE
    ) {
      cancelledBy = element?.name;
      cancelledReason = element.remarks;
      cancelledAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
      confirmedBy = element?.name;
      confirmedAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
    if (element?.bookingStatus === Constant?.BOOKING_STATUS.PROCESSED) {
      processedBy = element?.name;
      processedAt = moment(`${element?.modifiedAt}`).format('DD-MM-YYYY, h:mm A');
    }
  });

  useEffect(() => {
    setBookingDetails(details);
  }, [details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container fluid>
      {/* <button onClick={() => console.log({ details })}>Get state</button> */}
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.bookingId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{details?.user?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Gender'}</Label>
                    <Label className="text-muted">{Constant?.GENDER_TYPES[details?.gender?.type - 1] || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">User Contact Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">{details?.user?.mobile || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.user?.email || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Dates</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {details?.membershipId || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">
                Payment details
              </h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Order Id'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.orderId || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Payment Id'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.paymentId || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Invoice No.'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.invoiceNumber || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Amount</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Rack Rate'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.rackRate || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Total Amount Paid'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.totalAmountPaid || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Processing Fee'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.processingFee || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Taxes'}</Label>
                    <Label className="text-muted">
                      {details?.payment?.taxes || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">
                Location & Golf Course
              </h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {details?.facility || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Address'}</Label>
                    <Label className="text-muted">
                      {details?.address || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'City'}</Label>
                    <Label className="text-muted">
                      {details?.subregion || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'State'}</Label>
                    <Label className="text-muted">
                      {details?.region || `NA`}
                    </Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Country'}</Label>
                    <Label className="text-muted">
                      {details?.country || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Pick up Address</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Name'}</Label>
                    <Label className="text-muted">{details?.courierPickupAddress?.fullName || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile'}</Label>
                    <Label className="text-muted">{details?.courierPickupAddress?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick Up Address'}</Label>
                    <Label className="text-muted">{details?.courierPickupAddress?.fullAddress?.address || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Region'}</Label>
                    <Label className="text-muted">{details?.courierPickupAddress?.emirates?.location || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Sub Region'}</Label>
                    <Label className="text-muted">{details?.courierPickupAddress?.region?.location || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Landmark'}</Label>
                    <Label className="text-muted">{details?.courierPickupAddress?.landmark || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Drop Address</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Name'}</Label>
                    <Label className="text-muted">{details?.courierDropAddress?.fullName || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile'}</Label>
                    <Label className="text-muted">{details?.courierDropAddress?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Drop Address'}</Label>
                    <Label className="text-muted">{details?.courierDropAddress?.fullAddress?.address || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Region'}</Label>
                    <Label className="text-muted">{details?.courierDropAddress?.emirates?.location || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Sub Region'}</Label>
                    <Label className="text-muted">{details?.courierDropAddress?.region?.location || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Landmark'}</Label>
                    <Label className="text-muted">{details?.courierDropAddress?.landmark || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Guest Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {details?.membershipId || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Driver Details</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Driver Name'}</Label>
                    <Label className="text-muted">{details?.driver?.name || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Driver Mobile'}</Label>
                    <Label className="text-muted">{details?.driver?.mobile || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up Date'}</Label>
                    <Label className="text-muted">
                      {details?.driver?.pickupDate ? moment(`${details?.driver?.pickupDate}`).format('DD-MM-YYYY') : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Pick up Time'}</Label>
                    <Label className="text-muted">{details?.driver?.pickupTime || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Creation and Service</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date of request'}</Label>
                    <Label className="text-muted">{moment(`${details?.createdAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Date of Service'}</Label>
                    <Label className="text-muted">{`${details?.date}`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Time of Service'}</Label>
                    <Label className="text-muted">{`${details?.time ? moment(`${details?.time}`, 'HH:mm').format('h:mm A') : `NA`}`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action Date & Time</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Processed At'}</Label>
                    <Label className="text-muted">{processedAt}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Confirmed At'}</Label>
                    <Label className="text-muted">{confirmedAt}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Booking Cancelled At'}</Label>
                    <Label className="text-muted">{cancelledAt}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last updated at'}</Label>
                    <Label className="text-muted">{moment(`${details?.updatedAt}`).format('DD-MM-YYYY, h:mm A') || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Action By</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Last Updated By'}</Label>
                    <Label className="text-muted">{lastUpdatedBy}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{' Processed By'}</Label>
                    <Label className="text-muted">{processedBy}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Confirmed By'}</Label>
                    <Label className="text-muted">{confirmedBy}</Label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Cancelled By'}</Label>
                    <Label className="text-muted">{cancelledBy}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3"> Remarks and Reason</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Remarks'}</Label>
                    <Label className="text-muted">{details?.remarks || `NA`}</Label>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Reason For Cancellation'}</Label>
                    <Label className="text-muted">{cancelledReason}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Updates</h6>
              <div className="border mt-2 mb-3 border"></div>

              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Membership Id'}</Label>
                    <Label className="text-muted">
                      {moment(`${details?.updatedAt}`).format(
                        'DD-MM-YYYY, h:mm A',
                      ) || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};

export default BookingDetails;
