import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Col, Container, Label } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';

import Table from './Components/Tables/Table';
import { uaeProjectsBaseURL } from '../../../Config';
import { BreadCrumb, Loader } from '../../../Common';
import { Constant } from '../../../Helpers/constant';
import { CLIENT_DOWNLOADS_LIST, CLIENT_DOWNLOADS_LIST_EMPTY } from '../../../../store/application/actionType';

const themeStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    padding: '8px 15px',
    ':hover': {
      cursor: 'pointer',
    },
    borderBottom: '1px solid #80808026',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: '14px',
  }),

  placeholder: styles => ({
    ...styles,
    color: 'black',
  }),

  control: (styles, state) => ({
    ...styles,
    borderRadius: '30px',
    cursor: 'pointer',
    fontSize: '16px',
    padding: '2px 2px 2px 40px',
  }),

  menu: styles => ({
    ...styles,
    padding: '5px 0',
    margin: '5px 0',
    borderRadius: '20px',
    width: '100%',
    overflow: 'hidden',
  }),

  dropdownIndicator: styles => ({ ...styles, color: 'black' }),
  indicatorSeparator: styles => ({ ...styles, borderBottom: '1px solid black', display: 'none' }),
};

const initialValues = {
  client: null,
};

const Downloads = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const initialValues = {
    client: {
      label: `${JSON.parse(localStorage?.getItem('data'))?.program?.name}`,
      value: `${JSON.parse(localStorage?.getItem('data'))?.program?.code}`,
    },
  };
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [state, setState] = useState(initialValues);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Downloads
  const { downloads, downloadsLoading } = useSelector((state: any) => ({
    downloads: state?.ClientDownloadsList?.data,
    downloadsLoading: state?.ClientDownloadsList?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (downloads === null) {
      if (JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB_LANDMARK`) {
        if (state?.client?.value) {
          dispatch({
            type: CLIENT_DOWNLOADS_LIST,
            payload: {
              urlParam: {
                pageIndex: currentPageIndex,
              },
              headers: {
                'Project-Code': state?.client?.value,
              },
            },
          });
        }
      } else {
        dispatch({
          type: CLIENT_DOWNLOADS_LIST,
          payload: {
            urlParam: {
              pageIndex: currentPageIndex,
            },
          },
        });
      }
    }
  }, [dispatch, state?.client?.value]);

  useEffect(() => {
    return () => {
      dispatch({
        type: CLIENT_DOWNLOADS_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    if (params?.actionType === `DOWNLOAD`) {
      // setCurrentSelectedBookingDetails(params?.downloadDetails);
      window.open(params?.downloadDetails?.file, `_blank`);
    }
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);
    if (JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB_LANDMARK`) {
      dispatch({
        type: CLIENT_DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          },
          headers: {
            'Project-Code': state?.client?.value,
          },
        },
      });
    } else {
      dispatch({
        type: CLIENT_DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          },
        },
      });
    }
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    if (JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB_LANDMARK`) {
      dispatch({
        type: CLIENT_DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
          headers: {
            'Project-Code': state?.client?.value,
          },
        },
      });
    } else {
      dispatch({
        type: CLIENT_DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (downloadsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [downloadsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `Downloads`) {
      // do nothing
    }
  };

  /* ---------------------------- GET PROJECTS LIST ---------------------------- */
  async function loadProjects(search) {
    let projectCodes = [];
    JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients.map(item => projectCodes.push(item));
    const response = await fetch(`${uaeProjectsBaseURL}/admin/v1/projects`, {
      method: `POST`,
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projectCodes: projectCodes }),
    });
    const usersResponse = await response.json();
    let array = JSON.parse(localStorage?.getItem('data'))?.program?.client?.clients;
    return {
      options: usersResponse?.results?.map((data, index) => {
        return {
          value: array[index],
          label: `${data?.name}`,
          completeData: data,
        };
      }),
    };
  }

  /* ---------------------------- ON CHANGE HANDLER ---------------------------- */
  const onSelectClient = selectedOption => {
    setState({ ...state, client: selectedOption });
    setCurrentPageIndex(0);

    if (JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB_LANDMARK`) {
      dispatch({
        type: CLIENT_DOWNLOADS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
          headers: {
            'Project-Code': selectedOption?.value ?? '',
          },
        },
      });
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          <BreadCrumb title="Dump Download" navigationby={breadcrumNavigationHandler} navigation={[t(`Dashboard`), t(`Downloads`)]} />

          {JSON.parse(localStorage.getItem(`data`))?.program?.code == `SAB_LANDMARK` && (
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="client" className="form-label">
                  {t(`Select Client`)}
                </Label>
                <div className="area_expandable">
                  <AsyncPaginate
                    value={state?.client}
                    loadOptions={loadProjects}
                    onChange={e => onSelectClient(e)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    placeholder={t(`Select Client`)}
                    styles={themeStyles}
                  />
                  <div className="select-icon-start">
                    <i className="ri-bank-line text-dark fs-20 align-middle me-1"></i>
                  </div>
                </div>
              </div>
            </Col>
          )}

          <Table
            data={downloads?.results}
            totalData={downloads?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            actionHandler={tableActionHandler}
            refreshHandler={refreshHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default Downloads;
