import { useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';

import UpdateMembership from './UpdateMembership';
import { Row, Col, Button, Modal, ModalBody, Card, CardBody } from 'reactstrap';

const Memberships = props => {
  const [membershipData, setMembershipData] = useState(null);
  const [modal, setModal] = useState(false);

  return (
    <Modal centered isOpen={true} scrollable={true} id="user-cards-list" size="lg">
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title">Memberships</h5>
        <Button
          type="button"
          onClick={() => props.onCloseHandler()}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>

      <ModalBody>
        <Row>
          {props?.data?.length > 0 ? (
            props?.data?.map((membership, index) => (
              <Col md={4} key={index}>
                <Card className="card-animate bg-light">
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div>
                        {/* <p className="fw-medium mb-0 mt-2">Membership</p> */}
                        <h5 className="mt-1 ff-secondary fw-semibold">
                          <span className="counter-value">Membership:</span>
                        </h5>
                        <h6 className="mt-1 ff-secondary fw-semibold text-muted">
                          <span className="counter-value">{`${membership?.membershipId}`}</span>
                        </h6>
                        <h6 className="mt-4 ff-secondary fw-semibold">
                          <span className="counter-value">ID-{`${membership?.benefitGroup?.name}`}</span>
                        </h6>
                        <p className="mb-0">
                          Uploaded At: <span className="text-muted">{`${moment(membership?.createdAt)?.format('DD-MM-YY')}`}</span>
                        </p>
                        <p className="mb-0">
                          Expiry: <span className="text-muted">{`${moment(membership?.expiryDate)?.format('DD-MM-YY')}`}</span>
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <span
                          href="apps-ecommerce-add-product"
                          id="TooltipTop"
                          className="btn btn-light"
                          onClick={() => {
                            setMembershipData(membership);
                            setModal(true);
                          }}
                        >
                          <i className="ri-pencil-fill align-bottom"></i>
                        </span>
                      </div>
                      <div></div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <h5 className="text-center">Membership is not available.</h5>
          )}
        </Row>
        <div className="modal-footer d-flex justify-content-center pt-3">
          <Button className="btn btn-md btn-danger" color="danger" onClick={() => props.onCloseHandler()}>
            Close
          </Button>
        </div>
      </ModalBody>
      {modal && <UpdateMembership data={membershipData} setModal={setModal} />}
    </Modal>
  );
};

export default Memberships;
