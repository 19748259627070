import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { customBaseURL, customHeaders, indianProjectsBaseURL, uaeProjectsBaseURL } from '../../../../Containers/Config/index.js';
import { GET_CLIENT_HISTORY_LIST, GET_CLIENT_HISTORY_SUCCESS, GET_CLIENT_HISTORY_ERROR } from './../../actionType.js';
import apiCall from '../../apiCall';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getClientHistoryList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${uaeProjectsBaseURL}/vendor/getBookings`,
      method: 'POST',
      headers: {
        Authorization: Constant?.CLIENT_TOKEN,

        ...customHeaders,
      },
      params: {
        ...payload?.urlParam,
        pageSize: Constant?.TABLE_PAGE_SIZE,
        // projectId: 'IaQlNnHgJr',
      },
      data: payload?.data,
    });

    if (response.status === 200) {
      yield put({
        type: GET_CLIENT_HISTORY_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: GET_CLIENT_HISTORY_ERROR,
        payload: response?.data?.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_CLIENT_HISTORY_ERROR, payload: error });
  }
}

export function* watchClientHistoryList() {
  yield takeEvery(GET_CLIENT_HISTORY_LIST, getClientHistoryList);
}

function* getClientHistorySaga() {
  yield all([fork(watchClientHistoryList)]);
}

export default getClientHistorySaga;
