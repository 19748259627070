const TatUnitsEnum = {
  YEAR: 1,
  MONTH: 2,
  DAY: 3,
  HOUR: 4,
  MINUTE: 5,
  SECOND: 6,
  CLEAR_DAY: 7,
};

class DateUtils {
  static addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  }

  static addMonthsToDate(date, months) {
    var result = new Date(date);
    result.setMonth(date.getMonth() + months);

    return result;
  }

  static addMinutesToDate(minutes) {
    var result = new Date();
    result.setMinutes(result.getMinutes() + minutes);
    return result;
  }

  static addHoursToDate(date, hours) {
    var result = new Date(date);
    result.setHours(date.getHours() + hours);
    return result;
  }
  static getMinDate = (minTat, tatUnit = TatUnitsEnum.CLEAR_DAY) => {
    let newDate = new Date();

    let minimumDate;
    switch (tatUnit) {
      case TatUnitsEnum.CLEAR_DAY:
        minimumDate = DateUtils.addDays(newDate, 1 + Number(minTat));
        break;

      case TatUnitsEnum.DAY:
        minimumDate = DateUtils.addDays(newDate, Number(minTat));
        break;

      case TatUnitsEnum.HOUR:
        minimumDate = DateUtils.addHoursToDate(newDate, minTat);
        break;

      case TatUnitsEnum.MONTH:
        minimumDate = DateUtils.addMonthsToDate(newDate, minTat);
        break;
    }

    return minimumDate;
  };

  static getMaxDate = (maxTat, tatUnit = TatUnitsEnum.CLEAR_DAY) => {
    let maximumDate;
    let newDate = new Date();
    switch (tatUnit) {
      case TatUnitsEnum.CLEAR_DAY:
        maximumDate = DateUtils.addDays(newDate, Number(maxTat) + 1);
        break;
      case TatUnitsEnum.DAY:
        maximumDate = DateUtils.addDays(newDate, Number(maxTat));
        break;

      case TatUnitsEnum.HOUR:
        maximumDate = DateUtils.addHoursToDate(newDate, maxTat);
        break;

      case TatUnitsEnum.MONTH:
        maximumDate = DateUtils.addMonthsToDate(newDate, maxTat);
        break;
    }

    return maximumDate;
  };

  static getDiffDateInDays = (date1 = new Date(), date2 = new Date()) => {
    let result = Math.abs(date2 - date1);
    result = Math.ceil(result / (1000 * 60 * 60 * 24));
    return result;
  };

  static getMinDateInDays = (minTat, tatUnit = TatUnitsEnum.CLEAR_DAY) => {
    const getMinDateResult = DateUtils.getMinDate(minTat, tatUnit);

    const date1 = new Date();
    const date2 = new Date(getMinDateResult);
    const diffDays = DateUtils.getDiffDateInDays(date1, date2);
    return diffDays;
  };

  static getMaxDateInDays = (minTat, tatUnit = TatUnitsEnum.CLEAR_DAY) => {
    const getMinDateResult = DateUtils.getMaxDate(minTat, tatUnit);

    const date1 = new Date();
    const date2 = new Date(getMinDateResult);
    const diffDays = DateUtils.getDiffDateInDays(date1, date2);
    return diffDays;
  };
}

export default DateUtils;
