import React, { useEffect, useState } from 'react';

import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../Common';
import moment from 'moment';
import { Constant } from '../../../../../Helpers/constant';
import ReactPaginate from 'react-paginate';
import { hasPermission } from '../../../../../Helpers/Helpers';
import { PermissionConstants } from '../../../../../Helpers/Constants/permissions';
import DataTable from 'react-data-table-component';

const CouponTable = props => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `CHANGE_COUPON_EXPIRY`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '80px',
          selector: row => row.serialNumber,
          wrap: true,
        },

        {
          name: 'Code',
          width: '200px',
          selector: row => row.code,
          wrap: true,
        },

        {
          name: 'Created On',
          width: '200px',
          selector: row => row.createdAt,
          wrap: true,
        },

        {
          name: 'Expire On',
          width: '200px',
          selector: row => row.expiryDate,
          wrap: true,
        },
        {
          name: 'Utilized',
          width: '120px',
          selector: row => row.utilized,
          wrap: true,
        },
        {
          name: 'Utilized On',
          width: '120px',
          selector: row => row.utilizedOn,
          wrap: true,
        },

        {
          name: 'Actions',
          width: '200px',
          cell: row => (
            <div className="d-flex justify-content-center flex-column">
              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.COUPON_ASSIGN) && !row?.data?.utilized && props?.selectedCoupon?.status !== 2 && (
                <span
                  className="btn btn-sm btn-primary mb-1"
                  onClick={() => {
                    actionBtnHandler(`CHANGE_COUPON_EXPIRY`, row?.data);
                  }}
                  style={{ width: '150px' }}
                >
                  Change Expiry
                </span>
              )}
            </div>
          ),
        },
      ];
      setColumns(fetchColumns);
      setData(
        props?.data?.map((data, index) => {
          let utilized = '';
          if (data?.utilized == false) {
            utilized = 'No';
          } else if (data?.utilized == true) {
            utilized = 'Yes';
          }
          return {
            serialNumber: `${props?.pageNumber * Constant?.TABLE_PAGE_SIZE + (index + 1)}.`,
            code: data?.code || `NA`,

            createdAt: data?.createdAt ? moment(`${data?.createdAt}`)?.format('DD-MM-YYYY, h:mm A') : `NA`,

            expiryDate:
              props?.selectedCoupon?.status === 2
                ? moment(`${props.selectedCoupon.expiryDate}`)?.format('DD-MM-YYYY, h:mm A')
                : moment(`${data?.expiryDate}`)?.format('DD-MM-YYYY, h:mm A')
                ? moment(`${data?.expiryDate}`)?.format('DD-MM-YYYY, h:mm A')
                : `NA`,
            utilized: utilized || `NA`,
            utilizedOn: data?.utilizedAt ? moment(`${data?.utilizedAt}`)?.format('DD-MM-YYYY, h:mm A') : `NA`,
            data,
          };
        }),
      );
    }
  }, [props?.data]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Coupons" />

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CouponTable;
