import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Row, Col, Card, CardBody, TabContent } from 'reactstrap';

import { Loader } from '../../../../../Common';
import BenefitGroups from './Components/Forms/BenefitGroups';
import BenefitGroupItems from './Components/Forms/BenefitGroupItems';
import Preview from './Components/Forms/Preview';
import Finish from './Components/Forms/Finish';
import Navigation from './Components/Navigation/Navigation';
import {
  CREATE_BENEFIT_GROUP,
  CREATE_BENEFIT_GROUP_EMPTY,
  CREATE_BENEFIT_GROUP_ITEM,
  CREATE_BENEFIT_GROUP_ITEM_EMPTY,
  GET_BENEFIT_GROUPS_LIST,
  GET_BENEFIT_GROUPS_LIST_EMPTY,
  GET_BENEFIT_GROUP_ITEMS,
  GET_BENEFIT_GROUP_ITEMS_EMPTY,
  GET_COUNTRY,
  UPDATE_BENEFIT_GROUP_EMPTY,
  UPDATE_BENEFIT_GROUP_ITEM,
  UPDATE_BENEFIT_GROUP_ITEM_EMPTY,
} from '../../../../../../store/application/actionType';
import { Constant } from '../../../../../Helpers/constant';
import DisplayPropertiesSettings from './Components/Modals/DisplayPropertiesSettings';
import CouponSettings from './Components/Modals/CouponSettings';
import BookingSettings from './Components/Modals/BookingSettings';

const NewBenefitGroupsManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [benefitGroupData, setBenefitGroupData] = useState(null);
  const [benefitGroupItemsData, setBenefitGroupItemsData] = useState(null);

  const [isEditBenefitGroupShow, setIsEditBenefitGroupShow] = useState(false);
  const [isBenefitGroupItemsTableShow, setIsBenefitGroupItemsTableShow] = useState(false);
  const [isCreateBenefitGroupShow, setIsCreateBenefitGroupShow] = useState(false);

  const [currentPageNumberSecondary, setCurrentPageNumberSecondary] = useState(0);
  const [currentSelectedBenefitGroup, setCurrentSelectedBenefitGroup] = useState(null);

  const [isShowDisplayPropertyModal, setIsShowDisplayPropertyModal] = useState(false);
  const [isShowCouponSettingsModal, setIsShowCouponSettingsModal] = useState(false);
  const [isShowBookingSettingsModal, setIsShowBookingSettingsModal] = useState(false);
  const [currentSelectedBenefitGroupItem, setCurrentSelectedBenefitGroupItem] = useState(null);
  const [currentSelectedBenefitGroupItemFor, setCurrentSelectedBenefitGroupItemFor] = useState('');

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Benefit Groups
  const { BenefitGroupsList, BenefitGroupsListLoading } = useSelector(state => ({
    BenefitGroupsList: state?.GetBenefitGroups?.data,
    BenefitGroupsListLoading: state?.GetBenefitGroups?.loading,
  }));

  // Get Benefit Group Items
  const { benefitGroupItemsList, benefitGroupItemsListLoading } = useSelector(state => ({
    benefitGroupItemsList: state?.GetBenefitGroupItemsList?.data,
    benefitGroupItemsListLoading: state?.GetBenefitGroupItemsList?.loading,
  }));

  // Create Benefit Group
  const { CreateBenefitGroup, CreateBenefitLoading } = useSelector(state => ({
    CreateBenefitGroup: state?.CreateBenefitGroup?.data,
    CreateBenefitLoading: state?.CreateBenefitGroup?.loading,
  }));

  // Create Benefit Group Items
  const { CreateBenefitGroupItemsSuccess, CreateBenefitGroupItemsFailure, CreateBenefitGroupItemsLoading } = useSelector(state => ({
    CreateBenefitGroupItemsSuccess: state?.CreateBenefitGroupItems?.success,
    CreateBenefitGroupItemsFailure: state?.CreateBenefitGroupItems?.error,
    CreateBenefitGroupItemsLoading: state?.CreateBenefitGroupItems?.loading,
  }));

  // Get Service
  const { servicesInBenefitItemLoading } = useSelector(state => ({
    servicesInBenefitItemLoading: state?.GetServicesInBenefitItem?.loading,
  }));

  // Update Benefit Group Item
  const { updateBenefitGroupItem, updateBenefitGroupItemLoading } = useSelector(state => ({
    updateBenefitGroupItem: state?.UpdateBenefitGroupItems?.data,
    updateBenefitGroupItemLoading: state?.UpdateBenefitGroupItems?.loading,
  }));

  // Get Quota
  const { inventoryQuota, inventoriesQuotaLoading } = useSelector(state => ({
    inventoryQuota: state?.GetInventoryQuota?.data,
    inventoriesQuotaLoading: state?.GetInventoryQuota?.loading,
  }));

  // Countries
  const { countriesData } = useSelector(state => ({
    countriesData: state?.Countries?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (benefitGroupItemsList !== null) {
      setIsCreateBenefitGroupShow(false);
      setIsEditBenefitGroupShow(false);
      setIsBenefitGroupItemsTableShow(true);
    }
  }, [benefitGroupItemsList]);

  useEffect(() => {
    if (updateBenefitGroupItem !== null) {
      dispatch({
        type: UPDATE_BENEFIT_GROUP_ITEM_EMPTY,
      });

      dispatch({
        type: GET_BENEFIT_GROUP_ITEMS,
        payload: {
          urlParam: {
            pageSize: Constant?.TABLE_PAGE_SIZE,
            pageIndex: currentPageNumberSecondary * Constant.TABLE_PAGE_SIZE,
            benefitGroupId: currentSelectedBenefitGroup?.objectId,
          },
        },
      });
    }
  }, [updateBenefitGroupItem]);

  useEffect(() => {
    if (BenefitGroupsList === null) {
      dispatch({
        type: GET_BENEFIT_GROUPS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
          },
        },
      });
    }
  }, [BenefitGroupsList]);

  useEffect(() => {
    if (CreateBenefitGroup !== null) {
      if (CreateBenefitGroup?.objectId) {
        setBenefitGroupData({
          ...benefitGroupData,
          id: CreateBenefitGroup?.objectId,
        });
        dispatch({
          type: CREATE_BENEFIT_GROUP_EMPTY,
        });
        toggleTab(activeTab + 1);
      } else {
        dispatch({
          type: CREATE_BENEFIT_GROUP_EMPTY,
        });
      }
    }
  }, [CreateBenefitGroup]);

  useEffect(() => {
    if (CreateBenefitGroupItemsSuccess) {
      dispatch({
        type: CREATE_BENEFIT_GROUP_ITEM_EMPTY,
      });
      toggleTab(activeTab + 1);
    }

    if (CreateBenefitGroupItemsFailure) {
      dispatch({
        type: CREATE_BENEFIT_GROUP_ITEM_EMPTY,
      });
    }
  }, [CreateBenefitGroupItemsSuccess, CreateBenefitGroupItemsFailure]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_BENEFIT_GROUPS_LIST_EMPTY,
      });

      dispatch({
        type: CREATE_BENEFIT_GROUP_EMPTY,
      });

      dispatch({
        type: GET_BENEFIT_GROUP_ITEMS_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (countriesData === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
  }, [countriesData]);

  /* ---------------------------- BENEFIT GROUP SUBMIT HANDLER ---------------------------- */
  const benefitGroupSubmitHandler = data => {
    setBenefitGroupData(data);

    const payload = {
      ...data,
      benefitWorthCurrency: data?.benefitWorthCurrency?.value,
      packReleaseType: data?.packReleaseType?.value,
      groupCategory: data?.groupCategory?.value,
      membership: {
        ...data?.membership,
        expiry: data?.membership?.expiry?.value,
      },
      validityTat: {
        unit: data?.validityTat?.unit ? data?.validityTat?.unit?.value : '',
        value: data?.validityTat?.value ? data?.validityTat?.value?.value : '',
      },
    };

    dispatch({
      type: CREATE_BENEFIT_GROUP,
      payload: {
        data: payload,
      },
    });
  };

  /* ---------------------------- BENEFIT GROUP ITEMS SAVE HANDLER ---------------------------- */
  const benefitGroupItemsSaveHandler = data => {
    setBenefitGroupItemsData(data);
    toggleTab(activeTab + 1);
  };

  /* ---------------------------- BENEFIT GROUP ITEMS SUBMIT HANDLER ---------------------------- */
  const benefitGroupItemsSubmitHandler = data => {
    const payload = data?.benefitGroupItems?.map(benefitGroupItem => {
      let tempOrCondition = {
        enabled: false,
      };

      if (benefitGroupItem?.benefitGroupItem?.completeData?.orCondition) {
        tempOrCondition = {
          enabled: true,
          quotaId: benefitGroupItem?.benefitGroupItem?.completeData?.orCondition,
        };
      }

      return {
        ...benefitGroupItem?.benefitGroupItem?.completeData,
        airports: benefitGroupItem?.benefitGroupItem?.completeData?.airports?.map(airport => airport?.value),
        benefitGroup: {
          id: benefitGroupData?.id,
          name: benefitGroupData?.name,
        },
        orCondition: tempOrCondition,
        projectLevelQuota:
          benefitGroupItem?.benefitGroupItem?.completeData?.projectLevelQuota?.quotaId !== ''
            ? benefitGroupItem?.benefitGroupItem?.completeData?.projectLevelQuota
            : null,
        dynamicCode: benefitGroupItem?.dynamicCode,
      };
    });

    dispatch({
      type: CREATE_BENEFIT_GROUP_ITEM,
      payload: {
        data: {
          requests: [...payload],
        },
      },
    });
  };

  /* ---------------------------- MOVE TO BENEFIT GROUPS LIST HANDLER ---------------------------- */
  const benefitGroupBtnHandler = () => {
    dispatch({
      type: GET_BENEFIT_GROUPS_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
        },
      },
    });

    setactiveTab(1);
  };

  /* ---------------------------- UPDATE BENEFIT HANDLER ---------------------------- */
  const updateBenefitHandler = data => {
    dispatch({
      type: UPDATE_BENEFIT_GROUP_ITEM,
      payload: {
        data: {
          ...data?.data,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      CreateBenefitLoading ||
      CreateBenefitGroupItemsLoading ||
      BenefitGroupsListLoading ||
      servicesInBenefitItemLoading ||
      benefitGroupItemsListLoading ||
      updateBenefitGroupItemLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    CreateBenefitLoading,
    CreateBenefitGroupItemsLoading,
    BenefitGroupsListLoading,
    servicesInBenefitItemLoading,
    benefitGroupItemsListLoading,
    updateBenefitGroupItemLoading,
  ]);

  return (
    <>
      {/*  ---------------------------- LOADING ----------------------------  */}
      {loading && <Loader />}

      {/*  ---------------------------- MODAL'S ----------------------------  */}
      {isShowDisplayPropertyModal && (
        <DisplayPropertiesSettings
          data={currentSelectedBenefitGroupItem}
          onUpdateAction={updateBenefitHandler}
          onCloseHandler={() => setIsShowDisplayPropertyModal(false)}
        />
      )}

      {isShowCouponSettingsModal && (
        <CouponSettings
          data={currentSelectedBenefitGroupItem}
          onUpdateAction={updateBenefitHandler}
          onCloseHandler={() => setIsShowCouponSettingsModal(false)}
        />
      )}

      {isShowBookingSettingsModal && (
        <BookingSettings
          data={currentSelectedBenefitGroupItem}
          onUpdateAction={updateBenefitHandler}
          onCloseHandler={() => setIsShowBookingSettingsModal(false)}
        />
      )}

      {/*  ---------------------------- SCREEN'S ----------------------------  */}
      <>
        <Row>
          <Col xl="12">
            <Card>
              <CardBody className="checkout-tab">
                <Form action="#">
                  <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                    <Navigation activeTab={activeTab} toggleTab={toggleTab} />
                  </div>
                  <TabContent activeTab={activeTab}>
                    <BenefitGroups toggleTab={toggleTab} activeTab={activeTab} submitHandler={benefitGroupSubmitHandler} />
                    <BenefitGroupItems
                      toggleTab={toggleTab}
                      activeTab={activeTab}
                      togglemodal={togglemodal}
                      toggledeletemodal={toggledeletemodal}
                      onPressNext={benefitGroupItemsSaveHandler}
                    />
                    <Preview
                      toggleTab={toggleTab}
                      activeTab={activeTab}
                      data={{
                        benefitGroup: benefitGroupData,
                        benefitGroupItems: benefitGroupItemsData,
                      }}
                      onSubmit={benefitGroupItemsSubmitHandler}
                    />
                    <Finish toggleTab={toggleTab} activeTab={activeTab} onClickViewBenefitGrpBtn={benefitGroupBtnHandler} />
                  </TabContent>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    </>
  );
};

export default NewBenefitGroupsManagement;
