import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../../Common';
import { Container } from 'reactstrap';
import {
  GET_PROJECTS_LIST,
  CREATE_PROJECT_EMPTY,
  CREATE_CLIENTS_EMPTY,
  GET_CLIENTS,
  UPDATE_PROJECT_EMPTY,
} from '../../../../../store/application/actionType';
import CreateClientModal from './Components/Modals/CreateClient';
import ProgramsList from './Components/Tables/ProjectsList';
import ProgramConfiguration from './Components/Forms/ProjectConfiguration';

const Program = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);

  const [isProgramsTableShow, setIsProgramsTableShow] = useState(true);
  const [isCreateProgramsShow, setIsCreateProgramsShow] = useState(false);

  const [isCreateClient, setIsCreateClient] = useState(false);
  const [currentlySelectedProgram, setCurrentlySelectedProgram] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Projects List
  const { projectsList, projectsListLoading } = useSelector(state => ({
    projectsList: state?.ProjectsList?.data,
    projectsListLoading: state?.ProjectsList?.loading,
  }));

  // Create Project
  const { createProject, createProjectLoading } = useSelector(state => ({
    createProject: state?.CreateProject?.data,
    createProjectLoading: state?.CreateProject?.loading,
  }));

  // Countries List
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Create Client
  const { clientsRes, createClientsLoading } = useSelector(state => ({
    clientsRes: state?.CreateClient?.data,
    createClientsLoading: state?.CreateClient?.loading,
  }));

  // Clients List
  const { clients, clientsLoading } = useSelector(state => ({
    clients: state?.Clients?.data,
    clientsLoading: state?.Clients?.loading,
  }));

  // Update Program
  const { updateProgram, updateProgramLoading } = useSelector(state => ({
    updateProgram: state?.UpdateProject?.data,
    updateProgramLoading: state?.UpdateProject?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PROJECTS_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          pageSize: 100,
        },
      },
    });

    if (clients !== null) {
      dispatch({
        type: GET_CLIENTS,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    }

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (createProject !== null) {
      dispatch({
        type: CREATE_PROJECT_EMPTY,
      });

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
      setIsProgramsTableShow(true);
      setIsCreateProgramsShow(false);
      setCurrentlySelectedProgram(null);
    }
  }, [createProject]);

  useEffect(() => {
    if (updateProgram !== null) {
      dispatch({
        type: UPDATE_PROJECT_EMPTY,
      });

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
      setIsProgramsTableShow(true);
      setIsCreateProgramsShow(false);
      setCurrentlySelectedProgram(null);
    }
  }, [updateProgram]);

  useEffect(() => {
    if (clientsRes !== null) {
      dispatch({
        type: CREATE_CLIENTS_EMPTY,
      });

      dispatch({
        type: GET_CLIENTS,
      });
    }
  }, [clientsRes]);

  /* ---------------------------- BUTTON HANDLER ---------------------------- */
  const createProgramBtnHandler = () => {
    setCurrentlySelectedProgram(null);
    setIsProgramsTableShow(false);
    setIsCreateProgramsShow(true);
  };

  const selectedProgramHandler = program => {
    setCurrentlySelectedProgram(program);
    setIsProgramsTableShow(false);
    setIsCreateProgramsShow(true);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (projectsListLoading || countriesLoading || clientsLoading || createProjectLoading || createClientsLoading || updateProgramLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [projectsListLoading, countriesLoading, clientsLoading, createProjectLoading, createClientsLoading, updateProgramLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Program Management`) {
      setIsProgramsTableShow(true);
      setIsCreateProgramsShow(false);
      setCurrentlySelectedProgram(null);

      dispatch({
        type: GET_PROJECTS_LIST,
        payload: {
          urlParam: {
            pageIndex: 0,
            pageSize: 100,
          },
        },
      });
    } else if (title === `Information`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {isCreateClient && <CreateClientModal countries={countries} onCloseHandler={() => setIsCreateClient(false)} />}

          {isProgramsTableShow && (
            <>
              <BreadCrumb title="Program" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Program Management`]} />
              <ProgramsList selecteProgram={selectedProgramHandler} programs={projectsList?.results} createNew={createProgramBtnHandler} />
            </>
          )}

          {isCreateProgramsShow && (
            <>
              <BreadCrumb
                title="Program"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Program Management`, `${currentlySelectedProgram ? 'Update' : 'Create'}`]}
              />
              <ProgramConfiguration data={currentlySelectedProgram} createClientAction={() => setIsCreateClient(true)} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default Program;
