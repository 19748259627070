import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, ModalBody, ModalHeader, Col, Label, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { CREATE_BINS } from '../../../../../../../store/application/actionType';
import { Constant } from '../../../../../../Helpers/constant';
import { baseUrl, customHeaders } from '../../../../../../Config';

const CreateBinModal = ({ data, onCloseHandler }) => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialObj = {
    number: '',
    name: '',
    description: '',
    gateways: [],
    benefitGroupId: null,
  };
  const [state, setState] = useState(initialObj);

  const [modal_varying1, setmodal_varying1] = useState(true);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'name':
        updatedState = {
          ...state,
          name: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'number':
        updatedState = {
          ...state,
          number: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      case 'description':
        updatedState = {
          ...state,
          description: value,
        };
        setState(updatedState);
        validationCheck(updatedState);
        break;

      default:
      // do nothing
    }
  };

  const inputSelectHandler = (label, data) => {
    let updatedState = null;

    if (label === `gateways`) {
      updatedState = {
        ...state,
        gateways: data,
      };
      setState(updatedState);
      validationCheck(updatedState);
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.name !== `` && data?.number !== `` && data?.description !== ``) {
      setIsSubmitBtnDisabled(false);
    } else {
      setIsSubmitBtnDisabled(true);
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const params = {
      projectId: data?.selectedProject?.id,
    };

    const payload = {
      ...state,
      gateways: state?.gateways?.map(gateway => gateway?.code),
      projectCode: data?.selectedProject?.code,
      benefitGroupId: state?.benefitGroupId === null ? null : state?.benefitGroupId?.value,
    };

    dispatch({
      type: CREATE_BINS,
      payload: {
        data: payload,
      },
    });

    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const tog_varying1 = () => {
    onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  /* ---------------------------- TYPED USER HANDLER ---------------------------- */
  async function getBenefitGroups(search, loadedOptions) {
    const response = await fetch(`${baseUrl}/admin/benefitGroups?name=${search}`, {
      method: `GET`,
      headers: new Headers({
        Authorization: Constant?.CLIENT_TOKEN,
        ...customHeaders,
      }),
    });
    const responseData = await response.json();

    return {
      options: responseData?.results?.map((data, index) => {
        return {
          value: data.objectId,
          label: `${data?.name}`,
          completeData: data,
        };
      }),
      hasMore: false,
    };
  }

  /* ---------------------------- ON SELECT USER ---------------------------- */
  const onSelectBenefitGroup = event => {
    let updatedState = null;

    updatedState = {
      ...state,
      benefitGroupId: event,
    };
    setState(updatedState);
    validationCheck(updatedState);
  };

  return (
    <Modal
      isOpen={modal_varying1}
      toggle={() => {
        tog_varying1();
      }}
      id="exampleModal"
      //   scrollable={true}
      //   centered
    >
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Create Bin
        </h5>
        <Button type="button" onClick={() => tog_varying1()} id="addBoardBtn-close" className="btn-close btn-light" aria-label="Close"></Button>
      </div>
      <ModalBody>
        {/* <button onClick={() => console.log(data, state)}>Get state</button> */}
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Name <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="name" name="name" value={state?.name} className="form-control" onChange={inputChangeHandler} />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Number <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="number" name="number" value={state?.number} className="form-control" onChange={inputChangeHandler} />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Description <span className="lbl_mandatory">*</span>
                  </Label>
                  <Input id="description" name="description" value={state?.description} className="form-control" onChange={inputChangeHandler} />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Benefit Group
                  </Label>
                  <AsyncPaginate
                    debounceTimeout={500}
                    value={state?.userId}
                    loadOptions={getBenefitGroups}
                    onChange={e => onSelectBenefitGroup(e)}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    noOptionsMessage={() => 'No results found'}
                    cacheUniqs={[['code']]}
                    placeholder=""
                    isDisabled={state?.benefitGroupItemType === ``}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="name" className="form-label">
                    Payment Gateway
                  </Label>
                  <Select
                    id="gateways"
                    name="gateways"
                    isMulti={true}
                    value={state?.gateways}
                    options={[...data.selectedPaymentGateways]}
                    onChange={data => inputSelectHandler(`gateways`, data)}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={submitHandler} disabled={isSubmitBtnDisabled}>
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default CreateBinModal;
