import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';

import { Card, CardBody, Col, Form, Label, Row, Input, CardFooter, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import { PreviewCardHeader, Error } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';

import ReactTooltip from 'react-tooltip';
import { isANumber, isValidAlphaNumberic, isValidNumber } from '../../../../../../Helpers/Helpers';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const passTypes = [
  { label: 'Internal Testing', value: 'Internal Testing' },
  { label: 'Client Testing', value: 'Client Testing' },
  { label: 'Production', value: 'Production' },
];

const initialState = {
  clientId: Constant?.CURRENT_SELECTED_CLIENT,
  projectId: Constant?.CURRENT_SELECTED_PROGRAM,
  benefitGroupId: '',
  membershipData: {
    prefix: '',
    length: null,
    count: '',
    applicableDate: '',
    expiryDate: '',
  },
  type: '',
  perPackCost: '',
};

const BenefitPackForm = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [benefitGroups, setBenefitGroups] = useState([]);
  const [isBenefitGroupManual, setIsBenefitGroupManual] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  useEffect(() => {
    if (props?.data?.benefitGroups?.results.length > 0) {
      setBenefitGroups(
        props?.data?.benefitGroups?.results?.map(benefitGroup => {
          return {
            value: `${benefitGroup?.objectId}`,
            label: `${benefitGroup?.name}`,
            data: benefitGroup,
          };
        }),
      );
    }
  }, []);

  /* ---------------------------- UPDATE BENEFIT PACK ---------------------------- */
  useEffect(() => {
    if (props?.currentSelectedDetails) {
      const benefitGroup = benefitGroups.filter(data => props?.currentSelectedDetails?.benefitGroup?.id === data?.value);
      setState({
        clientId: props?.currentSelectedDetails?.client?.id,
        projectId: props?.currentSelectedDetails?.project?.id,
        benefitGroupId: benefitGroup[0],
        membershipData: {
          ...state?.membershipData,
          prefix: benefitGroup[0]?.data?.membership?.prefix,
          length: benefitGroup[0]?.data?.membership?.length,
          count: props?.currentSelectedDetails?.count,
          applicableDate: props?.currentSelectedDetails?.applicableDate,
          expiryDate: props?.currentSelectedDetails?.expiryDate,
        },
        type: {
          value: props?.currentSelectedDetails?.type,
          label: props?.currentSelectedDetails?.type,
        },
        perPackCost: props?.currentSelectedDetails?.perPackCost,
      });
    }
  }, [benefitGroups]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    let cost = ``;

    switch (name) {
      case 'prefix':
        updatedState = {
          ...state,
          membershipData: {
            ...state.membershipData,
            prefix: value,
          },
        };
        break;

      case 'length':
        updatedState = {
          ...state,
          membershipData: {
            ...state.membershipData,
            length: parseInt(value),
          },
        };
        break;

      case 'count':
        updatedState = {
          ...state,
          membershipData: {
            ...state.membershipData,
            count: isValidNumber(value) ? (value > 20000 ? state?.membershipData?.count : value) : state?.membershipData?.count,
          },
        };
        break;

      case 'benefit-group':
        updatedState = {
          ...state,
          benefitGroupId: value,
        };
        break;

      case 'perPackCost':
        if (value?.length === 0) {
          cost = ``;
        } else {
          cost = isANumber(value) ? value : state?.perPackCost;
        }
        updatedState = {
          ...state,
          perPackCost: cost,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...state };

    if (label === `benefitGroup`) {
      if (data?.data?.packReleaseType !== `Manual`) {
        setIsBenefitGroupManual(true);
      } else {
        setIsBenefitGroupManual(false);
      }
      updatedState = {
        ...state,
        benefitGroupId: data,
        membershipData: {
          ...state?.membershipData,
          prefix: data?.data?.membership?.prefix,
          length: data?.data?.membership?.length,
        },
      };
    } else if (label === 'type') {
      updatedState = {
        ...state,
        type: data,
      };
    }
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- DATE PICKR HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...state };

    switch (flag) {
      case 'applicableDate':
        updatedState = {
          ...state,
          membershipData: {
            ...state.membershipData,
            applicableDate: new Date(date).toISOString(),
          },
        };
        break;

      case 'expiryDate':
        updatedState = {
          ...state,
          membershipData: {
            ...state.membershipData,
            expiryDate: new Date(date).toISOString(),
          },
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (
      data.benefitGroupId !== `` &&
      data?.membershipData?.applicableDate !== `` &&
      data?.membershipData?.expiryDate !== `` &&
      data?.membershipData?.count !== `` &&
      data?.membershipData?.length !== `` &&
      data?.membershipData?.prefix !== `` &&
      data?.membershipData?.prefix?.length >= 2 &&
      data?.membershipData?.prefix?.length <= 10 &&
      data?.membershipData?.length >= 6 &&
      data?.membershipData?.length <= 20 &&
      data?.membershipData?.count <= 20000 &&
      new Date(data?.membershipData?.applicableDate).getTime() < new Date(data?.membershipData?.expiryDate).getTime() &&
      data?.type !== `` &&
      data?.perPackCost !== `` &&
      moment() <= moment(data?.membershipData?.applicableDate) &&
      moment() <= moment(data?.membershipData?.expiryDate)
    ) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
        benefitGroupId: state?.benefitGroupId?.value,
        clientId: state?.clientId?.id,
        projectId: state?.projectId?.id,
        membershipData: {
          ...state.membershipData,
          count: parseInt(state.membershipData.count),
        },
        perPackCost: parseFloat(state?.perPackCost),
        type: state?.type?.value ?? '',
      };
      props.onSubmit('CREATE', {
        ...payload,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title={`Benefit Pack ${props?.currentSelectedDetails ? 'Updation' : 'Creation'}`} />
            {/* <button onClick={() => console.log(state)}>get state</button> */}
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="benefitGroup" className="form-label">
                          Benefit Group
                          <span className="lbl_mandatory"> *</span>
                        </Label>

                        <Select
                          id="benefitGroup"
                          name="benefitGroup"
                          value={state?.benefitGroupId}
                          onChange={data => inputSelectHandler(`benefitGroup`, data)}
                          options={benefitGroups}
                          isDisabled={props?.currentSelectedDetails}
                        />
                        {isValidationShow && state?.benefitGroupId === `` && <Error text="Please select benefit group" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Prefix <span className="lbl_mandatory">* </span>
                          <Link to="#" className="fw-medium" id="prefix_tooltip">
                            <i className="ri-information-line"></i>
                          </Link>
                          <UncontrolledTooltip placement="top" target="prefix_tooltip">
                            Initial identifier for a membership id
                          </UncontrolledTooltip>
                        </Label>

                        <Input
                          id="prefix"
                          name="prefix"
                          type="text"
                          placeholder="Prefix"
                          className="form-control"
                          // onChange={inputChangeHandler}
                          value={state?.membershipData?.prefix}
                          minLength={3}
                          maxLength={10}
                          disabled={true || isBenefitGroupManual || props?.currentSelectedDetails}
                        />
                        {isValidationShow && state?.membershipData?.prefix === `` && <Error text="Prefix can't be empty" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Length <span className="lbl_mandatory">* </span>
                          <Link to="#" className="fw-medium" id="length_tooltip">
                            <i className="ri-information-line"></i>
                          </Link>
                          <UncontrolledTooltip placement="top" target="length_tooltip">
                            The length excludes prefix character
                          </UncontrolledTooltip>
                        </Label>
                        <Input
                          id="length"
                          name="length"
                          type="number"
                          onWheel={e => e.target.blur()}
                          placeholder="Length"
                          className="form-control"
                          // onChange={inputChangeHandler}
                          value={state?.membershipData?.length}
                          min={6}
                          max={20}
                          disabled={true || isBenefitGroupManual || props?.currentSelectedDetails}
                        />
                        {isValidationShow && state?.membershipData?.length === null && <Error text="Length can't be empty" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          No. of Memberships
                          <span className="lbl_mandatory"> *</span>
                          <Link to="#" className="fw-medium" id="count">
                            <i className="ri-information-line"></i>
                          </Link>
                          <UncontrolledTooltip placement="top" target="count">
                            In total 20000 memberships can be created
                          </UncontrolledTooltip>
                        </Label>

                        <Input
                          id="count1"
                          name="count"
                          type="text"
                          placeholder="Count"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.membershipData?.count}
                          max={20000}
                          disabled={isBenefitGroupManual || props?.currentSelectedDetails}
                        />
                        {isValidationShow && state?.membershipData?.count === `` && <Error text="Please enter membership count" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="applicable-date" className="form-label">
                          Applicable Date
                          <span className="lbl_mandatory"> *</span>
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className={`form-control ${isBenefitGroupManual ? `overlay-disable` : `bg-white `}`}
                            disabled={isBenefitGroupManual}
                            name="applicable-date"
                            id="applicable-date"
                            value={state?.membershipData?.applicableDate}
                            onChange={date => datePickHandler(`applicableDate`, date)}
                            // disabled={isBenefitGroupManual}
                            options={{
                              // minDate: 'today',
                              defaultMinute: '00',
                              defaultHour: '00',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                              // minTime:
                              //   state?.membershipData?.applicableDate.split('T')[0] === new Date().toISOString()?.split('T')[0]
                              //     ? `${new Date().getHours()}:${new Date().getMinutes()}`
                              //     : '',
                            }}
                          />
                          {isValidationShow && state?.membershipData?.applicableDate === `` && <Error text="Please select applicable date" />}
                          {isValidationShow &&
                            state?.membershipData?.applicableDate !== `` &&
                            (moment() > moment(state?.membershipData?.applicableDate) ||
                              new Date(state?.membershipData?.applicableDate).getTime() > new Date(state?.membershipData?.expiryDate).getTime()) && (
                              <Error text="Applicable date is invalid" />
                            )}
                        </div>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="expiry-date" className="form-label">
                          Expiry Date <span className="lbl_mandatory"> *</span>
                          <span className="icon_calendar">
                            <i className="las la-calendar"></i>
                          </span>
                        </Label>
                        <div className="area_expandable">
                          <Flatpickr
                            className={`form-control ${isBenefitGroupManual ? `overlay-disable` : `bg-white `}`}
                            disabled={isBenefitGroupManual}
                            name="expiry-date"
                            id="expiry-date"
                            value={state?.membershipData?.expiryDate}
                            onChange={date => datePickHandler(`expiryDate`, date)}
                            options={{
                              // minDate: 'today',
                              defaultMinute: '59',
                              defaultHour: '23',
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }}
                          />
                          {isValidationShow && state?.membershipData?.expiryDate === `` && <Error text="Please select expiry date" />}
                          {isValidationShow && state?.membershipData?.expiryDate !== `` && moment() > moment(state?.membershipData?.expiryDate) && (
                            <Error text="Expiry date is invalid" />
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="type" className="form-label">
                          Pass Type
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Select
                          id="type"
                          name="type"
                          options={passTypes ?? []}
                          value={state?.type}
                          onChange={data => inputSelectHandler(`type`, data)}
                        />
                        {isValidationShow && state?.type === '' && <Error text="Please select pass type" />}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Cost Per Membership
                          <span className="lbl_mandatory"> *</span>
                        </Label>
                        <Input
                          id="perPackCost"
                          name="perPackCost"
                          type="number"
                          onWheel={e => e.target.blur()}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.perPackCost}
                        />
                        {isValidationShow && state?.perPackCost === `` && <Error text="Kindly enter cost per membership" />}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>

            <CardFooter>
              <Row>
                <Col md={12}>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary mx-3 overlay-disable"
                      onClick={submitHandler}
                      // disabled={isSubmitBtnDisabled}
                    >
                      {props?.currentSelectedDetails ? 'Update' : 'Create'}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>

            <div
              style={{
                width: '100px',
                position: 'relative',
                zIndex: '999999999999',
              }}
            >
              <ReactTooltip />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BenefitPackForm;
