import React, { useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';

const inputTypeOptions = [
  { value: 1, label: 'Text', type: 2 },
  { value: 2, label: 'Select', type: 2 },
  { value: 3, label: 'Date', type: 3 },
  { value: 4, label: 'Time', type: 2 },
  { value: 5, label: 'TextArea', type: 2 },
];

const initialState = {
  inputType: null,
  type: 0,
  headerName: '',
  placeholder: '',
  isRequired: false,
  validationRegex: '',
  isValidationRegexRequired: false,
  validationRegexText: '',
  validations: {
    min: 0,
    max: 0,
    isMinMaxRequired: false,
  },
  options: [],
  value: '',
};

const DynamicForm = props => {
  const [modal_varying1, setmodal_varying1] = useState(true);
  const [state, setState] = useState(initialState);
  const [optionValue, setOptionValue] = useState('');
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (props?.selectedDynamicField) {
      setState(props?.selectedDynamicField);
    }
  }, [props?.selectedDynamicField]);

  const tog_varying1 = () => {
    props.onCloseHandler(false);
    setmodal_varying1(!modal_varying1);
  };

  const handleInputChange = e => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === 'checkbox' ? checked : value;

    // Convert min and max to numbers
    if (name === 'min' || name === 'max') {
      updatedValue = Number(updatedValue);
    }

    let updateState = null;
    if (name in state.validations) {
      updateState = {
        ...state,
        validations: {
          ...state.validations,
          [name]: updatedValue,
        },
      };
    } else {
      updateState = {
        ...state,
        [name]: updatedValue,
      };
    }
    setState(updateState);
    if (isValidationShow) {
      validateErrors(updateState);
    }
  };

  const handleSelectChange = selectedOption => {
    const updateState = {
      ...state,
      inputType: selectedOption?.value,
      type: selectedOption?.type,
    };
    console.log('updateState', updateState);
    setState(updateState);
    if (isValidationShow) {
      validateErrors(updateState);
    }
  };

  const handleAddOption = () => {
    if (optionValue) {
      const updateState = {
        ...state,
        options: [...state.options, optionValue],
      };
      setState(updateState);
      setOptionValue('');
      if (isValidationShow) {
        validateErrors(updateState);
      }
    }
  };

  const handleRemoveOption = index => {
    const updateState = {
      ...state,
      options: state.options.filter((option, idx) => idx !== index),
    };
    setState(updateState);
    if (isValidationShow) {
      validateErrors(updateState);
    }
  };

  const validateErrors = (data = state) => {
    const errors = {};
    if (!data.inputType) {
      errors.inputType = 'Input Type is required';
    }
    if (!data.headerName.trim()) {
      errors.headerName = 'Header Name is required';
    }
    if (!data.placeholder.trim()) {
      errors.placeholder = 'Placeholder is required';
    }
    if (data.inputType === 2 && data.options.length === 0) {
      errors.options = 'Options are required for select input type';
    }
    if (data.isValidationRegexRequired) {
      if (!data.validationRegex.trim()) {
        errors.validationRegex = 'Validation Regex is required';
      }
      if (!data.validationRegexText.trim()) {
        errors.validationRegexText = 'Validation Regex Text is required';
      }
    }
    if (data.validations.isMinMaxRequired) {
      if (data.validations.min === 0) {
        errors.min = 'Min Validation is required';
      }
      if (data.validations.max === 0) {
        errors.max = 'Max Validation is required';
      }
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  console.log('state', state);

  const handleSubmit = () => {
    setIsValidationShow(true);
    if (validateErrors(state)) {
      props.saveDynamicField(props?.selectedDynamicField ? 'UPDATE' : 'CREATE', props?.selectedDynamicField?.index, state);
      tog_varying1();
    }
  };

  return (
    <Modal isOpen={modal_varying1} id="exampleModal" size="md">
      <div className="modal-header p-3 bg-soft-secondary">
        <h5 className="modal-title" id="createboardModalLabel">
          Dynamic Form
        </h5>
        <Button type="button" onClick={tog_varying1} id="addBoardBtn-close" className="btn-close btn-light"></Button>
      </div>
      <ModalBody>
        <Form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="inputType" className="form-label">
                  Input Type
                </Label>
                <Select
                  options={inputTypeOptions}
                  onChange={handleSelectChange}
                  value={inputTypeOptions.find(option => option.value === state.inputType)}
                  placeholder="Select Input Type"
                />
                {validationErrors.inputType && <span className="text-danger">{validationErrors.inputType}</span>}
              </div>
            </Col>
            {state.inputType === 2 && (
              <>
                <Col md={12}>
                  <Row>
                    <Col md={10}>
                      <div className="mb-3">
                        <Input
                          type="text"
                          name="optionValue"
                          placeholder="Option Value"
                          value={optionValue}
                          onChange={e => setOptionValue(e.target.value)}
                          className="me-2"
                        />
                        {validationErrors.options && <span className="text-danger">{validationErrors.options}</span>}
                      </div>
                    </Col>
                    <Col md={2}>
                      <Button color="success" onClick={handleAddOption}>
                        <FaPlus />
                      </Button>
                    </Col>
                  </Row>
                </Col>
                {state.options.map((option, index) => (
                  <Col md={12} key={index} className="mb-3">
                    <Row>
                      <Col md={10}>
                        <Input type="text" value={option} className="me-2" disabled />
                      </Col>
                      <Col md={2}>
                        <Button color="danger" onClick={() => handleRemoveOption(index)}>
                          <FaMinus />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                ))}
              </>
            )}
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="headerName" className="form-label">
                  Header Name
                </Label>
                <Input type="text" name="headerName" value={state.headerName} onChange={handleInputChange} />
                {validationErrors.headerName && <span className="text-danger">{validationErrors.headerName}</span>}
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="placeholder" className="form-label">
                  Placeholder
                </Label>
                <Input type="text" name="placeholder" value={state.placeholder} onChange={handleInputChange} />
                {validationErrors.placeholder && <span className="text-danger">{validationErrors.placeholder}</span>}
              </div>
            </Col>
            <Col md={12}>
              <div className="form-switch d-flex align-items-center mb-3">
                <Input
                  className="form-check-input code-switcher form-switch-md cursor-pointer"
                  type="checkbox"
                  name="isRequired"
                  checked={state.isRequired}
                  onChange={handleInputChange}
                />
                <span>&nbsp; Required</span>
              </div>
            </Col>
            <Col md={12}>
              <div className="form-switch d-flex align-items-center mb-3">
                <Input
                  className="form-check-input code-switcher form-switch-md cursor-pointer"
                  type="checkbox"
                  name="isValidationRegexRequired"
                  checked={state.isValidationRegexRequired}
                  onChange={handleInputChange}
                />
                <span>&nbsp; Validation Regex Required</span>
              </div>
            </Col>
            {state.isValidationRegexRequired && (
              <>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="validationRegex" className="form-label">
                      Validation Regex
                    </Label>
                    <Input type="text" name="validationRegex" value={state.validationRegex} onChange={handleInputChange} />
                    {validationErrors.validationRegex && <span className="text-danger">{validationErrors.validationRegex}</span>}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="validationRegexText" className="form-label">
                      Validation Regex Text
                    </Label>
                    <Input type="text" name="validationRegexText" value={state.validationRegexText} onChange={handleInputChange} />
                    {validationErrors.validationRegexText && <span className="text-danger">{validationErrors.validationRegexText}</span>}
                  </div>
                </Col>
              </>
            )}
            {state.inputType === 3 && (
              <Col md={12}>
                <div className="form-switch d-flex align-items-center mb-3">
                  <Input
                    className="form-check-input code-switcher form-switch-md cursor-pointer"
                    type="checkbox"
                    name="isMinMaxRequired"
                    checked={state.validations.isMinMaxRequired}
                    onChange={handleInputChange}
                  />
                  <span>&nbsp; Min Max Required</span>
                </div>
              </Col>
            )}
            {state.validations.isMinMaxRequired && (
              <>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="min" className="form-label">
                      Min Validation
                    </Label>
                    <Input type="number" name="min" value={state.validations.min} onChange={handleInputChange} />
                    {validationErrors.min && <span className="text-danger">{validationErrors.min}</span>}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Label htmlFor="max" className="form-label">
                      Max Validation
                    </Label>
                    <Input type="number" name="max" value={state.validations.max} onChange={handleInputChange} />
                    {validationErrors.max && <span className="text-danger">{validationErrors.max}</span>}
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        <Button className="overlay-disable" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DynamicForm;
