import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';

import { TablePagination } from '../../../../../../Common';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { useTranslation } from 'react-i18next';

const Table = props => {
  const { t } = useTranslation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColums = [
        {
          name: `${t('S.No.')}`,
          selector: row => <span className="fw-medium">{row?.serialNumber}</span>,
          width: '90px',
        },
        {
          name: `${t('Booking ID')}`,
          selector: row => <span className="">{row?.bookingId}</span>,
          wrap: true,
          width: '200px',
        },
        {
          name: `${t('Name')}`,
          selector: row => row?.name,
          wrap: true,
          minWidth: '180px',
        },
        {
          name: `${t('Bank Name')}`,
          selector: row => row?.selectedClientCode,
          wrap: true,
          width: '180px',
        },
        {
          name: `${t('Date Time of Request')}`,
          selector: row => row?.createdAt,
          wrap: true,
          width: '200px',
        },
        {
          name: `${t('Status')}`,
          width: '150px',
          cell: row => (
            <span
              className={`booking_status badge break-word bg-${
                Constant.BOOKING_STATUSES.filter(status => status?.name === row?.bookingStatus)[0]?.bgColor
              }`}
            >
              {row.bookingStatus}
            </span>
          ),
        },
      ];
      setColumns(fetchColums);
      setData(
        props?.data?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          let confirmedAt = `NA`;
          data?.bookingModifyPersons?.forEach(element => {
            if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
              confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
            }
          });

          return {
            serialNumber: `${props?.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: data?.bookingId || `NA`,
            name: data?.user?.name || `NA`,
            selectedClientCode: props?.bankName || `NA`,
            createdAt: data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            bookingStatus: data?.vendor?.status ?? 'NA',
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  return (
    <>
      {/* <button onClick={() => console.log(bookingData)}>get state</button> */}
      <Row>
        <Col xxl={12}>
          <Card>
            <CardHeader className="d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{t(`Booking Reports`)}</h4>

              {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VENDOR_DOWNLOAD_DUMP) && !props?.isMobile && props?.data?.length > 0 && (
                <span className="btn btn-success mb-1 mx-2" onClick={props?.dumpDownload}>
                  <i className="ri-download-line me-1 text-white fs-14 align-middle"></i> Download Dump
                </span>
              )}
            </CardHeader>

            {/* ------------------------ TABLE DETAILS ------------------------ */}
            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(String(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1))}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
