import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { baseUrl, customHeaders, thriweDiscoveryUrl } from '../../../../Containers/Config/index.js';
import { GET_MEMBERSHIPS, GET_MEMBERSHIPS_SUCCESS, GET_MEMBERSHIPS_ERROR } from '../../actionType.js';
import apiCall from '../../apiCall.js';
import cogoToast from 'cogo-toast';
import { Constant } from '../../../../Containers/Helpers/constant.js';

function* getMemberships({ payload }) {
  let url = Constant.PROJECT_CODE === 'THRIWE_APP' ? `${thriweDiscoveryUrl}/v1/admin/get-memberships` : `${baseUrl}/v2/admin/user-benefit-packs`;
  let headers =
    Constant.PROJECT_CODE === 'THRIWE_APP'
      ? {
          Authorization: Constant?.CLIENT_TOKEN,
          ...customHeaders,
        }
      : {
          Authorization: Constant?.CLIENT_TOKEN,
          ...customHeaders,
          'X-Requested-By': 'one-admin',
        };

  try {
    const response = yield call(apiCall, {
      url: url,
      method: 'GET',
      headers: headers,
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({
        type: GET_MEMBERSHIPS_SUCCESS,
        payload: response?.data,
      });
    } else {
      cogoToast.error(`${response?.response?.data?.message}`);
      yield put({
        type: GET_MEMBERSHIPS_ERROR,
        payload: response.data.message,
      });
    }
  } catch (error) {
    yield put({ type: GET_MEMBERSHIPS_ERROR, payload: error });
  }
}

export function* watchGetMemberships() {
  yield takeEvery(GET_MEMBERSHIPS, getMemberships);
}

function* getMembershipsSaga() {
  yield all([fork(watchGetMemberships)]);
}

export default getMembershipsSaga;
