import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import {
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  GET_COUPON_PROJECTS,
  GET_STATIC_COUPON,
  GET_STATIC_COUPON_ERROR,
  POST_STATIC_COUPON_EMPTY,
  UPDATE_STATIC_COUPON_EMPTY,
} from '../../../../../store/actions';
import { BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import CouponUploadModal from './Components/Modals/CouponUploadModal';
import CouponUpdateModal from './Components/Modals/UpdateCoupon';

const StaticCouponManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [loading, setLoading] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isCouponUploadModalShow, setIsCouponUploadModalShow] = useState(false);
  const [isCouponUpdateModalShow, setIsCouponUpdateModalShow] = useState(false);
  const [currentSelectedCouponLot, setCurrentSelectedCouponLot] = useState(null);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Get Static Coupons
  const { staticCoupons, staticCouponsLoading } = useSelector(state => ({
    staticCoupons: state?.GetStaticCoupons?.data,
    staticCouponsLoading: state?.GetStaticCoupons?.loading,
  }));

  // Upload Static Coupons
  const { uploadStaticCoupons, uploadStaticCouponsLoading } = useSelector(state => ({
    uploadStaticCoupons: state?.UploadStaticCoupon?.data,
    uploadStaticCouponsLoading: state?.UploadStaticCoupon?.loading,
  }));

  // Projects
  const { couponProjects, couponProjectsLoading } = useSelector(state => ({
    couponProjects: state?.GetCouponProject?.data,
    couponProjectsLoading: state?.GetCouponProject?.loading,
  }));

  // Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  // Update Static Coupons
  const { updateStaticCoupons, updateStaticCouponsLoading } = useSelector(state => ({
    updateStaticCoupons: state?.UpdateStaticCoupon?.data,
    updateStaticCouponsLoading: state?.UpdateStaticCoupon?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (staticCoupons === null) {
      dispatch({
        type: GET_STATIC_COUPON,
        payload: {
          urlParam: {
            pageIndex: currentPageIndex,
          },
        },
      });
    }

    if (couponProjects === null) {
      dispatch({
        type: GET_COUPON_PROJECTS,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: GET_STATIC_COUPON_ERROR,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (uploadStaticCoupons !== null) {
      dispatch({
        type: POST_STATIC_COUPON_EMPTY,
      });

      dispatch({
        type: GET_STATIC_COUPON,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber,
          },
        },
      });
    }

    if (updateStaticCoupons !== null) {
      dispatch({
        type: UPDATE_STATIC_COUPON_EMPTY,
      });

      dispatch({
        type: GET_STATIC_COUPON,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber,
          },
        },
      });
    }
  }, [uploadStaticCoupons, updateStaticCoupons]);

  /* ---------------------------- UPLOAD COUPON BUTTON HANDLER ---------------------------- */
  const uploadCouponButtonHandler = () => {
    setIsCouponUploadModalShow(true);
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_STATIC_COUPON,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
        },
      },
    });
  };

  /* ---------------------------- REFRESH HANDLER ---------------------------- */
  const refreshHandler = () => {
    dispatch({
      type: GET_STATIC_COUPON,
      payload: {
        urlParam: {
          pageIndex: currentPageIndex,
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          downloadType: 5,
        },
        // data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedCouponLot(params?.couponDetails);
    if (params?.actionType === `UPDATE`) {
      setIsCouponUpdateModalShow(true);
    }
  };

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (staticCouponsLoading || couponProjectsLoading || uploadStaticCouponsLoading || downloadDumpLoading || updateStaticCouponsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [staticCouponsLoading, couponProjectsLoading, uploadStaticCouponsLoading, downloadDumpLoading, updateStaticCouponsLoading]);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Static Coupons`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ------------------------ MODAL's  ------------------------ */}
          {isCouponUploadModalShow && (
            <CouponUploadModal
              data={{
                projects: couponProjects,
              }}
              onCloseHandler={() => setIsCouponUploadModalShow(false)}
            />
          )}

          {isCouponUpdateModalShow && <CouponUpdateModal data={currentSelectedCouponLot} onCloseHandler={() => setIsCouponUpdateModalShow(false)} />}
          <BreadCrumb title="Static Coupons" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `Static Coupons`]} />
          <Table
            data={staticCoupons?.results}
            totalData={staticCoupons?.count}
            pageNumber={currentPageNumber}
            dataStartedFrom={currentPageIndex}
            pageHandler={paginationHandler}
            refreshHandler={refreshHandler}
            uploadCoupon={uploadCouponButtonHandler}
            dumpDownload={downloadDumpHandler}
            actionHandler={tableActionHandler}
          />
        </Container>
      </div>
    </>
  );
};

export default StaticCouponManagement;
