import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';

import Table from './Components/Tables/Table';
import Filter from './Components/Filters/Filter';
import { Constant } from '../../../Helpers/constant';
import { BreadCrumb, Loader } from '../../../Common';
import BookingDetails from './Components/Details/Details';
import {
  GET_CLIENT_HISTORY_LIST,
  SEND_CLIENT_DOWNLOAD_DUMP,
  SEND_CLIENT_DOWNLOAD_DUMP_EMPTY,
  SEND_EMAIL,
  SEND_EMAIL_EMPTY,
  VENDOR_UPDATE_BOOKING,
  VENDOR_UPDATE_BOOKING_EMPTY,
} from '../../../../store/actions';

const History = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);
  const [isAirportPickUp, setIsAirportPickUp] = useState(true);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { GetHistoryList, GetHistoryLoading } = useSelector((state: any) => ({
    GetHistoryList: state?.GetClientHistoryList?.data,
    GetHistoryLoading: state?.GetClientHistoryList?.loading,
  }));

  const { updateBookings, updateBookingsLoading } = useSelector((state: any) => ({
    updateBookings: state?.VendorUpdateBooking?.data,
    updateBookingsLoading: state?.VendorUpdateBooking?.loading,
  }));

  const { SendMail, SendMailLoading } = useSelector((state: any) => ({
    SendMail: state?.SendEmail?.data,
    SendMailLoading: state?.SendEmail?.loading,
  }));

  const { DownloadDump, DownloadDumpLoading } = useSelector((state: any) => ({
    DownloadDump: state?.ClientDownloadDump?.data,
    DownloadDumpLoading: state?.ClientDownloadDump?.loading,
  }));

  useEffect(() => {
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);
    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          bookingTypeId: isAirportPickUp ? 5 : 6,
        },
        data: currentlyAppliedFilters,
      },
    });
  }, [dispatch, isAirportPickUp]);

  useEffect(() => {
    dispatch({
      type: SEND_EMAIL_EMPTY,
    });
  }, [SendMail]);

  useEffect(() => {
    dispatch({
      type: SEND_CLIENT_DOWNLOAD_DUMP_EMPTY,
    });
  }, [DownloadDump]);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: VENDOR_UPDATE_BOOKING_EMPTY,
      });
    }
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);
    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          bookingTypeId: isAirportPickUp ? 5 : 6,
        },
        data: currentlyAppliedFilters,
      },
    });
  }, [updateBookings]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.details);

    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    } else if (params?.actionType === `UPDATE`) {
      dispatch({
        type: SEND_EMAIL,
        payload: {
          data: {
            type: isAirportPickUp ? 5 : 6,
            bookingId: params?.details?.bookingId ?? '',
          },
        },
      });
    } else if (params?.actionType === `CANCEL`) {
      dispatch({
        type: VENDOR_UPDATE_BOOKING,
        payload: {
          data: {
            bookingTypeId: isAirportPickUp ? 5 : 6,
            bookingsData: [
              {
                objectId: params?.details?.bookingId,
                process: 'cancel-booking',
              },
            ],
          },
        },
      });
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setCurrentlyAppliedFilters(filters);

    setCurrentPageNumber(0);
    setCurrentPageIndex(0);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: 0,
          bookingTypeId: isAirportPickUp ? 5 : 6,
        },
        data: filters,
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_CLIENT_HISTORY_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          bookingTypeId: isAirportPickUp ? 5 : 6,
          ...currentlyAppliedFilters,
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD HANDLER ---------------------------- */
  const onDownloadHandler = () => {
    dispatch({
      type: SEND_CLIENT_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId: Constant?.PROJECT_CODE,
          bookingTypeId: isAirportPickUp ? 5 : 6,
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  useEffect(() => {
    return () => {
      setCurrentPageNumber(0);
      setCurrentPageIndex(0);
      setCurrentlyAppliedFilters(null);
    };
  }, []);

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    } else if (title === `History`) {
      setIsDetailsPage(false);
    }
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (GetHistoryLoading || SendMailLoading || DownloadDumpLoading || updateBookingsLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [GetHistoryLoading, SendMailLoading, DownloadDumpLoading, updateBookingsLoading]);

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {!isDetailsPage && (
            <>
              <BreadCrumb title="History" navigationby={breadcrumNavigationHandler} navigation={[`Dashboard`, `History`]} />
              <Filter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                setIsAirportPickUp={setIsAirportPickUp}
                isAirportPickUp={isAirportPickUp}
                currentPageNumber={currentPageNumber}
                setCurrentPageNumber={setCurrentPageNumber}
              />
              <Table
                data={GetHistoryList?.results ?? []}
                totalData={GetHistoryList?.count}
                pageNumber={currentPageNumber}
                dataStartedFrom={currentPageIndex}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                onClickPrimaryBtn={onDownloadHandler}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="History Details"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `History`, `History details`]}
              />
              <BookingDetails details={currentSelectedBookingDetails} bookingTypeID={isAirportPickUp ? 5 : 6} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default History;
