import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { GET_USER_BOOKINGS, GET_USER_BOOKINGS_SUCCESS, GET_USER_BOOKINGS_ERROR } from './../../actionType';
import { Constant } from '../../../../Containers/Helpers/constant';
import cogoToast from 'cogo-toast';
import apiCall from '../../apiCall';
import { baseUrl, customHeaders, indianProjectsBaseURL } from '../../../../Containers/Config';

function* getUserBookings({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${indianProjectsBaseURL}/admin/user-bookings-info`,
      method: 'GET',
      headers: { Authorization: Constant?.CLIENT_TOKEN, ...customHeaders },
      params: {
        ...payload?.urlParam,
      },
    });

    if (response.status === 200) {
      yield put({ type: GET_USER_BOOKINGS_SUCCESS, payload: response?.data });
    } else {
      cogoToast.error(response?.response?.data?.message);
      yield put({ type: GET_USER_BOOKINGS_ERROR, payload: response?.data?.message });
    }
  } catch (error) {
    yield put({ type: GET_USER_BOOKINGS_ERROR, payload: error });
  }
}

export function* watchGetUserBookings() {
  yield takeEvery(GET_USER_BOOKINGS, getUserBookings);
}

function* getUserBookingsSaga() {
  yield all([fork(watchGetUserBookings)]);
}

export default getUserBookingsSaga;
