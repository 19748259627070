import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import DefaultScreen from '../../DefaultScreen';
import Offer from '../../ExistingCustomer/Components/Offer';
import GeneratedVoucher from '../../Common/GeneratedVoucher';
import OfferInvoiceModal from '../../ExistingCustomer/Components/OfferInvoiceModal';
import { CREATE_BOOKING, GET_BENEFIT_ITEMS, GET_BENEFIT_ITEMS_EMPTY } from '../../../../../../../../../store/actions';
import { Error } from '../../../../../../../../Common';

const NewRedemption = ({
  createBookingRes,
  registerCustomerData,
  membership,
  benefitConfig,
  benefitItems,
  otpValidate,
  currentRedemptionJourney,
  setCurrentRedemptionJourney,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [isShowDefaultScreen, setIsShowDefaultScreen] = useState(true);

  const [bookingsDetails, setBookingsDetails] = useState(null);
  const [selectedBenefitItem, setSelectedBenefitItem] = useState('');

  const [isShowInvoiceModal, setIsShowInvoiceModal] = useState(false);
  const [dataForBooking, setDataForBooking] = useState(null);
  const [newVoucherBookingDetails, setNewVoucherBookingDetails] = useState(null);

  const onChangeDefaultScreen = () => {
    dispatch({
      type: GET_BENEFIT_ITEMS_EMPTY,
    });

    setCurrentRedemptionJourney(`existing-customer-journey`);
    setIsShowDefaultScreen(!isShowDefaultScreen);
    fetchBenefitItems(`existing-customer-journey`);
  };

  const fetchBenefitItems = currentJourney => {
    if (benefitConfig?.length > 0 && currentJourney === `existing-customer-journey`) {
      const existingUserBenefitConfig = benefitConfig?.filter(benefitConfig => benefitConfig?.allocatedTo === `existing_user`);

      if (existingUserBenefitConfig?.length > 0) {
        dispatch({
          type: GET_BENEFIT_ITEMS,
          endpoint: '/v2/client/benefit-items',
          payload: {
            urlParam: {
              benefitConfigId: existingUserBenefitConfig?.[0]?.objectId,
            },
            headers: { Authorization: otpValidate?.headers?.authorization },
          },
        });

        setBookingsDetails({
          membershipId: membership?.[0]?.objectId,
          benefitConfigId: existingUserBenefitConfig?.[0]?.objectId,
        });
      }
    }
  };

  /* ---------------------------- SELECT BENEFIT ITEM ---------------------------- */
  const onSelectBenefitItem = e => {
    setSelectedBenefitItem(e?.target?.value);
  };

  /* ---------------------------- ON GENERATE BOOKING ---------------------------- */
  const generateCouponBooking = (bookingData, invoiceData) => {
    setBookingsDetails({ ...bookingsDetails, serviceId: benefitItems?.[0]?.service?.id });
    let userId = JSON.parse(localStorage.getItem('authUser'))?.data?.userId;

    if (benefitItems != null) {
      dispatch({
        type: CREATE_BOOKING,
        endpoint: '/v2/booking',
        payload: {
          data: {
            process: 'initiate-booking',
            vendor: {
              vendorId: userId,
            },
            ...invoiceData,
          },
          headers: { Authorization: otpValidate?.headers?.authorization },

          urlParams: {
            ...bookingData,
          },
        },
      });
    }
  };

  /* ---------------------------- ON SUBMIT INVOICE ---------------------------- */
  const onSubmitInvoice = invoiceData => {
    setIsShowInvoiceModal(false);
    generateCouponBooking({ ...dataForBooking }, invoiceData);
  };

  /* ---------------------------- ON GENERATE VOUCHER ---------------------------- */
  const onGenerateVoucher = () => {
    const selectedServiceItem = benefitItems?.filter(benefitItem => benefitItem?.objectId === selectedBenefitItem);

    if (selectedServiceItem?.length > 0 && selectedBenefitItem) {
      setDataForBooking({
        benefitConfigId: bookingsDetails?.benefitConfigId,
        membershipId: bookingsDetails?.membershipId,
        serviceId: selectedServiceItem?.[0]?.service?.id,
      });
      setIsShowInvoiceModal(true);
    }
  };

  useEffect(() => {
    if (createBookingRes !== null && currentRedemptionJourney === `existing-customer-journey`) {
      setNewVoucherBookingDetails(createBookingRes);
    }
  }, [createBookingRes]);

  return (
    <>
      {isShowDefaultScreen && (
        <Col md={6} className="d-flex align-items-center">
          <DefaultScreen
            data={{
              name: `${t('Existing Customer')}`,
              description: `${t(`Generate discount voucher for SAB Bank existing credit card customers`)}`,
              btnTitle: `${t(`Redeem offer`)}`,
              icon: `ri-group-fill`,
            }}
            onClick={onChangeDefaultScreen}
          />
        </Col>
      )}

      {!isShowDefaultScreen && (
        <>
          {createBookingRes === null && benefitItems?.length > 0 && (
            <Col md={6} className="d-flex align-items-center">
              <div>
                <h5>Offers</h5>

                <Row>
                  {benefitItems?.map((benefitData, index) => (
                    <Offer key={index} benefit={benefitData} onSelectBenefitItem={onSelectBenefitItem} selectedBenefitItem={selectedBenefitItem} />
                  ))}

                  {/* {['', null, undefined]?.includes(selectedBenefitItem) && <Error text="Please select offer!" />} */}
                </Row>

                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-success px-4"
                    disabled={selectedBenefitItem === `` || selectedBenefitItem === null}
                    onClick={onGenerateVoucher}
                  >
                    {t(`Generate Voucher`)}
                  </button>
                </div>
              </div>
            </Col>
          )}

          {createBookingRes !== null && currentRedemptionJourney === `existing-customer-journey` && (
            <GeneratedVoucher data={newVoucherBookingDetails} flag={`new_user`} />
          )}
        </>
      )}

      {isShowInvoiceModal && (
        <OfferInvoiceModal
          selectedBenefitItem={benefitItems?.filter(benefitItems => benefitItems?.objectId === selectedBenefitItem)?.[0]}
          data={registerCustomerData}
          onSubmit={onSubmitInvoice}
          onClose={() => setIsShowInvoiceModal(false)}
        />
      )}
    </>
  );
};

export default NewRedemption;
