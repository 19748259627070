import React, { useEffect, useState } from 'react';

import moment from 'moment';
import DataTable from 'react-data-table-component';

import { Badge, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import { Constant } from '../../../../../../Helpers/constant';
import { formattedWithComma } from '../../../../../../Helpers/Helpers';

const Table = props => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No.',
          width: '90px',
          selector: row => row?.serialNumber,
        },
        {
          name: 'ID',
          width: '250px',
          wrap: true,
          cell: row => (
            <span
              className="fw-semibold text-primary text-decoration-underline cursor-pointer"
              onClick={() => actionBtnHandler('VIEW', row?.originalData)}
            >
              {row?.originalData?.id}
            </span>
          ),
        },
        {
          name: 'Program',
          width: '180px',
          selector: row => row?.project_id,
        },
        {
          name: 'Channel',
          width: '120px',
          selector: row => row?.channel,
        },
        {
          name: 'Event Type',
          width: '220px',
          wrap: true,
          selector: row => row?.event_type,
        },
        {
          name: 'Template Name',
          width: '240px',
          wrap: true,
          selector: row => row?.template_name,
        },
        {
          name: 'Created At',
          width: '180px',
          selector: row => row?.created_at,
        },
        {
          name: 'Vendor Status',
          width: '130px',
          selector: row => row?.vendor_status,
        },
        {
          name: 'Status',
          width: '130px',
          selector: row => checkCommsStatusHandler(row?.status),
        },
        {
          name: 'Message',
          width: '250px',
          wrap: true,
          selector: row => row?.message,
        },
      ];
      setColumns(fetchColumns);

      const data = props?.data?.map((comms, index) => ({
        serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
        project_id: comms?.project_id,
        channel: comms.channel,
        event_type: comms.event_type,
        template_name: comms?.template_name,
        vendor_status: comms?.vendor_status,
        status: comms?.status,
        message: comms?.message,
        created_at: moment(new Date(`${comms?.created_at}`)).format('DD-MM-YYYY, h:mm A'),
        originalData: comms,
      }));
      setData(data);
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        reportData: data,
        actionType: flag,
      });
    }
  };

  /* ---------------------------- CHECK COMMS STATUS HANDLER ---------------------------- */
  const checkCommsStatusHandler = status => {
    switch (status) {
      case 'SUCCESS':
        return <Badge color="success"> {`Success`} </Badge>;

      case 'FAILED':
        return <Badge color="danger"> {`Failed`} </Badge>;

      default:
      // Need not to do anything
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <PreviewCardHeader title={props.title} isShowFilterBtn={props?.isShowFilterBtn} filterBtnHandler={props?.filterBtnHandler} />

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                highlightOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                    },
                  },
                }}
              />
            </CardBody>

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table, (prevProps, nextProps) => prevProps.data === nextProps.data);
