import React, { useEffect, useState } from 'react';

import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Card, CardBody, Col, Row, Container, Label } from 'reactstrap';

const CustomerDetails = props => {
  const [details, setDetails] = useState([]);

  useEffect(() => {
    setDetails({
      ...props.details,
    });
  }, [props.details]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <Card className="mt-n4 mx-n4 mb-n5">
            <div className="bg-soft-warning">
              <CardBody className="pb-4 mb-5">
                {/* <button onClick={() => console.log(details)}>Get state</button> */}
                <Row>
                  <div className="col-md">
                    <Row className="align-items-center">
                      <div className="col-md-auto mb-1 mt-4"></div>
                      <div className="col-md">
                        <h4 className="fw-semibold" id="ticket-title">
                          {details?.objectId || ''}
                        </h4>
                      </div>
                    </Row>
                  </div>
                </Row>
              </CardBody>
            </div>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Personal Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Full Name'}</Label>
                    <Label className="text-muted">{`${details?.firstName} ${details?.lastName}` || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Email'}</Label>
                    <Label className="text-muted">{details?.email || `NA`}</Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Mobile No.'}</Label>
                    <Label className="text-muted">
                      {`${details?.countryCode ? `+${details?.countryCode}-` : ''}${details?.mobileNumber ?? ''}` || `NA`}
                    </Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Membership Id's</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                {details?.memberships?.map((membership, index) => (
                  <Col md={4} key={index}>
                    <div className="mb-3">
                      <Label className="d-flex">{'Membership ID'}</Label>
                      <Label
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          color: '#3577f1',
                        }}
                        onClick={() => props?.membershipDetails(membership)}
                      >
                        {membership || `NA`}
                      </Label>

                      {/* <Label
                        className="text-muted"
                        id={`membership-${index}`}
                        style={{ cursor: 'pointer' }}
                      >
                        {membership?.membershipId || `NA`}

                        <UncontrolledTooltip
                          placement="top"
                          target={`membership-${index}`}
                        >
                          <div>
                            Creation: &nbsp;
                            {membership?.createdAt
                              ? moment(`${membership?.createdAt}`).format(
                                  'DD-MM-YYYY, h:mm A',
                                )
                              : `NA`}
                          </div>

                          <div>
                            Expiry: &nbsp;
                            {membership?.expiryDate
                              ? moment(`${membership?.expiryDate}`).format(
                                  'DD-MM-YYYY, h:mm A',
                                )
                              : `NA`}
                          </div>
                        </UncontrolledTooltip>
                      </Label> */}
                    </div>
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody className="p-4">
              <h6 className="fw-semibold text-uppercase mb-3">Membership Details</h6>
              <div className="border mt-2 mb-3 border"></div>
              <Row>
                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Created At'}</Label>
                    <Label className="text-muted">
                      {details?.createdAt ? moment(`${new Date(`${details?.createdAt}`)}`).format('DD-MM-YYYY, h:mm A') : `NA`}
                    </Label>
                  </div>
                </Col>

                <Col md={4}>
                  <div className="mb-3">
                    <Label className="d-flex">{'Handicap'}</Label>
                    <Label className="text-muted">{details?.handicap || `NA`}</Label>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ReactTooltip />
    </Container>
  );
};

export default CustomerDetails;
