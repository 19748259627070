import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader } from '../../../Common';
import { Container } from 'reactstrap';
import moment from 'moment';
import {
  CLICK_DOWNLOAD_DUMP,
  GET_CARD_LIST,
  GET_CARD_LIST_EMPTY,
  GET_MEMBERSHIP_DETAILS,
  GET_MEMBERSHIP_DETAILS_EMPTY,
  GET_USER_RIGHTS,
  PROGRAM_USERS_LIST,
  PROGRAM_USER_EMPTY,
  UPDATE_CLIENTS_CUSTOMER,
  UPDATE_CLIENTS_CUSTOMER_EMPTY,
} from '../../../../store/application/actionType';
import Table from './Components/Tables/Table';
import { Constant } from '../../../Helpers/constant';
import CustomerDetails from './Components/Details/Details';
import Filter from './Components/Filters/Filter';
import MembershipModal from './Components/Modals/MembershipModal';
import CardListModal from './Components/Modals/CardList';
import cogoToast from 'cogo-toast';
import CustomFilter from './Components/Filters/CustomFilter';
import ConfirmModal from './Components/Modals/Confirm';

const CustomersManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);

  const [loading, setLoading] = useState(false);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);

  const [currentSelectedCustomerDetails, setCurrentSelectedCustomerDetails] = useState([]);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);

  const [isMembershipDetailsShow, setIsMembershipDetailsShow] = useState(false);
  const [currentSelectedMembershipDetails, setCurrentSelectedMembershipDetails] = useState(null);

  const [isCardListModalShow, setIsCardListModalShow] = useState(false);

  const [isShowFilters, setIsShowFilters] = useState(false);
  const [isConfirmationModalShow, setIsConfirmationModalShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Rights
  const { rights, rightsLoading } = useSelector(state => ({
    rights: state?.AllRights?.data,
    rightsLoading: state?.AllRights?.loading,
  }));

  // Create User
  const { createUser, createUserLoading } = useSelector(state => ({
    createUser: state?.CreateUser?.data,
    createUserLoading: state?.CreateUser?.loading,
  }));

  // Program Users
  const { programUsers, programUsersLoading } = useSelector(state => ({
    programUsers: state?.ProgramUsers?.data,
    programUsersLoading: state?.ProgramUsers?.loading,
  }));

  // Membership Details
  const { membershipDetails, membershipDetailsLoading } = useSelector(state => ({
    membershipDetails: state?.MembershipDetails?.data,
    membershipDetailsLoading: state?.MembershipDetails?.loading,
  }));

  // Card List
  const { cardDetails, cardDetailsLoading } = useSelector(state => ({
    cardDetails: state?.CardDetails?.data,
    cardDetailsLoading: state?.CardDetails?.loading,
  }));

  // Download Dump
  const { downloadDumpLoading } = useSelector(state => ({
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  // Update User
  const { updateUser } = useSelector(state => ({
    updateUser: state?.UpdateClientsCustomer?.data,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */

  useEffect(() => {
    if (updateUser !== null) {
      dispatch({
        type: PROGRAM_USERS_LIST,
        payload: {
          urlParam: {
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            ...currentlyAppliedFilters,
          },
        },
      });
      dispatch({
        type: UPDATE_CLIENTS_CUSTOMER_EMPTY,
      });
    }
  }, [updateUser]);

  useEffect(() => {
    dispatch({
      type: PROGRAM_USERS_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          ...currentlyAppliedFilters,
        },
      },
    });
  }, [dispatch]);

  useEffect(() => {
    if (rights === null) {
      dispatch({
        type: GET_USER_RIGHTS,
      });
    }

    return () => {
      dispatch({
        type: PROGRAM_USER_EMPTY,
      });

      dispatch({ type: GET_MEMBERSHIP_DETAILS_EMPTY });
    };
  }, [dispatch]);

  useEffect(() => {
    if (membershipDetails !== null) {
      setCurrentSelectedMembershipDetails(membershipDetails?.results);
      setIsMembershipDetailsShow(true);
    }
  }, [membershipDetails]);

  useEffect(() => {
    if (cardDetails !== null) {
      if (cardDetails?.results === null) {
        cogoToast.error(`Card not found.`);
        dispatch({
          type: GET_CARD_LIST_EMPTY,
        });
      } else {
        setIsCardListModalShow(true);
      }
    }
  }, [cardDetails]);

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedCustomerDetails(params?.details);

    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(true);
    } else if (params?.actionType === `CARD_LIST`) {
      dispatch({
        type: GET_CARD_LIST,
        payload: {
          urlParam: {
            userId: `${params?.details?.objectId}`,
          },
        },
      });
    } else if (params?.actionType === `UPDATE_STATUS`) {
      setIsConfirmationModalShow(true);
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    setIsShowFilters(false);
    const urlParams = {
      email: filters?.email === `` ? null : filters?.email,
      mobileNumber: filters?.mobileNumber === `` ? null : filters?.mobileNumber,
      // firstName: filters?.firstName === `` ? null : filters?.firstName,
    };

    setCurrentlyAppliedFilters(urlParams);

    setCurrentPageNumber(0);

    dispatch({
      type: PROGRAM_USERS_LIST,
      payload: {
        urlParam: {
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          ...urlParams,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    dispatch({
      type: PROGRAM_USERS_LIST,
      payload: {
        urlParam: {
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          ...currentlyAppliedFilters,
        },
      },
    });
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    let tempData = {};
    if (currentlyAppliedFilters !== null) {
      tempData = {
        email: currentlyAppliedFilters?.email ?? '',
        phoneNumber: currentlyAppliedFilters?.mobileNumber ?? '',
      };
    }

    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          downloadType: 3,
        },
        data: {
          ...tempData,
        },
      },
    });
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Customer Management`) {
      setIsDetailsPage(false);
    } else if (title === `Complete Details`) {
      // do nothing
    }
  };

  /* ---------------------------- MEMBERSHIP HANDLER ---------------------------- */
  const membershipDetailsHandler = membershipId => {
    setIsMembershipDetailsShow(null);
    dispatch({
      type: GET_MEMBERSHIP_DETAILS,
      payload: {
        urlParam: {
          membershipId: membershipId,
        },
      },
    });
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (rightsLoading || createUserLoading || programUsersLoading || membershipDetailsLoading || cardDetailsLoading || downloadDumpLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [rightsLoading, createUserLoading, programUsersLoading, membershipDetailsLoading, cardDetailsLoading, downloadDumpLoading]);

  const updateUserHandler = data => {
    dispatch({
      type: UPDATE_CLIENTS_CUSTOMER,
      payload: {
        data: {
          objectId: `${data?.objectId}`,
          isActive: !data?.isActive,
        },
      },
    });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ----------------------------  MODAL ---------------------------- */}
          {isMembershipDetailsShow && (
            <MembershipModal data={currentSelectedMembershipDetails} onCloseHandler={() => setIsMembershipDetailsShow(false)} />
          )}

          {isCardListModalShow && (
            <CardListModal data={currentSelectedCustomerDetails} cardDetails={cardDetails} onCloseHandler={() => setIsCardListModalShow(false)} />
          )}

          {isConfirmationModalShow && (
            <ConfirmModal
              data={currentSelectedCustomerDetails}
              onCloseHandler={() => setIsConfirmationModalShow(false)}
              updateUserHandler={updateUserHandler}
            />
          )}

          {!isDetailsPage && (
            <>
              <BreadCrumb title="Customer Management" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Customer Management']} />
              <CustomFilter
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                setCurrentPageNumber={setCurrentPageNumber}
                show={isShowFilters}
                onCloseClick={() => setIsShowFilters(false)}
                onReset={() => setIsShowFilters(false)}
                setCurrentlyAppliedFilters={setCurrentlyAppliedFilters}
              />
              <Table
                data={programUsers?.results ?? []}
                totalData={programUsers?.length !== null && programUsers?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                filterBtnHandler={() => setIsShowFilters(true)}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="Customer Management"
                navigationby={breadcrumNavigationHandler}
                navigation={['Dashboard', 'Customer Management', `Complete Details`]}
              />
              <CustomerDetails details={currentSelectedCustomerDetails} membershipDetails={membershipDetailsHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default CustomersManagement;
