import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';

import { Card, CardBody, Col, Form, Label, Row, CardFooter, Input, Container, OffcanvasBody, OffcanvasHeader, Offcanvas } from 'reactstrap';
import { PreviewCardHeader } from '../../../../../../Common';
import { GET_BOOKING_TYPES_LIST, GET_CATEGORY_EMPTY } from '../../../../../../../store/application/actionType';
import Select from 'react-select';
import { isValidAplha } from '../../../../../../Helpers/Helpers';
import moment from 'moment';

const CustomFilter = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    name: ``,
    updateDate: ``,
    typeId: ``,
  };
  const [filter, setFilter] = useState(initialState);
  const [isResetDisabled, setIsResetDisabled] = useState(true);

  const [bookingTypesData, setBookingTypesData] = useState([]);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Booking Types
  const { bookingTypes } = useSelector(state => ({
    bookingTypes: state?.BookingTypes?.data,
  }));

  useEffect(() => {
    if (bookingTypes === null) {
      dispatch({
        type: GET_BOOKING_TYPES_LIST,
      });
    }
  }, []);

  useEffect(() => {
    if (props?.appliedFilters !== null) {
      setFilter(props?.appliedFilters);
      validationCheck(props?.appliedFilters);
    }
  }, [props?.appliedFilters]);

  useEffect(() => {
    if (bookingTypes !== null) {
      setBookingTypesData(
        bookingTypes?.results?.map(bookingType => {
          return {
            value: bookingType?.objectId,
            label: bookingType?.name,
            data: bookingType,
          };
        }),
      );
    }
  }, [bookingTypes]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...filter };

    switch (name) {
      case 'name':
        updatedState = {
          ...filter,
          name: isValidAplha(value) ? value : filter?.name,
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck({ ...updatedState });
  };

  /* ---------------------------- INPUT SELECT HANDLER ---------------------------- */
  const inputSelectHandler = (label, data) => {
    let updatedState = { ...filter };

    if (label === `typeId`) {
      updatedState = {
        ...filter,
        typeId: data,
      };
    }

    setFilter(updatedState);
    validationCheck({ ...updatedState });
  };

  /* ---------------------------- DATE SELECT HANDLER ---------------------------- */
  const datePickHandler = (flag, date) => {
    let updatedState = { ...filter };

    switch (flag) {
      case 'updateDate':
        updatedState = {
          ...filter,
          updateDate: new Date(date).toISOString(),
        };
        break;

      default:
      // do nothing
    }

    setFilter(updatedState);
    validationCheck(updatedState);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validationCheck = data => {
    if (data?.name !== `` || data?.updateDate !== `` || data?.typeId !== ``) {
      setIsResetDisabled(false);
    } else {
      setIsResetDisabled(true);
    }
  };

  /* ---------------------------- ON SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = () => {
    props.filterHandler({ ...filter });
  };

  /* ---------------------------- ON RESET HANDLER ---------------------------- */
  const resetFilterHandler = () => {
    setFilter({ ...initialState });
    setIsResetDisabled(true);

    dispatch({
      type: GET_CATEGORY_EMPTY,
    });
  };

  return (
    <>
      <Offcanvas direction="end" isOpen={props.show} id="offcanvasExample" toggle={() => props.onCloseClick()}>
        <OffcanvasHeader className="bg-light" toggle={() => props.onCloseClick()}>
          Category Filters
        </OffcanvasHeader>
        <OffcanvasBody>
          {/* <button onClick={() => console.log('state', filter)}>get state</button> */}
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="name" className="form-label">
                  Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  value={filter?.name}
                  onChange={inputChangeHandler}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="bookingType" className="form-label">
                  Booking Type
                </Label>
                <Select
                  id="bookingType"
                  name="bookingType"
                  options={bookingTypesData ?? []}
                  value={filter?.typeId}
                  onChange={data => inputSelectHandler(`typeId`, data)}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <Label htmlFor="to" className="form-label">
                  Last Updated At
                  <span className="icon_calendar">
                    <i className="las la-calendar"></i>
                  </span>
                </Label>
                <div className="area_expandable">
                  <Flatpickr
                    className="form-control"
                    name="updateDate"
                    id="updateDate"
                    value={filter?.updateDate}
                    onChange={date => datePickHandler(`updateDate`, date)}
                    options={{
                      enableTime: false,
                      dateFormat: 'd M, Y',
                      maxDate: 'today',
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </OffcanvasBody>

        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
          <button
            className="btn btn-light w-100"
            onClick={resetFilterHandler}
            // disabled={isResetDisabled}
          >
            Reset Filter
          </button>

          <button className="btn btn-primary w-100" onClick={filterSubmitHandler}>
            Search
          </button>
        </div>
      </Offcanvas>
    </>
  );
};

export default CustomFilter;
