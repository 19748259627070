import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';

//import images
import logoSm from '../assets/images/logo-sm.png';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';

//import Components
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';
import ProjectsDropdown from '../Containers/Pages/Headers/ProjectsDropdown';
import { useLocation } from 'react-router-dom';
import { Constant } from '../Containers/Helpers/constant';
import LanguageDropdown from '../Components/Common/LanguageDropdown';
import i18n from '../i18n';
import i18next from 'i18next';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
  const history = useHistory();
  const location = useLocation();
  const [isClientType, setIsClientType] = useState(false);
  const [language, setLanguage] = useState(i18n.resolvedLanguage);
  const [isMobile, setIsMobile] = useState(false);

  // useEffect(()=>{
  //   setLanguage(localStorage.getItem('I18N_LANGUAGE'))
  // },[localStorage.getItem('I18N_LANGUAGE')])
  // Function to check if the device is a mobile device based on the screen width
  const checkDeviceType = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  i18next.on('languageChanged', function (lng) {
    console.log('language2', setLanguage(i18n.resolvedLanguage));
  });

  useEffect(() => {
    // Add event listener to window resize event
    window.addEventListener('resize', checkDeviceType);
    // Initial check of device type on component mount
    checkDeviceType();
    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  useEffect(() => {
    const isClientType = location?.pathname?.includes('/client/') ?? false;
    setIsClientType(isClientType);
  }, []);

  const [search, setSearch] = useState(false);
  const toogleSearch = () => {
    setSearch(!search);
  };

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu');
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute('data-layout') === 'vertical') {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm');
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable');
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg');
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable');
        document.documentElement.setAttribute('data-sidebar-size', 'lg');
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel');
    }
  };

  if (['', null, undefined]?.includes(JSON.parse(localStorage.getItem('authUser') ?? null)?.token)) {
    let redirectionRoute = `/`;
    const userType = parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
    if (userType === Constant?.userTypes?.ADMIN_USER) {
      redirectionRoute = `/login`;
    } else if (userType === Constant?.userTypes?.CLIENT) {
      redirectionRoute = `/client/login`;
    } else if (userType === Constant?.userTypes?.VENDOR) {
      redirectionRoute = `/vendor/login`;
    } else {
      redirectionRoute = `/login`;
    }
    history.push(`${redirectionRoute}`);
  }

  const getUserType = () => {
    return parseInt(JSON.parse(window.localStorage.getItem('userType') ?? -1));
  };

  const user = JSON.parse(localStorage.getItem('data'));

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSm} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="17" />
                  </span>
                </Link>
              </div>
              {(user?.program?.code !== 'SAB' || (user?.program?.code === 'SAB' && !isMobile)) && (
                <button
                  onClick={toogleMenuBtn}
                  type="button"
                  className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                  id="topnav-hamburger-icon"
                >
                  <span className="hamburger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              )}
            </div>

            <div className="d-flex align-items-center">
              {/*Language dropdown*/}

              {(['Valet Parking'].includes(JSON.parse(localStorage.getItem('data'))?.program?.name) || ['SAB_LANDMARK'].includes(JSON.parse(localStorage.getItem('data'))?.program?.code)) && <LanguageDropdown />}
              <b>{JSON.parse(localStorage.getItem('data'))?.program?.name == 'Valet Parking' && language}&nbsp;</b>
              <div>{JSON.parse(localStorage.getItem('data'))?.program?.name}</div>
              {getUserType() === Constant?.userTypes?.ADMIN_USER && <ProjectsDropdown />}

              {/* MyCartDropdwon */}

              {/* FullScreenDropdown */}
              <FullScreenDropdown />

              {/* Dark/Light Mode set */}

              {/* ProfileDropdown */}
              <ProfileDropdown selectedUserType={getUserType()} />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
