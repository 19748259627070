import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import WorkflowTable from './Components/Tables/Table';
import { BreadCrumb } from '../../../../Common';

const WorkflowManagement = () => {
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isWorkflowsShow, setIsWorkflowShow] = useState(true);

  /* ---------------------------- CREATE WORKFLOW ---------------------------- */
  const createNewWorkflow = () => {
    setIsWorkflowShow(!isWorkflowsShow);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Workflows`) {
      setIsWorkflowShow(true);
    } else if (title === `Create Workflow`) {
      // do nothing
    }
  };

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        {isWorkflowsShow && (
          <>
            <BreadCrumb title="Brand" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Workflows']} />
            <WorkflowTable
              title={`Workflow's Data`}
              // data={categories?.results}
              // totalData={categories?.count}
              // pageHandler={paginationHandler}
              // pageNumber={currentPageNumber}
              // actionHandler={tableActionHandler}
              isShowFilterBtn={true}
              filterBtnHandler={() => {
                // setIsShowFilters(true)
              }}
              isShowPrimaryButton={true}
              createNewWorkflow={createNewWorkflow}
            />
          </>
        )}

        {!isWorkflowsShow && (
          <>
            <BreadCrumb title="Brand" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Workflows', `Create Workflow`]} />
          </>
        )}
      </Container>
    </div>
  );
};

export default WorkflowManagement;
