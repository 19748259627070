import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';

import { Error } from '../../../../../Common';
import { UPDATE_CUSTOMER_DATA } from '../../../../../../store/actions';
import MobileField from '../../../../../Common/FormControls/MobileField';
import { isValidEmail, isValidNumber } from '../../../../../Helpers/Helpers';

const initialValues = {
  firstName: '',
  email: '',
  phoneNumber: '',
  countryCode: '',
  id: '',
};

const UpdateUser = props => {
  const dispatch = useDispatch();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialValues);
  const [isValidationShow, setIsValidationShow] = useState(false);
  const [loading, setLoading] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { updateUserLoading } = useSelector(state => ({
    updateUserLoading: state.UpdateCustomer.loading,
  }));

  useEffect(() => {
    if (props?.data !== null) {
      setState({
        ...state,
        id: props?.data?.userId || '',
        firstName: props?.data?.firstName || '',
      });
    }
  }, [props?.data]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = null;

    switch (name) {
      case 'firstName':
        updatedState = {
          ...state,
          firstName: value,
        };
        setState(updatedState);
        break;

      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      case 'phoneNumber':
        updatedState = {
          ...state,
          phoneNumber: value,
        };
        setState(updatedState);
        validateErrors(updatedState, `validationCheck`);
        break;

      default:
      // do nothing
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.email?.trim() === '' && data?.phoneNumber?.trim() === '') {
      setIsValidationShow(true);
      return false;
    } else if (data?.email?.trim() !== '' && !isValidEmail(data?.email?.trim())) {
      setIsValidationShow(true);
      return false;
    } else if (data?.phoneNumber?.trim() !== '' && !isValidNumber(data?.phoneNumber?.trim())) {
      setIsValidationShow(true);
      return false;
    } else {
      setIsValidationShow(false);
      return true;
    }
  };

  const getNonEmptyFields = state => {
    const filteredState = {};
    for (const key in state) {
      if (state[key]?.trim()) {
        filteredState[key] = state[key];
      }
    }
    return filteredState;
  };

  const updateUserHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const filteredState = getNonEmptyFields(state);
      dispatch({
        type: UPDATE_CUSTOMER_DATA,
        payload: {
          data: {
            ...filteredState,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (updateUserLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [updateUserLoading]);

  const mobileChangeHandler = async (value, data) => {
    let updatedState = {
      ...state,
      countryCode: data?.dialCode,
      phoneNumber: value.slice(data?.dialCode?.length),
    };
    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  return (
    <Modal centered isOpen={true} scrollable={true} id="user-cards-list" size="md">
      <div className="modal-header p-3 bg-soft-warning">
        <h5 className="modal-title" id="createboardModalLabel">
          Update User
        </h5>
        <Button
          type="button"
          onClick={() => props.setModal(false)}
          id="addBoardBtn-close"
          className="btn-close btn-light"
          aria-label="Close"
        ></Button>
      </div>
      <ModalBody>
        <form>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="firstName" className="form-label">
                    Name
                  </Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="form-control"
                    value={state?.firstName}
                    onChange={inputChangeHandler}
                    placeholder=""
                    disabled={true}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="email" className="form-label">
                    Email
                  </Label>
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    className="form-control"
                    value={state?.email}
                    onChange={inputChangeHandler}
                    placeholder=""
                  />
                </div>
                {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email) && <Error text="Please enter correct email" />}
              </div>
            </Col>

            <Col md={12}>
              <div className="mb-3">
                <div>
                  <Label htmlFor="phoneNumber" className="form-label">
                    Mobile Number
                  </Label>
                  <MobileField
                    onChange={mobileChangeHandler}
                    value={`${state?.countryCode}${state?.phoneNumber}`}
                    preferredCountries={['sa', 'uae', 'in']}
                    defaultCountry={'in'}
                  />
                </div>
                {isValidationShow && state?.phoneNumber?.trim() !== `` && !isValidNumber(state?.phoneNumber) && (
                  <Error text="Please enter correct number" />
                )}
              </div>
            </Col>
          </Row>
          {isValidationShow && state?.email?.trim() === '' && state?.phoneNumber?.trim() === '' && (
            <Error text="Please enter either an email or a mobile number" />
          )}
        </form>
      </ModalBody>

      <div className="modal-footer d-flex justify-content-center pt-3">
        <Button className="btn btn-primary" color="primary" onClick={updateUserHandler}>
          {loading ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            'Update'
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default UpdateUser;
