import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BreadCrumb, Loader, PreviewCardHeader } from '../../../../Common';
import { Container } from 'reactstrap';

import LocationForm from './Components/Form';
import {
  GET_COUNTRY,
  GET_COUPON_FACILITY,
  GET_COUPON_PARTNER,
  GET_REDEMPTION_TYPE,
  GET_REGION,
  GET_SUB_REGION,
  CREATE_LOCATION_EMPTY,
  GET_EMPTY_POST_COUPON,
} from '../../../../../store/application/actionType';
import CountryCode from './../../../../Utility/countryCode';
import BulkUploadModal from './Components/Modals/BulkUpload';

const AddLocation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [loading, setLoading] = useState(false);
  const [isBulkUploadShow, setIsBulkUploadShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Partners
  const { partners, partnersLoading } = useSelector(state => ({
    partners: state?.GetCouponPartner?.data,
    partnersLoading: state?.GetCouponPartner?.loading,
  }));

  // Redemption Type
  const { redemptionType, redemptionTypeLoading } = useSelector(state => ({
    redemptionType: state?.GetRedemptionType?.data,
    redemptionTypeLoading: state?.GetRedemptionType?.loading,
  }));

  // Countries
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Regions
  const { regions, regionsLoading } = useSelector(state => ({
    regions: state?.Regions?.data,
    regionsLoading: state?.Regions?.loading,
  }));

  // Sub Regions
  const { subRegions, subRegionsLoading } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
    subRegionsLoading: state?.SubRegions?.loading,
  }));

  // Create Partner
  const { createFacility, createFacilityLoading } = useSelector(state => ({
    createFacility: state?.CreateLocation?.data,
    createFacilityLoading: state?.CreateLocation?.loading,
  }));

  // Bulk Upload
  const { bulkUpload, bulkUploadLoading } = useSelector(state => ({
    bulkUpload: state?.PostCoupons?.data,
    bulkUploadLoading: state?.PostCoupons?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    if (createFacility !== null) {
      history.push('/facility/manage-location');
      dispatch({
        type: CREATE_LOCATION_EMPTY,
      });
    }
  }, [createFacility]);

  useEffect(() => {
    if (bulkUpload !== null) {
      dispatch({
        type: GET_EMPTY_POST_COUPON,
      });
    }
  }, [bulkUpload]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      partnersLoading ||
      redemptionTypeLoading ||
      countriesLoading ||
      regionsLoading ||
      subRegionsLoading ||
      createFacilityLoading ||
      bulkUploadLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [partnersLoading, redemptionTypeLoading, countriesLoading, regionsLoading, subRegionsLoading, createFacilityLoading, bulkUploadLoading]);

  /* ---------------------------- BULK UPLOAD HANDLER ---------------------------- */
  const bulkUploadActionHandler = () => {
    setIsBulkUploadShow(true);
  };

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push('/dashboard');
    } else if (title === `Add Outlet`) {
      // do nothing
    } else if (title === `Outlet`) {
      // do nothing
    }
  };

  return (
    <>
      {loading && <Loader />}

      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ---------------------------- MODAL'S ----------------------------  */}
          {isBulkUploadShow && <BulkUploadModal onCloseHandler={() => setIsBulkUploadShow(false)} />}

          {/*  ---------------------------- PAGES ----------------------------  */}
          <>
            <BreadCrumb title="Outlet" navigationby={breadcrumNavigationHandler} navigation={['Dashboard', 'Outlet', 'Add Outlet']} />

            <LocationForm bulkUploadAction={bulkUploadActionHandler} title="Add Outlet" />
          </>
        </Container>
      </div>
    </>
  );
};

export default AddLocation;
