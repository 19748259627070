import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Button, Card, CardBody, Container, Label } from 'reactstrap';

import Table from './Components/Tables/Table';
import Filter from './Components/Filters/Filter';
import { Constant } from '../../../../../Helpers/constant';
import { BreadCrumb, Error, Loader } from '../../../../../Common';
import { VENDOR_BOOKINGS_DATA, VENDOR_DOWNLOAD_DUMP, VENDOR_DOWNLOAD_DUMP_EMPTY } from '../../../../../../store/actions';

import { useTranslation } from 'react-i18next';
const buttonStyle = {
  background: 'linear-gradient(to right, #009BBD 0%, #3F9B76 100%)',
  border: 'none',
  color: 'white',
  padding: '9px 20px',
  cursor: 'pointer',
  borderRadius: '5px',
  fontSize: '13px',
};

const initialFilters = {
  createdAtRange: {
    from: ``,
    to: ``,
  },
  allocatedTo: {
    label: `New User`,
    value: `new_user`,
  },
  vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
};

const initialState = {
  startDate: '',
  endDate: '',
};

const ReportLandmark = () => {
  const { t } = useTranslation();

  const selectedClientCode = JSON.parse(window.localStorage.getItem('data'))?.program?.client?.clients?.[0];

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState(initialState);
  const [isMobile, setIsMobile] = useState(false);
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(initialFilters);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [bookingTypeId, setBookingTypeId] = useState(2);
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState({ code: '', name: '' });
  const [isValidationShow, setIsValidationShow] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.GetVendorBookings?.data,
    bookingsLoading: state?.GetVendorBookings?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.VendorDownloadDump?.data,
    downloadDumpLoading: state?.VendorDownloadDump?.loading,
  }));

  // Function to check if the device is a mobile device based on the screen width
  const checkDeviceType = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    window.addEventListener('resize', checkDeviceType);
    checkDeviceType();
    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, []);

  useEffect(() => {
    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId: 2,
          pageIndex: 0 * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: {
          ...currentlyAppliedFilters,
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          allocatedTo: currentlyAppliedFilters?.allocatedTo?.value,
        },
        headers: {
          code: selectedClientCode,
        },
      },
    });
  }, []);

  const datePickHandler = (flag, date) => {
    let updatedState = null;
    switch (flag) {
      case 'startDate':
        updatedState = {
          ...state,
          startDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      case 'endDate':
        updatedState = {
          ...state,
          endDate: moment(new Date(date[0])).format('Y-MM-DD'),
        };
        setState(updatedState);
        break;
      default:
      // do nothing
    }
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/client/dashboard`);
    }
  };

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = (filters, code, name, filterStatus) => {
    setCurrentPageNumber(0);
    setCurrentlyAppliedFilters(filters);
    setBank({ code: code, name: name });

    dispatch({
      type: VENDOR_BOOKINGS_DATA,
      payload: {
        urlParam: {
          bookingTypeId,
          pageIndex: 0,
          sortOn: filters?.sortOn ?? 'updatedAt',
        },
        data: {
          // filter: filterStatus,
          ...filters,
          allocatedTo: filters?.allocatedTo?.value,
          vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
        },
        headers: {
          code: selectedClientCode,
        },
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);

    if (isMobile) {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: 'updatedAt',
          },
          data: {
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
            createdAtRange: {
              from: state.startDate,
              to: state.endDate,
            },
          },
          headers: {
            code: selectedClientCode,
          },
        },
      });
    } else {
      dispatch({
        type: VENDOR_BOOKINGS_DATA,
        payload: {
          urlParam: {
            bookingTypeId,
            pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: {
            ...currentlyAppliedFilters,
            allocatedTo: currentlyAppliedFilters?.allocatedTo?.value,
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
          headers: {
            code: selectedClientCode,
          },
        },
      });
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    console.log('isMobile', isMobile);
    if (isMobile) {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP,
        payload: {
          urlParam: {
            bookingTypeId,
            downloadType: 1,
          },
          data: {
            ...currentlyAppliedFilters,

            allocatedTo: currentlyAppliedFilters?.allocatedTo?.value,
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
          headers: {
            code: selectedClientCode,
          },
        },
      });
    } else {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP,
        payload: {
          urlParam: {
            bookingTypeId,
            downloadType: 1,
          },
          data: {
            ...currentlyAppliedFilters,
            allocatedTo: currentlyAppliedFilters?.allocatedTo?.value,
            vendorId: JSON.parse(window.localStorage.getItem('authUser'))?.data?.userId ?? '',
          },
          headers: {
            code: selectedClientCode,
          },
        },
      });
    }
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.startDate !== `` && data?.endDate !== `` && new Date(data?.startDate)?.getTime() < new Date(data?.endDate)?.getTime()) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: VENDOR_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (bookingsLoading || downloadDumpLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [bookingsLoading, downloadDumpLoading]);

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <>
          {loading && <Loader />}

          <BreadCrumb title="Voucher Reports" navigationby={breadcrumNavigationHandler} navigation={[t('Dashboard'), t('Voucher Reports')]} />
          <Filter
            filterHandler={filterSubmitHandler}
            appliedFilters={currentlyAppliedFilters}
            selectedClientCode={selectedClientCode}
            setCurrentPageNumber={setCurrentPageNumber}
            setCurrentlyAppliedFilters={setCurrentlyAppliedFilters}
          />
          <Table
            data={bookings?.results}
            totalData={bookings?.count}
            pageNumber={currentPageNumber}
            pageHandler={paginationHandler}
            dumpDownload={downloadDumpHandler}
            selectedClientCode={selectedClientCode}
            appliedFilters={currentlyAppliedFilters}
            bankName={bank?.name}
          />
        </>
      </Container>
    </div>
  );
};

export default ReportLandmark;
