import React, { useEffect, useState } from 'react';

import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import ReactPaginate from 'react-paginate';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { hasPermission } from '../../../../../../Helpers/Helpers';

const WorkflowsTable = props => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data, index) => {
          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data?.displayName || `NA`,
            data?.parentCategory?.name || `NA`,
            data?.bookingType?.name || `NA`,
            data || `NA`,
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `VIEW`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    } else if (flag === `UPDATE`) {
      props.actionHandler({
        details: data,
        actionType: flag,
      });
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <PreviewCardHeader
              title={props.title}
              isShowFilterBtn={props?.isShowFilterBtn}
              filterBtnHandler={props?.filterBtnHandler}
              primaryButton={props?.isShowPrimaryButton}
              primaryButtonText={`Create New Workflow`}
              primaryClickHandler={props?.createNewWorkflow}
            />

            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'S.No.',
                      width: '60px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Id',
                      width: '200px',
                      formatter: (cell, row) => _(<span className="fw-semibold">{cell}</span>),
                    },

                    {
                      name: 'Name',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Booking Type',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Sub-Category',
                      width: '170px',
                      formatter: cell => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            {hasPermission(PermissionConstants?.ALL_PERMISSIONS?.CATEGORY_UPDATE) && (
                              <span
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() => {
                                  actionBtnHandler(`UPDATE`, cell);
                                }}
                              >
                                Update
                              </span>
                            )}
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                  style={{
                    th: {
                      zIndex: '999',
                    },
                    td: {
                      position: 'relative',
                    },
                  }}
                />
              </div>
              {props.children}
            </CardBody>

            <CardFooter>
              <Row className="custom_pagination">
                <Col>
                  <div className="d-flex justify-content-end">
                    <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(WorkflowsTable, (prevProps, nextProps) => prevProps.data === nextProps.data);
