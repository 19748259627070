import React, { useEffect, useState } from 'react';

import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import { PreviewCardHeader, TablePagination } from '../../../../../../Common';
import ReactPaginate from 'react-paginate';
import { Constant } from '../../../../../../Helpers/constant';
import { PermissionConstants } from '../../../../../../Helpers/Constants/permissions';
import { formattedWithComma, hasPermission } from '../../../../../../Helpers/Helpers';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Flatpickr from 'react-flatpickr';

const logsInfoStyle = {
  cursor: 'pointer',
  position: 'relative',
  top: ' 3px',
  fontSize: '17px',
  left: '3px',
  zIndex: '0',
};

const RedeemedTable = props => {
  console.log('hello', props?.data);
  const [data, setData] = useState([]);
  const [serialColumnNumber, setSerialColumnNumber] = useState(0);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      const fetchColumns = [
        {
          name: 'S.No',
          width: '90px',
          wrap: true,
          selector: row => row?.serialNumber,
        },
        {
          name: 'Booking ID',
          width: '130px',
          wrap: true,
          selector: row => row?.bookingId,
        },
        {
          name: 'Brand Name',
          width: '140px',
          wrap: true,
          selector: row => row?.brandName,
        },
        {
          name: 'Offer Name',
          minWidth: '300px',
          wrap: true,
          selector: row => row?.offerName,
        },
        {
          name: 'Point Redeemed',
          width: '140px',
          wrap: true,
          selector: row => row?.pointsRedeemed,
        },
        {
          name: 'Redeemption Date',
          width: '160px',
          wrap: true,
          selector: row => row?.redemptionDate,
        },
      ];
      setColumns(fetchColumns);

      setData(
        props?.data?.map((data, index) => {
          return {
            serialNumber: `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            bookingId: data?.bookingId || `NA`,
            brandName: data?.brandName || `NA`,
            offerName: data?.offer || `NA`,
            pointsRedeemed: data?.pointsRedeemed || `NA`,
            redemptionDate: data?.date ? moment(new Date(data?.date)).format('DD-MMM-YYYY') : '',
          };
        }),
      );
    } else {
      setData([]);
    }
  }, [props?.data]);

  console.log('dddd', data);

  const today = new Date();
  const previousDay = new Date();
  previousDay.setDate(today.getDate() - 1);

  const defaultDates = [
    previousDay.toISOString().split('T')[0], // Previous day's date
    today.toISOString().split('T')[0], // Today's date
  ];

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card className="card-inner">
            <CardHeader className="d-flex" style={{ background: '#f3f3f8' }}>
              <h4 className="card-title mb-0 flex-grow-1 d-flex align-items-center">{props?.title}</h4>
              <div className="d-flex">
                <div>
                  <Flatpickr
                    className="form-control"
                    onChange={props?.filterDateHandler}
                    options={{
                      mode: 'range',
                      dateFormat: 'Y-m-d',
                      maxDate: 'today',
                      defaultDate: defaultDates, // Set default date range
                    }}
                    placeholder="Select Date"
                  />
                </div>
              </div>
            </CardHeader>

            <CardBody>
              <DataTable
                columns={columns ?? []}
                data={data ?? []}
                customStyles={{
                  headRow: {
                    style: {
                      borderBottom: '1px solid #ddd',
                      backgroundColor: '#f9fafb',
                      fontWeight: '600',
                    },
                  },
                  cells: {
                    style: {
                      border: '0.5px solid #ddd',
                      borderBottom: '0px solid #ddd',
                    },
                  },
                }}
              />
            </CardBody>

            {/* {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col md={12} className="d-flex justify-content-between align-items-center">
                    <div>
                      Showing <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + 1)}</b> to&nbsp;
                      <b>{formattedWithComma(props?.pageNumber * Constant?.TABLE_PAGE_SIZE + props?.data?.length)}</b> of{' '}
                      <b>{formattedWithComma(props?.totalData)}</b> records
                    </div>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )} */}

            {/* <CardFooter>
                            <Row className="custom_pagination">
                                <Col className="text-end">
                                    <ReactPaginate
                                        previousLabel={"previous"}
                                        forcePage={props?.pageNumber}
                                        nextLabel={"next"}
                                        breakLabel={"..."}
                                        pageCount={Math.ceil(
                                            props?.totalData / Constant.TABLE_PAGE_SIZE
                                        )}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={3}
                                        onPageChange={(event) => {
                                            props.pageHandler(event.selected);
                                        }}
                                        containerClassName={"pagination justify-content-center"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </Col>
                            </Row>
                        </CardFooter> */}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(RedeemedTable, (prevProps, nextProps) => prevProps.data === nextProps.data);
