import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Grid, _ } from 'gridjs-react';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';

import { Constant } from '../../../../../../../Helpers/constant';
import { hasPermission } from '../../../../../../../Helpers/Helpers';
import { PreviewCardHeader, TablePagination } from '../../../../../../../Common';
import { PermissionConstants } from '../../../../../../../Helpers/Constants/permissions';

const Table = props => {
  const [data, setData] = useState([]);

  const [bookingData, setBookingData] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [currentFilterBookingStatus, setCurrentFilterBookingStatus] = useState(0);
  const [currentFilteredBookingTitle, setCurrentFilterBookingTitle] = useState('Requested Bookings');

  useEffect(() => {
    getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.vendorStatus));
    if (props?.data?.length > 0) {
      setBookingData(
        props?.data?.map(booking => {
          return { ...booking, checkboxValue: false };
        }),
      );

      setSelectedBookings([]);
      getBookingStatusTitleHandler(parseInt(props?.appliedFilters?.vendorStatus));
      setCurrentFilterBookingStatus(parseInt(props?.appliedFilters?.vendorStatus));
    } else {
      setBookingData([]);
      setSelectedBookings([]);
      setCurrentFilterBookingStatus(0);
    }
  }, [props?.data]);

  useEffect(() => {
    if (bookingData?.length > 0) {
      setData(
        bookingData?.map((data, index) => {
          const status = Constant?.BOOKING_STATUSES?.filter(statusObj => statusObj.objectId === data?.bookingStatusCode)[0]?.name;

          let confirmedAt = `NA`;
          data?.bookingModifyPersons?.forEach(element => {
            if (element?.bookingStatus === Constant?.BOOKING_STATUS?.CONFIRM) {
              confirmedAt = moment(`${element?.modifiedAt}`).format(`DD-MM-YYYY, h:mm A`);
            }
          });

          return [
            `${props.pageNumber * Constant.TABLE_PAGE_SIZE + (index + 1)}.`,
            data?.bookingId || `NA`,
            // index,
            data?.user?.name || `NA`,
            data?.user?.email || `NA`,
            data?.user?.mobile || `NA`,
            data?.createdAt ? moment(`${data?.createdAt}`).format('DD-MM-YYYY, h:mm A') : `NA`,
            data?.numberOfPassengers || `NA`,
            data?.numberOfBabySeats || `NA`,
            data?.numberOfLuggages || `NA`,

            data?.pickupLocation?.address || `NA`,
            data?.pickupLocation?.emirates || `NA`,
            data?.pickupLocation?.region || `NA`,

            data?.dropLocation?.address || `NA`,
            data?.dropLocation?.emirates || `NA`,
            data?.dropLocation?.region || `NA`,

            data?.pickupDate ? moment(`${data?.pickupDate}`).format('DD-MM-YYYY') : `NA`,
            data?.pickupTime ? moment(`${data?.pickupTime}`, 'HH:mm').format('h:mm A') : `NA`,

            data?.flightNumber || `NA`,
            data?.terminalNumber || `NA`,

            // data?.bookingStatusCode ? status : 'NA',
            data,
          ];
        }),
      );
    } else {
      setData([]);
    }
  }, [bookingData]);

  /* ---------------------------- BOOKING TABLE TITLE HANDLER ---------------------------- */
  const getBookingStatusTitleHandler = bookingsStatusCode => {
    let bookingsTitle = 'Requested Bookings';

    if (bookingsStatusCode !== 0) {
      Constant?.BOOKING_STATUSES?.forEach(bookingStatus => {
        if (bookingsStatusCode === bookingStatus?.objectId) {
          bookingsTitle = `${bookingStatus?.name} Bookings`;
        }
      });
    }
    setCurrentFilterBookingTitle(bookingsTitle);
  };

  /* ---------------------------- ACTION HANDLER ---------------------------- */
  const actionBtnHandler = (flag, data) => {
    if (flag === `ACCEPT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `REJECT`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    } else if (flag === `COMPLETE`) {
      props.actionHandler({
        bookingDetails: data,
        actionType: flag,
      });
    }
  };

  const renderer = (data, index) => {
    if (data.completed) {
      const selectedRow = Array.from(document.querySelectorAll('.gridjs-tr'))[index + 1];
      selectedRow.style.backgroundColor = `#ec7b65`;
      selectedRow.style.color = `#fff`;
    } else {
      return (
        <span>
          {data.hours}:{data.minutes}:{data.seconds}
        </span>
      );
    }
  };

  return (
    <>
      {/* <button onClick={() => console.log(bookingData)}>get state</button> */}
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader
              title={currentFilteredBookingTitle}
              primaryButton={
                hasPermission(PermissionConstants?.ALL_PERMISSIONS?.VENDOR_DOWNLOAD_DUMP) &&
                props?.data?.length > 0 &&
                !['', null, undefined]?.includes(props?.appliedFilters?.createdAtRange?.from)
              }
              primaryButtonText="Download Dump"
              primaryClickHandler={props?.dumpDownload}
            />

            {/* ------------------------ TABLE DETAILS ------------------------ */}
            {props?.data?.length > 0 && (
              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={data}
                    columns={[
                      {
                        name: 'S.No.',
                        width: '70px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Booking ID',
                        width: '130px',
                        formatter: (cell, row) => _(<div className="d-flex justify-content-center flex-column">{cell}</div>),
                      },

                      {
                        name: 'Full Name',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Email',
                        width: '250px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Mobile No.',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Date Time of Request',
                        width: '180px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'No. Of Passengers',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'No. Of Baby Seats',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'No. Of Luggages',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Pickup Location',
                        width: '220px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Pickup SubRegion',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Pickup Region',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Drop Location',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Drop SubRegion',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Drop Region',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Pickup Date',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Pickup Time',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Flight Number',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Terminal Number',
                        width: '140px',
                        formatter: cell => _(<span className="">{cell}</span>),
                      },

                      // {
                      //   name: 'Status',
                      //   width: '140px',
                      //   formatter: cell =>
                      //     _(
                      //       <span
                      //         className={`booking_status break-word badge bg-${
                      //           Constant.BOOKING_STATUSES.filter(status => status?.name === cell)[0]?.bgColor
                      //         }`}
                      //       >
                      //         {cell}
                      //       </span>,
                      //     ),
                      // },

                      {
                        name: 'Actions',
                        width: '120px',
                        formatter: (cell, row) =>
                          _(
                            <div className="d-flex justify-content-center flex-column">
                              {cell?.vendorStatus === Constant?.BOOKING_STATUS?.REQUESTED && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() => {
                                      actionBtnHandler(`ACCEPT`, cell);
                                    }}
                                  >
                                    Accept
                                  </span>

                                  <span
                                    className="btn btn-sm btn-danger mb-1"
                                    onClick={() => {
                                      actionBtnHandler(`REJECT`, cell);
                                    }}
                                  >
                                    Reject
                                  </span>
                                </>
                              )}

                              {cell?.vendorStatus === Constant?.BOOKING_STATUS?.CONFIRM && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() => {
                                      actionBtnHandler(`COMPLETE`, cell);
                                    }}
                                  >
                                    Complete
                                  </span>
                                </>
                              )}
                            </div>,
                          ),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>
            )}

            {props?.data?.length === 0 && (
              <CardBody>
                <h5 className="text-center">Record not found!</h5>
              </CardBody>
            )}

            {props?.data?.length > 0 && (
              <CardFooter>
                <Row className="custom_pagination">
                  <Col>
                    <div className="d-flex justify-content-end">
                      <TablePagination totalData={props?.totalData} currentPage={props?.pageNumber} onPageChangeHandler={props?.pageHandler} />
                    </div>
                  </Col>
                </Row>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Table);
