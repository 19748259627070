import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from 'reactstrap';
import Filter from './Components/Filters/Filter';
import Table from './Components/Tables/Table';
import { ActionConfirmation, BreadCrumb, Loader } from '../../../../Common';
import { Constant } from '../../../../Helpers/constant';
import UpdateBookingModal from './Components/Modals/Update';

import {
  CLICK_DOWNLOAD_DUMP,
  CLICK_DOWNLOAD_DUMP_EMPTY,
  EMPTY_UPDATE_BOOKING,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_EMPTY,
  GET_CATEGORY,
  GET_COUNTRY,
  GET_PARTNER,
  GET_VENDORS_LIST,
  GET_VENDOR_BY_BOOKING,
  RESEND_MAIL,
  UPDATE_BOOKING,
  VENDORS_LIST,
  VIEW_FILE,
  VIEW_FILE_EMPTY,
} from '../../../../../store/application/actionType';
import BookingDetails from './Components/Details/Details';
import CancelModal from './Components/Modals/CancelModal';
import ChaufferAssignModal from './Components/Modals/ChaufferAssignModal';
import BulkCancelModal from './Components/Modals/BulkUpdateModals/CancelModal';
import BulkProcessModal from './Components/Modals/BulkUpdateModals/ProcessModal';
import BulkVendorAssign from './Components/Modals/BulkUpdateModals/VendorAssignModal';
import BulkConfirmModal from './Components/Modals/BulkUpdateModals/ConfirmModal';
import BulkConfirmModalEnbd from './Components/Modals/BulkUpdateModals/ConfirmEnbdModal';
import BulkResendMailModal from './Components/Modals/BulkUpdateModals/ResendMailModal';
import BulkUpdateModal from './Components/Modals/BulkUpdateModals/UpdateModal';
import BulkUpdateModalEnbd from './Components/Modals/BulkUpdateModals/UpdateModalEnbd';
import BulkChaufferAssign from './Components/Modals/BulkUpdateModals/ChaufferAssign';
import BulkChaufferAssignEnbd from './Components/Modals/BulkUpdateModals/ChaufferAssignEnbd';
import VendorAssignModal from './Components/Modals/VendorAssignModal';
import cogoToast from 'cogo-toast';
import ConfirmModalAirport from './Components/Modals/ConfirmModalAirport';
import ManualBooking from './Components/Forms/ManualBooking';
import UpdateModalAirport from './Components/Modals/UpdateAirportModal';
import VendorAssignModalAirport from './Components/Modals/VendorAssignAirportModal';
import ChaufferAssignModalAirport from './Components/Modals/ChaufferAssignAirportModal';

const PickAndDropBookingManagement = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [projectId, setProjectId] = useState(Constant?.CURRENT_SELECTED_PROGRAM?.id);
  const [bookingTypeId, setBookingTypeId] = useState(Constant?.BOOKING_TYPES?.pickupAndDropBookings?.objectId);

  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState(null);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [currentSelectedBookingDetails, setCurrentSelectedBookingDetails] = useState([]);

  const [loading, setLoading] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const [currentConfirmationModalActionFor, setCurrentConfirmationModalActionFor] = useState(null);

  const [isCancelModalShow, setIsCancelModalShow] = useState(false);
  const [isChaufferAssignShow, setIsChaufferAssignShow] = useState(false);
  const [isUpdateBookingModal, setIsUpdateBookingModal] = useState(false);
  const [isVendorAssignModal, setIsVendorAssignModal] = useState(false);

  const [isProcessBulkModalShow, setIsProcessBulkModalShow] = useState(false);
  const [isAssignVendorBulkModalShow, setIsAssignVendorBulkModalShow] = useState(false);
  const [isConfirmBulkModalShow, setIsConfirmBulkModalShow] = useState(false);
  const [isCancelBulkModalShow, setIsCancelBulkModalShow] = useState(false);
  const [isUpdateBulkModalShow, setIsUpdateBulkModalShow] = useState(false);
  const [isResendMailBulkModalShow, setIsResendMailBulkModalShow] = useState(false);
  const [isChaufferAssignBulkModalShow, setIsChaufferAssignBulkModalShow] = useState(false);
  const [isShowManualBooking, setIsShowManualBooking] = useState(false);
  const [isConfirmModalForAirportTransfer, setIsConfirmModalForAirportTransfer] = useState(false);
  const [isUpdateModalForAirportTransfer, setIsUpdateModalForAirportTransfer] = useState(false);
  const [isVendorAssignForAirportTransfer, setIsVendorAssignForAirportTransfer] = useState(false);
  const [isChaufferAssignForAirportTransfer, setisChaufferAssignForAirportTransfer] = useState(false);
  const [isBulkChangeVendor, setIsBulkChangeVendor] = useState(false);

  /* ---------------------------- REDUX STATES ---------------------------- */
  // Bookings
  const { bookings, bookingsLoading } = useSelector(state => ({
    bookings: state?.Bookings?.data,
    bookingsLoading: state?.Bookings?.loading,
  }));

  // Update Booking
  const { updateBookings, updateBookingsLoading } = useSelector(state => ({
    updateBookings: state?.UpdateBooking?.data,
    updateBookingsLoading: state?.UpdateBooking?.loading,
  }));

  // Countries
  const { countries, countriesLoading } = useSelector(state => ({
    countries: state?.Countries?.data,
    countriesLoading: state?.Countries?.loading,
  }));

  // Regions
  const { regions, regionsLoading } = useSelector(state => ({
    regions: state?.Regions?.data,
    regionsLoading: state?.Regions?.loading,
  }));

  // Sub-Regions
  const { subRegions, subRegionsLoading } = useSelector(state => ({
    subRegions: state?.SubRegions?.data,
    subRegionsLoading: state?.SubRegions?.loading,
  }));

  // Category
  const { categories, categoryLoading } = useSelector(state => ({
    categories: state?.GetCategory?.data,
    categoryLoading: state?.GetCategory?.loading,
  }));

  // Partners
  const { partners, partnersLoading } = useSelector(state => ({
    partners: state?.GetPartners?.data,
    partnersLoading: state?.GetPartners?.loading,
  }));

  // Locations
  const { locations, locationsLoading } = useSelector(state => ({
    locations: state?.GetLocation?.data,
    locationsLoading: state?.GetLocation?.loading,
  }));

  // Service
  const { services, serviceLoading } = useSelector(state => ({
    services: state?.GetService?.data,
    serviceLoading: state?.GetService?.loading,
  }));

  // Click Download Dump
  const { downloadDump, downloadDumpLoading } = useSelector(state => ({
    downloadDump: state?.DownloadDump?.data,
    downloadDumpLoading: state?.DownloadDump?.loading,
  }));

  // Resend Mail
  const { resendMail, resendMailLoading } = useSelector(state => ({
    resendMail: state?.ResendMail?.data,
    resendMailLoading: state?.ResendMail?.loading,
  }));

  // Get Vendor List
  const { vendorsList, vendorsListLoading } = useSelector(state => ({
    vendorsList: state?.VendorsList?.data,
    vendorsListLoading: state?.VendorsList?.loading,
  }));

  // Get Vendor List
  const { viewFile, viewFileLoading } = useSelector(state => ({
    viewFile: state?.ViewFile?.data,
    viewFileLoading: state?.ViewFile?.loading,
  }));

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });

    return () => {
      dispatch({
        type: GET_BOOKING_LIST_EMPTY,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (viewFile !== null) {
      window.open(viewFile, `_blank`);
      dispatch({
        type: VIEW_FILE_EMPTY,
      });
    }
  }, [viewFile]);

  useEffect(() => {
    if (countries === null) {
      dispatch({
        type: GET_COUNTRY,
      });
    }
    if (categories === null) {
      dispatch({
        type: GET_CATEGORY,
      });
    }

    if (partners === null) {
      dispatch({
        type: GET_PARTNER,
      });
    }

    // if (locations === null) {
    //   dispatch({
    //     type: GET_LOCATION,
    //   })
    // }

    // if (services === null) {
    //   dispatch({
    //     type: GET_SERVICE,
    //   })
    // }
  }, [dispatch]);

  useEffect(() => {
    if (updateBookings !== null) {
      dispatch({
        type: EMPTY_UPDATE_BOOKING,
      });

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
            sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
          },
          data: currentlyAppliedFilters,
        },
      });
    }
  }, [updateBookings]);

  useEffect(() => {
    if (downloadDump !== null) {
      dispatch({
        type: CLICK_DOWNLOAD_DUMP_EMPTY,
      });
    }
  }, [downloadDump]);

  /* ---------------------------- FILTER SUBMIT HANDLER ---------------------------- */
  const filterSubmitHandler = filters => {
    if (filters?.filter) {
      setCurrentPageNumber(0);
      setCurrentPageIndex(0);
      setCurrentlyAppliedFilters(filters);

      dispatch({
        type: GET_BOOKING_LIST,
        payload: {
          urlParam: {
            projectId,
            bookingTypeId,
            pageIndex: 0,
            sortOn: filters?.sortOn ?? 'updatedAt',
          },
          data: filters,
        },
      });
    } else {
      setCurrentlyAppliedFilters(filters);
    }
  };

  /* ---------------------------- FILTER RESET HANDLER ---------------------------- */
  const onResetHandler = () => {
    setCurrentPageNumber(0);
    setCurrentPageIndex(0);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: 0,
          sortOn: 'updatedAt',
        },
        data: null,
      },
    });
  };

  /* ---------------------------- PAGINATION HANDLER ---------------------------- */
  const paginationHandler = pageNumber => {
    setCurrentPageNumber(pageNumber);
    setCurrentPageIndex(pageNumber * Constant.TABLE_PAGE_SIZE);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: pageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  /* ---------------------------- TABLE ACTION HANDLER ---------------------------- */
  const tableActionHandler = params => {
    setCurrentSelectedBookingDetails(params?.bookingDetails);
    setCurrentConfirmationModalActionFor(params?.actionType);
    if (params?.actionType === `VIEW`) {
      setIsDetailsPage(!isDetailsPage);
    } else if (params?.actionType === `PROCESS`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CONFIRM`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `CANCEL`) {
      setIsCancelModalShow(true);
    } else if (params?.actionType === `CHAUFFER ASSIGN`) {
      setIsChaufferAssignShow(true);
    } else if (params?.actionType === `UPDATE`) {
      setIsUpdateBookingModal(true);
    } else if (params?.actionType === `RESEND_EMAIL`) {
      setIsShowConfirmationModal(true);
    } else if (params?.actionType === `BULK_PROCESS`) {
      setIsProcessBulkModalShow(true);
    } else if (params?.actionType === `BULK_CONFIRM`) {
      setIsConfirmBulkModalShow(true);
    } else if (params?.actionType === `BULK_ASSIGN_VENDOR`) {
      setIsAssignVendorBulkModalShow(true);
    } else if (params?.actionType === `BULK_CHANGE_VENDOR`) {
      setIsAssignVendorBulkModalShow(true);
      setIsBulkChangeVendor(true);
    } else if (params?.actionType === `BULK_CANCEL`) {
      setIsCancelBulkModalShow(true);
    } else if (params?.actionType === `BULK_UPDATE`) {
      setIsUpdateBulkModalShow(true);
    } else if (params?.actionType === `BULK_RESEND_MAIL`) {
      setIsResendMailBulkModalShow(true);
    } else if (params?.actionType === `BULK_CHAUFFER_ASSIGN`) {
      setIsChaufferAssignBulkModalShow(true);
    } else if (params?.actionType === `ASSIGN_VENDOR`) {
      dispatch({
        type: GET_VENDORS_LIST,
        payload: {
          urlParams: {
            serviceId: `${params?.bookingDetails?.service?.id}`,
          },
        },
      });

      // setIsVendorAssignModal(true)
    } else if (params?.actionType === `CHANGE_VENDOR`) {
      dispatch({
        type: GET_VENDORS_LIST,
        payload: {
          urlParams: {
            serviceId: `${params?.bookingDetails?.service?.id}`,
          },
        },
      });
      // setIsVendorAssignModal(true)
    } else if (params?.actionType === `CONFIRM_AIRPORT`) {
      setIsConfirmModalForAirportTransfer(true);
    } else if (params?.actionType === `ASSIGN_VENDOR_AIRPORT`) {
      setIsVendorAssignForAirportTransfer(true);
    } else if (params?.actionType === `UPDATE_AIRPORT`) {
      setIsUpdateModalForAirportTransfer(true);
    } else if (params?.actionType === `CHAUFFER_ASSIGN_AIRPORT`) {
      setisChaufferAssignForAirportTransfer(true);
    }
  };

  /* ---------------------------- CLOSE MODAL HANDLER ---------------------------- */
  const closeModalHandler = (flag, data) => {
    if (flag === `ConfirmationModal`) {
      setIsShowConfirmationModal(false);
      if (data === `YES`) {
        if (currentConfirmationModalActionFor === `PROCESS`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.PROCESSED,
                    process: `process-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CONFIRM`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CONFIRM,
                    process: `confirm-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `CANCEL`) {
          dispatch({
            type: UPDATE_BOOKING,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingsData: [
                  {
                    // ...currentSelectedBookingDetails,
                    objectId: currentSelectedBookingDetails?.bookingId,
                    detailedBookingId: currentSelectedBookingDetails?.detailedBookingId,
                    status: Constant?.BOOKING_STATUS?.CANCEL_BY_THRIWE,
                    process: `cancel-booking`,
                  },
                ],
              },
            },
          });
        } else if (currentConfirmationModalActionFor === `RESEND_EMAIL`) {
          dispatch({
            type: RESEND_MAIL,
            payload: {
              data: {
                projectId,
                bookingTypeId,
                bookingIds: [currentSelectedBookingDetails?.bookingId],
              },
            },
          });
        }
      }
    }
  };

  /* ---------------------------- DOWNLOAD DUMP HANDLER ---------------------------- */
  const downloadDumpHandler = () => {
    dispatch({
      type: CLICK_DOWNLOAD_DUMP,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          downloadType: 1,
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  const showManualBookingHandler = () => {
    setIsDetailsPage(false);
    setIsShowManualBooking(true);
  };

  /* ---------------------------- LOADING HANDLER ---------------------------- */
  useEffect(() => {
    if (
      bookingsLoading ||
      countriesLoading ||
      regionsLoading ||
      subRegionsLoading ||
      partnersLoading ||
      locationsLoading ||
      updateBookingsLoading ||
      downloadDumpLoading ||
      resendMailLoading ||
      categoryLoading ||
      vendorsListLoading ||
      viewFileLoading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    bookingsLoading,
    countriesLoading,
    regionsLoading,
    subRegionsLoading,
    partnersLoading,
    locationsLoading,
    updateBookingsLoading,
    downloadDumpLoading,
    resendMailLoading,
    categoryLoading,
    vendorsListLoading,
    viewFileLoading,
  ]);

  /* ---------------------------- BREADCRUMB HANDLER ---------------------------- */
  const breadcrumNavigationHandler = title => {
    if (title === `Dashboard`) {
      history.push(`/dashboard`);
    } else if (title === `Airport Transfer Booking`) {
      setIsDetailsPage(false);
      setIsShowManualBooking(false);
    } else if (title === `Airport Transfer Booking Details`) {
      // do nothing
    }
  };

  const viewTicketHandler = details => {
    dispatch({
      type: VIEW_FILE,
      payload: {
        urlParams: {
          key: `${details?.ticketUrl}`,
          purpose: `flightTicket`,
          user_id: `${details?.user?.objectId}`,
        },
      },
    });
  };

  const setIsShowManualBookingHandler = flag => {
    setIsShowManualBooking(flag);

    dispatch({
      type: GET_BOOKING_LIST,
      payload: {
        urlParam: {
          projectId,
          bookingTypeId,
          pageIndex: currentPageNumber * Constant.TABLE_PAGE_SIZE,
          sortOn: currentlyAppliedFilters?.sortOn ?? 'updatedAt',
        },
        data: currentlyAppliedFilters,
      },
    });
  };

  return (
    <>
      {loading && <Loader />}

      {isShowConfirmationModal && (
        <ActionConfirmation onClose={closeModalHandler} data={currentSelectedBookingDetails} action={currentConfirmationModalActionFor} />
      )}

      {isConfirmModalForAirportTransfer && (
        <ConfirmModalAirport onCloseHandler={() => setIsConfirmModalForAirportTransfer(false)} data={currentSelectedBookingDetails} />
      )}
      {isUpdateModalForAirportTransfer && (
        <UpdateModalAirport onCloseHandler={() => setIsUpdateModalForAirportTransfer(false)} data={currentSelectedBookingDetails} />
      )}
      {isVendorAssignForAirportTransfer && (
        <VendorAssignModalAirport onCloseHandler={() => setIsVendorAssignForAirportTransfer(false)} data={currentSelectedBookingDetails} />
      )}
      {isChaufferAssignForAirportTransfer && (
        <ChaufferAssignModalAirport onCloseHandler={() => setisChaufferAssignForAirportTransfer(false)} data={currentSelectedBookingDetails} />
      )}
      <div className="page-content">
        <Container fluid className="position-relative">
          {/*  ---------------------------- BULK MODAL'S ---------------------------- */}
          {isCancelBulkModalShow && <BulkCancelModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsCancelBulkModalShow(false)} />}

          {isProcessBulkModalShow && (
            <BulkProcessModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsProcessBulkModalShow(false)} />
          )}

          {isAssignVendorBulkModalShow && (
            <BulkVendorAssign
              data={currentSelectedBookingDetails}
              onCloseHandler={() => setIsAssignVendorBulkModalShow(false)}
              isBulkChangeVendor={isBulkChangeVendor}
            />
          )}

          {isConfirmBulkModalShow && Constant.PROJECT_CODE !== 'ENBD_V3' && (
            <BulkConfirmModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsConfirmBulkModalShow(false)} />
          )}

          {isConfirmBulkModalShow && Constant.PROJECT_CODE === 'ENBD_V3' && (
            <BulkConfirmModalEnbd data={currentSelectedBookingDetails} onCloseHandler={() => setIsConfirmBulkModalShow(false)} />
          )}

          {isResendMailBulkModalShow && (
            <BulkResendMailModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsResendMailBulkModalShow(false)} />
          )}

          {isUpdateBulkModalShow && Constant.PROJECT_CODE !== 'ENBD_V3' && (
            <BulkUpdateModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsUpdateBulkModalShow(false)} locations={locations} />
          )}

          {isUpdateBulkModalShow && Constant.PROJECT_CODE === 'ENBD_V3' && (
            <BulkUpdateModalEnbd data={currentSelectedBookingDetails} onCloseHandler={() => setIsUpdateBulkModalShow(false)} locations={locations} />
          )}

          {isChaufferAssignBulkModalShow && Constant.PROJECT_CODE !== 'ENBD_V3' && (
            <BulkChaufferAssign data={currentSelectedBookingDetails} onCloseHandler={() => setIsChaufferAssignBulkModalShow(false)} />
          )}

          {isChaufferAssignBulkModalShow && Constant.PROJECT_CODE === 'ENBD_V3' && (
            <BulkChaufferAssignEnbd data={currentSelectedBookingDetails} onCloseHandler={() => setIsChaufferAssignBulkModalShow(false)} />
          )}

          {/*  ----------------------------  MODAL ---------------------------- */}
          {isCancelModalShow && <CancelModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsCancelModalShow(false)} />}

          {isChaufferAssignShow && <ChaufferAssignModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsChaufferAssignShow(false)} />}

          {isUpdateBookingModal && <UpdateBookingModal data={currentSelectedBookingDetails} onCloseHandler={() => setIsUpdateBookingModal(false)} />}
          {isVendorAssignModal && (
            <VendorAssignModal
              modalFor={currentConfirmationModalActionFor}
              data={currentSelectedBookingDetails}
              onCloseHandler={() => setIsVendorAssignModal(false)}
              vendorsList={vendorsList}
            />
          )}

          {!isDetailsPage && !isShowManualBooking && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`]}
              />
              <Filter
                data={{
                  countries,
                  regions,
                  subRegions,
                  categories: categories?.results,
                  partners: partners?.results,
                  locations: locations?.results,
                  services: services?.results,
                }}
                filterHandler={filterSubmitHandler}
                appliedFilters={currentlyAppliedFilters}
                onReset={onResetHandler}
              />
              <Table
                data={bookings?.results}
                totalData={bookings?.count}
                pageNumber={currentPageNumber}
                pageHandler={paginationHandler}
                actionHandler={tableActionHandler}
                dumpDownload={downloadDumpHandler}
                appliedFilters={currentlyAppliedFilters}
                showManualBookingHandler={showManualBookingHandler}
              />
            </>
          )}

          {isDetailsPage && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`, `Airport Transfer Details`]}
              />
              <BookingDetails details={currentSelectedBookingDetails} onViewTicket={viewTicketHandler} />
            </>
          )}

          {isShowManualBooking && (
            <>
              <BreadCrumb
                title="Airport Transfer Booking"
                navigationby={breadcrumNavigationHandler}
                navigation={[`Dashboard`, `Airport Transfer Booking`, `Create Manual Booking`]}
              />
              <ManualBooking setIsShowManualBooking={setIsShowManualBookingHandler} />
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default PickAndDropBookingManagement;
