import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link, useParams } from 'react-router-dom';

import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Label, Input } from 'reactstrap';

import { Error } from '../../../../Common';
import { isValidEmail } from '../../../../Helpers/Helpers';
import { GET_PROJECT_UTILITY } from '../../../../../store/actions';
import ThriweWhite from '../../../../../assets/custom/thriwe-white-logo.png';
import ParticlesAuth from '../../../../../pages/AuthenticationInner/ParticlesAuth';

import { get, map } from 'lodash';
import i18n from '../../../../../i18n';
import languages from '../../../../../Components/Common/languages';
import { useTranslation } from 'react-i18next';

const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const initialState = {
    email: '',
    password: '',
  };

  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('I18N_LANGUAGE'));

  const [client, setClient] = useState('MASTERCARD_KSA');
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const { getProjectUtility } = useSelector(state => ({
    getProjectUtility: state.GetProjectUtility.data?.results,
  }));
  const [projectUtility, setProjectUtility] = useState();

  const [state, setState] = useState(initialState);
  const [isValidationShow, setIsValidationShow] = useState(false);

  const [isShowPassword, setIsShowPassword] = useState(false);

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const langParam = urlParams.get('lang');
    const validLang = langParam === 'en' || langParam === 'ar' ? langParam : 'en';
    setSelectedLang(validLang);
  }, []);

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown);
  };

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem('I18N_LANGUAGE', lang);
    setSelectedLang(lang);
  };

  useEffect(() => {
    if (getProjectUtility != null) {
      setProjectUtility(JSON.parse(localStorage.getItem('projectUtility')));
    }
  }, [getProjectUtility]);

  useEffect(() => {
    if (clientId && clientId === 'landmark') {
      setClient(clientId);
      dispatch({ type: GET_PROJECT_UTILITY });
    }
  }, [clientId]);

  /* ---------------------------- INPUT CHANGE HANDLER ---------------------------- */
  const inputChangeHandler = e => {
    const { name, value } = e.target;
    let updatedState = { ...state };

    switch (name) {
      case 'email':
        updatedState = {
          ...state,
          email: value,
        };
        break;

      case 'password':
        updatedState = {
          ...state,
          password: value,
        };
        break;

      default:
      // do nothing
    }

    setState(updatedState);
    validateErrors(updatedState, `validationCheck`);
  };

  /* ---------------------------- VALIDATION CHECK ---------------------------- */
  const validateErrors = (data = state, flag) => {
    if (data?.email?.trim() !== `` && data?.password?.trim() !== `` && isValidEmail(data?.email?.trim())) {
      setIsValidationShow(false);
      return true;
    } else {
      setIsValidationShow(flag === `submissionCheck` ? true : isValidationShow);
      return false;
    }
  };

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    if (validateErrors(state, `submissionCheck`)) {
      const payload = {
        ...state,
      };

      onSubmit({
        ...payload,
      });
    }
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div style={{ display: 'flex', justifyContent: 'right', position: 'absolute', top: '0px', right: '48px', color: '#fff', zIndex: 1000 }}>
          <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
            <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
              <div>
                <button
                  className="btn btn-icon btn-topbar btn-ghost-secondary"
                  style={{
                    color: '#333',
                    border: '1px solid #fff',
                    width: '108px',
                    background: '#fff',
                  }}
                >
                  <img
                    src={get(languages, `${selectedLang}.flag`)}
                    alt="Header Language"
                    height="20"
                    className="rounded"
                    style={{ marginRight: '10px' }}
                  />
                  {selectedLang === 'en' ? 'English' : 'Arabic'}
                </button>
              </div>
            </DropdownToggle>
            <DropdownMenu className="notify-item language py-2">
              {Object.keys(languages).map(key => (
                <DropdownItem
                  key={key}
                  onClick={() => changeLanguageAction(key)}
                  className={`notify-item ${selectedLang === key ? 'active' : 'none'}`}
                >
                  <img src={get(languages, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
                  <span className="align-middle">{get(languages, `${key}.label`)}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <p className="mt-3 fs-15 fw-medium">&nbsp;</p>
                    <Link to="/client/login" className="d-inline-block auth-logo">
                      {client === 'landmark' ? (
                        <img src={projectUtility?.results[0]?.images?.logo} alt="LANDMARK" height="50" />
                      ) : (
                        <img src={ThriweWhite} alt="" height="50" />
                      )}
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4" style={{ borderRadius: '40px' }}>
                  <CardBody className="p-md-5 p-sm-3">
                    {/* <button onClick={() => console.log(state)}>get state</button> */}
                    <div className="text-center mt-2">
                      <h1 className="text-primary">{t('Login')}</h1>
                    </div>
                    <div className="p-2 mt-4 mb-3">
                      <div className="mb-3">
                        <Label htmlFor="email" className="form-label">
                          {t('Email ID')}
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          type="text"
                          placeholder={t(`Enter Email ID`)}
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state?.email}
                        />
                        {isValidationShow && state?.email?.trim() === `` && <Error text={`${t("Email can't be empty")}`} />}
                        {isValidationShow && state?.email?.trim() !== `` && !isValidEmail(state?.email?.trim()) && (
                          <Error text={t(`Please enter valid email`)} />
                        )}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="password-input">
                          {t('Password')}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password"
                            name="password"
                            type={isShowPassword ? 'text' : 'password'}
                            placeholder={t(`Enter Password`)}
                            className="form-control"
                            onChange={inputChangeHandler}
                            value={state?.password}
                          />
                          {isValidationShow && state?.password?.trim() === `` && <Error text="Password can't be empty" />}
                          {isValidationShow && state?.password?.trim() !== `` && <Error text="Please enter correct password" />}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() => {
                              setIsShowPassword(!isShowPassword);
                            }}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>

                      <div className="form-check">
                        <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                        <Label className="form-check-label" htmlFor="auth-remember-check">
                          {t('Remember me')}
                        </Label>
                        <div className="float-end">
                          <Link to="/client/forgot-password" className="text-muted">
                            {t(`Forgot password?`)}
                          </Link>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button color="success" className="btn btn-success w-100" type="submit" onClick={submitHandler}>
                          {t('Login')}
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(LoginForm);
